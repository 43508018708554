/** @format */

import { AxiosError, AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../../constants/api";
import { TTStoreCreateRequest } from "../../redux-modules/declarations/maps/store";
import apiClient from "../../utils/apiClient";

export const apiStoreCreate = (
  data: TTStoreCreateRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .post(API_ENDPOINTS.STORES, data)
    .then(response => ({ response }))
    .catch(error => ({ error }));
