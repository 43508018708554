/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import { sagaCacheSave, sagaCacheRemove } from "../sagas/sagasCache";

export function* watcherCache(): unknown {
  yield takeLatest(TActionType.loginSuccess, sagaCacheSave);
  yield takeLatest(TActionType.customerSelect, sagaCacheSave);
  yield takeLatest(TActionType.customerListSuccess, sagaCacheSave);
  yield takeLatest(TActionType.groupListSuccess, sagaCacheSave);
  yield takeLatest(TActionType.licenseListSuccess, sagaCacheSave);
  yield takeLatest(TActionType.peopleListSuccess, sagaCacheSave);
  yield takeLatest(TActionType.accountTypeListSuccess, sagaCacheSave);
  yield takeLatest(TActionType.brandListSuccess, sagaCacheSave);
  yield takeLatest(TActionType.roleListSuccess, sagaCacheSave);
  yield takeLatest(TActionType.administratorListSuccess, sagaCacheSave);
  yield takeLatest(TActionType.storeListSuccess, sagaCacheSave);
  yield takeLatest(TActionType.alertRemove, sagaCacheSave);
  yield takeLatest(TActionType.logoutSuccess, sagaCacheRemove);
  yield takeLatest(TActionType.storeReloadSuccess, sagaCacheSave);
  yield takeLatest(TActionType.staticAlertRemove, sagaCacheSave);
}
