/** @format */

import React, { FC } from "react";
import { Breadcrumb, Divider } from "antd";
import { UICol, UIRow, UITitle, UITransition } from "@rstore-react-packages/template-ui";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { Routes } from "../../../utils/Routes";
import { selectorCustomer } from "../../../redux-modules/selectors/selectorsCustomer";
import { TStore } from "../../../redux-modules/declarations/store";
import LayoutCustomerStatistics from "../../layout/CustomerStatistics";
import LayoutGroupsTable from "../../layout/Tables/Groups";
import { useGenericAlert } from "../../../redux-modules/hooks/useGenericAlert";
import CustomerInfoPanel from "../../layout/InfoPanels/CustomerInfoPanel";
import LayoutBrandsTable from "../../layout/Tables/Brands";
import { LayoutCustomerMenu } from "../../layout/Menu/CustomerMenu";

const ViewCustomer: FC = (): JSX.Element => {
  //@ts-ignore
  const { id } = useParams();
  const Alert = useGenericAlert(6000);
  const customer = useSelector((store: TStore) => selectorCustomer(store, id));

  return (
    <>
      <LayoutCustomerMenu />
      <UITransition>
        {Alert}
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>
            <NavLink to={Routes.home}>Home</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{customer?.name}</Breadcrumb.Item>
        </Breadcrumb>
        <UIRow style={{ marginTop: "30px" }}>
          <UICol>
            <UITitle>{customer?.name}</UITitle>
          </UICol>
        </UIRow>
        <CustomerInfoPanel customer={customer} />
        <Divider />
        <LayoutCustomerStatistics customer={customer} />
        <UIRow style={{ marginTop: "35px" }}>
          <UICol xs={24}>
            <UITitle level={3}>User Groups</UITitle>
          </UICol>
        </UIRow>
        <LayoutGroupsTable filteredByCustomer={id} />
        <UIRow style={{ marginTop: "35px" }}>
          <UICol xs={24}>
            <UITitle level={3}>Store Groups</UITitle>
          </UICol>
        </UIRow>
        <LayoutBrandsTable filteredByCustomer={id} withLinks />
      </UITransition>
    </>
  );
};

export default ViewCustomer;
