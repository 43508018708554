/** @format */

export const KEY_APP_NAME = `${process.env.REACT_APP_NAME}`;
export const KEY_ASSISTANCE_MAIL = `${process.env.REACT_APP_ADMIN_EMAIL}`;

export enum TSTATUS_LIST {
  "pending" = "pending",
  "paused" = "paused",
  "deleted" = "deleted",
}

export enum TSTORE_STATUS_LIST {
  "Existing" = "Existing",
  "Closed" = "Closed",
  "On hold" = "On hold",
  "Next opening" = "Next opening",
  "Closing" = "Closing",
}
