/** @format */

import {
  TActionLicenseListSuccess,
  TActionLicenseListReset,
  TActionLicenseDeleteSuccess,
} from "../declarations/actions/licenses";
import { TActionType } from "../declarations/actionTypes";
import { TLicenseState } from "../declarations/maps/license";

type Actions = TActionLicenseListSuccess | TActionLicenseListReset | TActionLicenseDeleteSuccess;

const defaultState = {
  list: {
    1: {
      id: 1,
      group_id: 1,
      start_date: "01/01/2020",
      end_date: "31/12/2021",
      account_type_id: 1,
      account_type: "X",
      people_usage_limit: "1 / 10",
      report_usage_limit: "10",
    },
    2: {
      id: 2,
      group_id: 1,
      start_date: "01/01/2020",
      end_date: "31/12/2021",
      account_type_id: 1,
      account_type: "X",
      people_usage_limit: "1 / 10",
      report_usage_limit: "10",
    },
  },
};

export const reducerLicense = (
  state: TLicenseState = defaultState,
  action: Actions,
): TLicenseState => {
  switch (action.type) {
    case TActionType.licenseListSuccess:
      return { list: { ...action.payload } };
    case TActionType.licenseListReset:
      return defaultState;
    case TActionType.licenseDeleteSuccess:
      const { [action.payload]: _, ...newState } = state.list;
      return { list: newState };
    default:
      return state;
  }
};
