/** @format */

import { TTStore, TTStoreForTable } from "../declarations/maps/store";
import { TStore } from "../declarations/store";

export const selectorStoreList = (store: TStore): TTStore[] =>
  Object.values(store.store.list) || [];

export const selectorStore = (store: TStore, storeId: string): TTStore | null =>
  store.store.list[storeId] || null;

export const selectorStoreListForTables = (
  store: TStore,
  brandId: number | false = false,
): TTStoreForTable[] => {
  const storeList = brandId
    ? Object.values(store.store.list).filter(el => !!el.brands.find(obj => obj.id == brandId))
    : Object.values(store.store.list);
  if (!!!storeList) return [];

  return storeList
    .map(el => {
      const newEl = (({ ...o }): TTStoreForTable => ({
        ...o,
        key: o.id,
        brands: `${o.brands.map(
          (brand, i): string => ` ${brand.name}${i !== o.brands.length - 1 ? "" : ""}`,
        )}`,
        branch: o.branch?.name || "",
        partner: o.partner?.name || "",
        address: o.address,
        gender: o.details?.data.find(el => el.key === "gender")?.value || "",
        status: o.details?.data.find(el => el.key === "storeStatus")?.value || "",
      }))(el);

      return newEl;
    })
    .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
};
