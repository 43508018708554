/** @format */

import {
  TActionTipologyListFailure,
  TActionTipologyListRequest,
  TActionTipologyListSuccess,
} from "../declarations/actions/tipologies";
import { TActionType } from "../declarations/actionTypes";
import { TTipologyList } from "../declarations/maps/tipology";

export const actionTipologyListRequest = (): TActionTipologyListRequest => ({
  type: TActionType.tipologyListRequest,
});

export const actionTipologyListSuccess = (payload: TTipologyList): TActionTipologyListSuccess => ({
  type: TActionType.tipologyListSuccess,
  payload,
});

export const actionTipologyListFailure = (payload: string): TActionTipologyListFailure => ({
  type: TActionType.tipologyListFailure,
  reason: payload,
});
