/** @format */

import { UIRow, UICol } from "@rstore-react-packages/template-ui";
import { Button, Form, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionMassivePeopleBranchRequest } from "../../../../redux-modules/actions/actionsGeneral";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import { selectorBranchListForSelect } from "../../../../redux-modules/selectors/selectorsBranch";
import StyledModal from "../../../ui/Modal";
const { Option } = Select;

/** @format */
interface Props {
  visible: boolean;
  handleOk?: () => void;
  handleCancel?: () => void;
  people: number[];
}
const ModalChangeBranch = ({
  visible = false,
  handleCancel,
  handleOk,
  people,
}: Props): JSX.Element => {
  const l = useLanguage();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const branches = useSelector(selectorBranchListForSelect);

  const handleFinish = (form): void => {
    dispatch(
      actionMassivePeopleBranchRequest({
        users_ids: people,
        action: "branch",
        branch_id: form.branch_id,
      }),
    );
    //@ts-ignore
    handleOk && handleOk();
  };

  const handleReturn = (): void => {
    handleCancel && handleCancel();
  };

  return (
    <StyledModal
      title={l("editBranch")}
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleReturn}>
          Return
        </Button>,
        <Button key="submit" onClick={(): void => form.submit()}>
          Submit
        </Button>,
      ]}
    >
      <UIRow>
        <UICol xs={24}>
          <Form form={form} name="edit-people-branch" layout="horizontal" onFinish={handleFinish}>
            <Form.Item
              name="branch_id"
              label={"You are editing " + people.length + "'s people branch with:"}
            >
              <Select
                optionFilterProp="label"
                showSearch
                filterOption={(input, option): boolean =>
                  //@ts-ignore
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {branches.map(branch => (
                  <Option value={branch.value} key={branch.key}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </UICol>
      </UIRow>
    </StyledModal>
  );
};

export default ModalChangeBranch;
