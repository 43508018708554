/** @format */

import { UIRow, UICol } from "@rstore-react-packages/template-ui";
import { Button, Form, Select } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { TSTORE_STATUS_LIST } from "../../../../constants/general";
import { actionMassiveStoreStatusRequest } from "../../../../redux-modules/actions/actionsStore";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import StyledModal from "../../../ui/Modal";
const { Option } = Select;

/** @format */
interface Props {
  visible: boolean;
  handleOk?: () => void;
  handleCancel?: () => void;
  store: number[];
}
const ModalChangeStoreSwitchStatus = ({
  visible = false,
  handleCancel,
  handleOk,
  store,
}: Props): JSX.Element => {
  const l = useLanguage();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const status = TSTORE_STATUS_LIST;

  const handleFinish = (form): void => {
    dispatch(
      actionMassiveStoreStatusRequest({
        users_ids: store,
        action: "status",
        status: form.status,
      }),
    );
    //@ts-ignore
    handleOk && handleOk();
  };

  const handleReturn = (): void => {
    handleCancel && handleCancel();
  };

  return (
    <StyledModal
      title={l("editStatus")}
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleReturn}>
          Return
        </Button>,
        <Button key="submit" onClick={(): void => form.submit()}>
          Submit
        </Button>,
      ]}
    >
      <UIRow>
        <UICol xs={24}>
          <Form form={form} name="edit-store-status" layout="horizontal" onFinish={handleFinish}>
            <Form.Item
              name="status"
              label={"You are editing " + store.length + "'s store status with:"}
            >
              <Select
                optionFilterProp="label"
                showSearch
                filterOption={(input, option): boolean =>
                  //@ts-ignore
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {Object.keys(status).map(status => (
                  <Option value={status} key={status}>
                    {status}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </UICol>
      </UIRow>
    </StyledModal>
  );
};
export default ModalChangeStoreSwitchStatus;
