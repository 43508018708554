/** @format */

import React, { FC, useEffect, useState } from "react";
import { UIRow, UICol, UIButton } from "@rstore-react-packages/template-ui";
import { useDispatch, useSelector } from "react-redux";
import {
  selectorImportPeopleLoading,
  selectorPeopleListLoading,
} from "../../../../redux-modules/selectors/selectorsUI";
import { Input } from "antd";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import styled from "styled-components";
import { TPeopleForTable } from "../../../../redux-modules/declarations/maps/people";
import { peopleListColumns } from "../../../../constants/tables/peopleListColumns";
import { selectorPeopleListForTables } from "../../../../redux-modules/selectors/selectorsPeople";
import { TStore } from "../../../../redux-modules/declarations/store";
import ModalImportPeople from "../../Modals/People/importPeople";
import { actionPeopleImportRequest } from "../../../../redux-modules/actions/actionsPeople";
import MenuPeopleTableActions from "../../Menu/TableActions/peopleTableActions";
import FormAddPeople from "../../Forms/People/addPeople";
import StyledTable from "../../../ui/Table";

interface Props {
  filteredByGroup?: number | false;
}

const LayoutPeopleTable: FC<Props> = ({ filteredByGroup = false }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const l = useLanguage();
  const [searchingVal, setSearchingVal] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRows, setSelectedRow] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [addingPeople, setAddingPeople] = useState(false);
  const peopleListLoading = useSelector(selectorPeopleListLoading);
  const [filteredTable, setFilteredTable] = useState<TPeopleForTable[] | null>(null);
  const formattedPeopleList = useSelector((store: TStore) =>
    selectorPeopleListForTables(store, filteredByGroup),
  );

  const importPeopleLoading = useSelector(selectorImportPeopleLoading);

  useEffect(() => {
    if (
      !!filteredTable &&
      JSON.stringify(filteredTable) !==
        JSON.stringify(
          formattedPeopleList.filter(o =>
            Object.keys(o).some(k =>
              String(o[k]).toLowerCase().includes(searchingVal.toLowerCase()),
            ),
          ),
        )
    ) {
      handleSearch(searchingVal, formattedPeopleList);
    }
  }, [formattedPeopleList]);

  const toggleAddingPeople = (): void => setAddingPeople(!addingPeople);

  //@ts-ignore
  const onSelectRow = (selectedRowKeys: React.Key[], selectedRows: DataType[]): void => {
    //@ts-ignore
    setSelectedRow(selectedRows);
    //@ts-ignore
    setSelectedRowKeys(selectedRowKeys);
  };

  const unselectRow = (): void => {
    setSelectedRow([]);
    setSelectedRowKeys([]);
  };

  const handleSearch = (value: string, list: TPeopleForTable[] | false = false): void => {
    const filterTable = list
      ? list.filter(o =>
          Object.keys(o).some(k => String(o[k]).toLowerCase().includes(value.toLowerCase())),
        )
      : formattedPeopleList.filter(o =>
          Object.keys(o).some(k => String(o[k]).toLowerCase().includes(value.toLowerCase())),
        );
    setFilteredTable(filterTable);
  };

  const handleModalOk = (file, name): void => {
    setIsModalVisible(false);

    //dispatch some
    !!file &&
      filteredByGroup &&
      dispatch(
        actionPeopleImportRequest({
          csv: file,
          filename: name,
          type: "people",
          importable_id: filteredByGroup,
        }),
      );
  };

  const handleModalCancel = (): void => {
    setIsModalVisible(false);
  };
  return (
    <>
      {isModalVisible && (
        <ModalImportPeople
          visible={isModalVisible}
          handleOk={handleModalOk}
          handleCancel={handleModalCancel}
        />
      )}
      <UIRow justify="space-between" gutter={[16, 16]}>
        <UICol>
          <UIButton
            loading={importPeopleLoading}
            onClick={(): void => setIsModalVisible(true)}
            style={{ color: "#000" }}
          >
            {l("import")}
          </UIButton>
          <UIButton
            onClick={toggleAddingPeople}
            style={{
              color: addingPeople ? "#FFF" : "#000",
              backgroundColor: addingPeople ? "#931c22" : "white",
              marginLeft: "10px",
            }}
          >
            {addingPeople ? l("dismiss") : l("addPeople")}
          </UIButton>
          {!!selectedRows.length && (
            <MenuPeopleTableActions
              idList={
                //@ts-ignore
                selectedRows.map(e => e.id)
              }
              unselectAll={unselectRow}
            />
          )}
        </UICol>
      </UIRow>
      <UIRow>
        <UICol xs={24}>
          <>
            {addingPeople && filteredByGroup && (
              <FormAddPeople groupId={filteredByGroup} onCreateCallback={toggleAddingPeople} />
            )}
          </>
        </UICol>
      </UIRow>
      <UIRow justify="end" gutter={[16, 16]}>
        <UICol>
          <StyledUISearchInput
            placeholder={l("searchPlaceholder")}
            onSearch={(e): void => handleSearch(e)}
            onChange={(e): void => setSearchingVal(e.target.value)}
          />
        </UICol>
      </UIRow>
      <UIRow style={{ marginTop: "10px" }} gutter={[16, 16]}>
        <UICol span={24}>
          <StyledTable
            size="middle"
            rowSelection={{ type: "checkbox", onChange: onSelectRow, selectedRowKeys }}
            loading={peopleListLoading}
            columns={peopleListColumns}
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            rowKey={r => {
              return `${r.id}`;
            }}
            dataSource={filteredTable || formattedPeopleList}
            pagination={{ showSizeChanger: true }}
          />
        </UICol>
      </UIRow>
    </>
  );
};
const StyledUISearchInput = styled(Input.Search)`
  .anticon {
    color: #000 !important;
  }
`;
export default LayoutPeopleTable;
