/** @format */

import {
  TActionBrandListSuccess,
  TActionBrandListReset,
  TActionBrandDeleteSuccess,
} from "../declarations/actions/brands";
import { TActionType } from "../declarations/actionTypes";
import { TBrandState } from "../declarations/maps/brand";

type Actions = TActionBrandListSuccess | TActionBrandListReset | TActionBrandDeleteSuccess;

const defaultState = {
  list: {},
};

export const reducerBrand = (state: TBrandState = defaultState, action: Actions): TBrandState => {
  switch (action.type) {
    case TActionType.brandListSuccess:
      return { list: { ...action.payload } };
    case TActionType.brandListReset:
      return defaultState;
    case TActionType.brandDeleteSuccess:
      const { [action.payload]: _, ...newState } = state.list;
      return { list: newState };
    default:
      return state;
  }
};
