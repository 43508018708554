/** @format */

import { UIRow, UICol, UIText } from "@rstore-react-packages/template-ui";
import { Colors } from "@rstore-react-packages/template-ui/lib/theme/maps/Colors";
import Paragraph from "antd/lib/typography/Paragraph";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionCustomerUpdateRequest } from "../../../../redux-modules/actions/actionsCustomer";
import { TCustomer } from "../../../../redux-modules/declarations/maps/customer";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import { selectorIsAdmin } from "../../../../redux-modules/selectors/selectorsAuth";
import FormEditCustomer from "../../Forms/Customer/editCustomer";

interface Props {
  customer: TCustomer | null;
}

const CustomerInfoPanel = ({ customer }: Props): JSX.Element => {
  const l = useLanguage();
  const isAdmin = useSelector(selectorIsAdmin);
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const handleEditing = (): void => setIsEditing(!isEditing);
  const handleFinish = (form): void => {
    const customerInfo = { ...customer, ...form, tipology_id: customer?.tipology.id };
    delete customerInfo?.logo;
    customerInfo.logo = "rounderga.png";
    dispatch(actionCustomerUpdateRequest(customerInfo));
    handleEditing();
  };

  if (!!!customer) return <></>;
  if (!!isEditing)
    return (
      <FormEditCustomer
        customer={customer}
        onFinishCallback={handleFinish}
        onCloseForm={handleEditing}
      />
    );
  return (
    <>
      <UIRow style={{ marginTop: "10px", marginBottom: "30px" }}>
        <UICol xs={12}>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol style={{ display: "inline-flex" }}>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("vat")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph
                  style={{ display: "inline" }}
                  data-cy-edit-customer
                  editable={!!isAdmin && { onStart: handleEditing }}
                >
                  {`${customer.vat}`}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("address")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph
                  style={{ display: "inline" }}
                  editable={!!isAdmin && { onStart: handleEditing }}
                >
                  {customer.address}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("phone")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph
                  style={{ display: "inline" }}
                  editable={!!isAdmin && { onStart: handleEditing }}
                >
                  {`${customer.phone}`}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("email")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph
                  style={{ display: "inline" }}
                  editable={!!isAdmin && { onStart: handleEditing }}
                >
                  {customer.email}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
        </UICol>
      </UIRow>
    </>
  );
};

export default CustomerInfoPanel;
