/** @format */

import {
  TActionCustomerListSuccess,
  TActionCustomerListReset,
  TActionCustomerDeleteSuccess,
} from "../declarations/actions/customers";
import { TActionType } from "../declarations/actionTypes";
import placeholderLogo from "../../assets/images/smallPlaceholder.png";
import { TCustomerState } from "../declarations/maps/customer";

type Actions = TActionCustomerListSuccess | TActionCustomerListReset | TActionCustomerDeleteSuccess;

const defaultState = {
  list: {
    "1": {
      id: 1,
      name: "Test",
      vat: "1",
      address: "via x",
      tipology: { id: 1, name: "XXX" },
      phone: "3210000",
      email: "xx@yy.zz",
      api_url: "xxxx.xxxx.it",
      api_token: "xxxxXZZXZXDDSAZ",
      logo: placeholderLogo,
    },
    "2": {
      id: 2,
      name: "Test 2",
      vat: "1",
      address: "via x",
      tipology: { id: 2, name: "YYY" },
      phone: "3210000",
      email: "xx@yy.zz",
      api_url: "xxxx.xxxx.it",
      api_token: "xxxxXZZXZXDDSAZ",
      logo: placeholderLogo,
    },
  },
};

export const reducerCustomer = (
  state: TCustomerState = defaultState,
  action: Actions,
): TCustomerState => {
  switch (action.type) {
    case TActionType.customerListSuccess:
      return { list: { ...action.payload } };
    case TActionType.customerListReset:
      return defaultState;
    case TActionType.customerDeleteSuccess:
      const { [action.payload]: _, ...newState } = state.list;
      return { list: newState };
    default:
      return state;
  }
};
