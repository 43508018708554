/** @format */

import { UIRow, UICol } from "@rstore-react-packages/template-ui";
import { Button, Form, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionMassivePeopleLicenseRequest } from "../../../../redux-modules/actions/actionsGeneral";
import { TStore } from "../../../../redux-modules/declarations/store";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import { selectorLicenseListForSelect } from "../../../../redux-modules/selectors/selectorsLicense";
import { selectorPeople } from "../../../../redux-modules/selectors/selectorsPeople";
import StyledModal from "../../../ui/Modal";
const { Option } = Select;

/** @format */
interface Props {
  visible: boolean;
  handleOk?: () => void;
  handleCancel?: () => void;
  people: number[];
}
const ModalChangeLicense = ({
  visible = false,
  handleCancel,
  handleOk,
  people,
}: Props): JSX.Element => {
  const l = useLanguage();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const person = useSelector((store: TStore) => selectorPeople(store, `${people[0]}`));

  const licenses = useSelector((store: TStore) =>
    //@ts-ignore
    selectorLicenseListForSelect(store, person?.groups[0].group_id),
  );

  const handleFinish = (form): void => {
    dispatch(
      actionMassivePeopleLicenseRequest({
        users_ids: people,
        action: "changeLicense",
        license_id: form.license_id,
      }),
    );
    //@ts-ignore
    handleOk && handleOk();
  };

  const handleReturn = (): void => {
    handleCancel && handleCancel();
  };

  return (
    <StyledModal
      title={l("editLicense")}
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleReturn}>
          Return
        </Button>,
        <Button key="submit" onClick={(): void => form.submit()}>
          Submit
        </Button>,
      ]}
    >
      <UIRow>
        <UICol xs={24}>
          <Form form={form} name="edit-people-license" layout="horizontal" onFinish={handleFinish}>
            <Form.Item
              name="license_id"
              label={"You are editing " + people.length + "'s people license with:"}
            >
              <Select>
                {licenses.map(license => (
                  <Option value={license.value} key={license.key}>
                    {license.id}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </UICol>
      </UIRow>
    </StyledModal>
  );
};
export default ModalChangeLicense;
