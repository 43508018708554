/** @format */

import React, { FC, useState } from "react";
import { UIRow, UICol, UIButton } from "@rstore-react-packages/template-ui";
import { useSelector } from "react-redux";
import { selectorLicenseListForTables } from "../../../../redux-modules/selectors/selectorsLicense";
import { selectorLicenseListLoading } from "../../../../redux-modules/selectors/selectorsUI";
import { Input, Table } from "antd";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import { TLicenseForTable } from "../../../../redux-modules/declarations/maps/license";
import { licenseListColumns } from "../../../../constants/tables/licenseListColumns";
import { TStore } from "../../../../redux-modules/declarations/store";
import FormAddLicense from "../../Forms/License/addLicense";
import styled from "styled-components";

interface Props {
  filteredByGroup?: number | false;
}

const LayoutLicensesTable: FC<Props> = ({ filteredByGroup = false }: Props): JSX.Element => {
  const l = useLanguage();
  const licenseListLoading = useSelector(selectorLicenseListLoading);
  const [addingLicense, setAddingLicense] = useState(false);
  const [filteredTable, setFilteredTable] = useState<TLicenseForTable[] | null>(null);
  const toggleAddingLicense = (): void => setAddingLicense(!addingLicense);
  const formattedLicenseList = useSelector((store: TStore) =>
    selectorLicenseListForTables(store, filteredByGroup),
  );

  const handleSearch = (value: string): void => {
    const filterTable = formattedLicenseList.filter(o =>
      Object.keys(o).some(k => String(o[k]).toLowerCase().includes(value.toLowerCase())),
    );

    setFilteredTable(filterTable);
  };

  return (
    <>
      {filteredByGroup && (
        <>
          <UIRow>
            <UICol>
              <UIButton
                onClick={toggleAddingLicense}
                style={{
                  color: addingLicense ? "#FFF" : "#000",
                  backgroundColor: addingLicense ? "#931c22" : "white",
                }}
              >
                {addingLicense ? l("dismiss") : l("addLicense")}
              </UIButton>
            </UICol>
          </UIRow>
          {addingLicense && (
            <FormAddLicense groupId={filteredByGroup} onCreateCallback={toggleAddingLicense} />
          )}
        </>
      )}
      <UIRow justify="end" gutter={[16, 16]}>
        <UICol>
          <StyledUISearchInput placeholder={l("searchPlaceholder")} onSearch={handleSearch} />
        </UICol>
      </UIRow>
      <UIRow style={{ marginTop: "10px" }} gutter={[16, 16]}>
        <UICol span={24}>
          <Table
            size="middle"
            loading={licenseListLoading}
            columns={licenseListColumns}
            dataSource={filteredTable || formattedLicenseList}
          />
        </UICol>
      </UIRow>
    </>
  );
};

const StyledUISearchInput = styled(Input.Search)`
  .anticon {
    color: #000 !important;
  }
`;

export default LayoutLicensesTable;
