/** @format */

import moment from "moment";
import { TLicense, TLicenseForSelect, TLicenseForTable } from "../declarations/maps/license";
import { TStore } from "../declarations/store";

export const selectorLicenseList = (store: TStore): TLicense[] =>
  Object.values(store.license.list) || [];

export const selectorLicense = (store: TStore, licenseId: string): TLicense | null => {
  const ll = store.license.list[licenseId];
  //@ts-ignore
  ll.end_date = moment(ll.end_date, "DD/MM/YYYY");
  //@ts-ignore
  ll.start_date = moment(ll.start_date, "DD/MM/YYYY");
  ll.people_usage_limit = ll.people_usage_limit?.split(" / ")[1] || "";
  return store.license.list[licenseId] || null;
};

export const selectorLicenseListForTables = (
  store: TStore,
  groupId: number | false = false,
): TLicenseForTable[] => {
  const licenseList = groupId
    ? Object.values(store.license.list).filter(el => el.group_id == groupId)
    : Object.values(store.license.list);
  if (!!!licenseList) return [];

  return licenseList.map(el => {
    const newEl = (({ group_id, account_type_id, ...o }): TLicenseForTable => ({
      ...o,
      key: o.id,
      //@ts-ignore
      account_type: o.account_type.name || "",
    }))(el);

    return newEl;
  });
};

export const selectorLicenseListForSelect = (
  store: TStore,
  groupId: number | false = false,
): TLicenseForSelect[] => {
  const licenseList = groupId
    ? Object.values(store.license.list).filter(el => el.group_id == groupId)
    : Object.values(store.license.list);
  if (!!!licenseList) return [];

  return licenseList.map(el => {
    const newEl = (({ ...o }): TLicenseForSelect => ({
      ...o,
      key: o.id,
      value: o.id,
      description: o.id,
    }))(el);

    return newEl;
  });
};
