/** @format */

import { UICol, UIRow, UITitle, UITransition } from "@rstore-react-packages/template-ui";
import { Breadcrumb, Divider } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { TStore } from "../../../redux-modules/declarations/store";
import {
  useGenericAlert,
  useGenericAlertClosable,
} from "../../../redux-modules/hooks/useGenericAlert";
import { useLanguage } from "../../../redux-modules/hooks/useLanguage";
import { selectorBrand } from "../../../redux-modules/selectors/selectorsBrand";
import { selectorCustomer } from "../../../redux-modules/selectors/selectorsCustomer";
import { Routes } from "../../../utils/Routes";
import BrandInfoPanel from "../../layout/InfoPanels/BrandInfoPanel";
import LayoutStoreTable from "../../layout/Tables/Stores";

const ViewBrand = (): JSX.Element => {
  //@ts-ignore
  const { id } = useParams();
  const Alert = useGenericAlert(12000);
  const StaticAlert = useGenericAlertClosable();
  const brand = useSelector((store: TStore) => selectorBrand(store, id));
  const customer = useSelector((store: TStore) => selectorCustomer(store, `${brand?.customer_id}`));

  const l = useLanguage();

  return (
    <>
      <UITransition>
        {Alert}
        {StaticAlert}
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>
            <NavLink to={Routes.home}>Home</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <NavLink to={Routes.customer.replace(":id", `${customer?.id}`)}>
              {customer?.name}
            </NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{brand?.name}</Breadcrumb.Item>
        </Breadcrumb>
        <UIRow style={{ marginTop: "30px" }}>
          <UICol>
            <UITitle>{brand?.name}</UITitle>
          </UICol>
        </UIRow>
        <BrandInfoPanel brand={brand} />
        <Divider />
        <UIRow style={{ marginTop: "35px" }}>
          <UICol xs={24}>
            <UITitle level={3}>{l("stores")}</UITitle>
          </UICol>
        </UIRow>
        <LayoutStoreTable filteredByBrand={id} />
      </UITransition>
    </>
  );
};

export default ViewBrand;
