/** @format */

import { UIRow, UICol, UIInput, UIText, UITransition } from "@rstore-react-packages/template-ui";
import { Button, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TSTATUS_LIST } from "../../../../constants/general";
import { actionMassivePeopleStatusRequest } from "../../../../redux-modules/actions/actionsGeneral";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import StyledModal from "../../../ui/Modal";
const { Option } = Select;

/** @format */
interface Props {
  visible: boolean;
  handleOk?: () => void;
  handleCancel?: () => void;
  people: number[];
}
const ModalChangeStatus = ({
  visible = false,
  handleCancel,
  handleOk,
  people,
}: Props): JSX.Element => {
  const l = useLanguage();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [selectedState, setSelectedState] = useState("");
  const [showDeleteCheck, setShowDeleteCheck] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [typedValue, setTypedValue] = useState("");

  const handleFinish = (form): void => {
    dispatch(
      actionMassivePeopleStatusRequest({
        users_ids: people,
        action: "status",
        status: form.status,
      }),
    );
    //@ts-ignore
    handleOk && handleOk();
  };

  const handleReturn = (): void => {
    handleCancel && handleCancel();
  };

  const handleChange = (val): void => setSelectedState(val);

  useEffect(() => {
    if (selectedState === "deleted") {
      setShowDeleteCheck(true);
      if (typedValue !== "delete people") setCanDelete(false);
    } else {
      setShowDeleteCheck(false);
      setCanDelete(true);
    }
  }, [selectedState]);

  useEffect(() => {
    if (typedValue === "delete people") setCanDelete(true);
    else setCanDelete(false);
  }, [typedValue]);

  return (
    <StyledModal
      title={l("editStatus")}
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleReturn}>
          Return
        </Button>,
        <Button key="submit" disabled={!canDelete} onClick={(): void => form.submit()}>
          Submit
        </Button>,
      ]}
    >
      <UIRow>
        <UICol xs={24}>
          <Form form={form} name="edit-people-status" layout="horizontal" onFinish={handleFinish}>
            <Form.Item
              name="status"
              label={"You are editing " + people.length + "'s people status with:"}
            >
              <Select onChange={handleChange}>
                {Object.keys(TSTATUS_LIST).map(status => (
                  <Option value={status} key={status} style={{ textTransform: "capitalize" }}>
                    {status === "pending" ? status + " (We will send an activation mail)" : status}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </UICol>
      </UIRow>
      {!!showDeleteCheck && (
        <UITransition>
          <UIRow style={{ marginTop: "10px" }}>
            <UIText>To confirm the operation type "delete people":</UIText>
            <UIInput onChange={(e): void => setTypedValue(e.target.value)} />
          </UIRow>
        </UITransition>
      )}
    </StyledModal>
  );
};
export default ModalChangeStatus;
