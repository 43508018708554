/** @format */

import { AxiosResponse, AxiosError } from "axios";
import { API_ENDPOINTS } from "../../constants/api";
import { TGroupAdminCreateRequest } from "../../redux-modules/declarations/maps/groupAdmin";
import apiClient from "../../utils/apiClient";

export const apiGroupAdminCreate = (
  data: TGroupAdminCreateRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .post(API_ENDPOINTS.GROUP_ADMIN, data)
    .then(response => ({ response }))
    .catch(error => ({ error }));

export const apiGroupAdminDelete = (
  data: TGroupAdminCreateRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .delete(
      API_ENDPOINTS.GROUP_ADMIN_DELETE.replace(":id_group", `${data.group_id}`).replace(
        ":id_person",
        `${data.person_id}`,
      ),
    )
    .then(response => ({ response }))
    .catch(error => ({ error }));
