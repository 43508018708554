/** @format */

import { TPeople, TPeopleForSelect, TPeopleForTable } from "../declarations/maps/people";
import { TStore } from "../declarations/store";

export const selectorPeopleList = (store: TStore): TPeople[] =>
  Object.values(store.people.list) || [];

export const selectorPeople = (store: TStore, peopleId: string): TPeople | null =>
  store.people.list[peopleId] || null;

export const selectorPeopleListForTables = (
  store: TStore,
  groupId: number | false = false,
): TPeopleForTable[] => {
  const peopleList = groupId
    ? Object.values(store.people.list).filter(
        el => !!el.groups.find(obj => obj.group_id == groupId),
      )
    : Object.values(store.people.list);
  if (!!!peopleList) return [];

  return peopleList.map(el => {
    const newEl = (({ account_type_id, ...o }): TPeopleForTable => {
      const branch = o.branch?.name || "";
      const partner = o.partner?.name || "";
      const role = o.role;
      return {
        ...o,
        key: o.id,
        brands: `${o.brands.map(
          (brand, i): string => ` ${brand.name}${i !== o.brands.length - 1 ? "" : ""}`,
        )}`,
        //@ts-ignore
        branchpartner: role === "VM Partner" ? `${partner}` : `${branch}`,
        license_id: o.license?.id || 0,
      };
    })(el);

    return newEl;
  });
};

export const selectorPeopleListForSelect = (
  store: TStore,
  groupId: number | false = false,
): TPeopleForSelect[] => {
  const peopleList = groupId
    ? Object.values(store.people.list).filter(
        el => !!el.groups.find(obj => obj.group_id == groupId),
      )
    : Object.values(store.people.list);
  if (!!!peopleList) return [];

  return peopleList.map(el => {
    const newEl = (({ account_type_id, ...o }): TPeopleForSelect => ({
      ...o,
      key: o.id,
      value: o.id,
    }))(el);

    return newEl;
  });
};

export const selectorAdministratorListForSelect = (store: TStore): TPeopleForSelect[] => {
  const peopleList = Object.values(store.administrator.list);
  if (!!!peopleList) return [];

  return peopleList.map(el => {
    const newEl = (({ account_type_id, ...o }): TPeopleForSelect => ({
      ...o,
      key: o.id,
      value: o.id,
    }))(el);

    return newEl;
  });
};
