/** @format */

import {
  TActionTipologyListSuccess,
  TActionTipologyListReset,
} from "../declarations/actions/tipologies";
import { TActionType } from "../declarations/actionTypes";
import { TTipologyState } from "../declarations/maps/tipology";

type Actions = TActionTipologyListSuccess | TActionTipologyListReset;

const defaultState = {
  list: {
    1: {
      id: 1,
      name: "xxx",
    },
    2: {
      id: 2,
      name: "yyy",
    },
  },
};

export const reducerTipology = (
  state: TTipologyState = defaultState,
  action: Actions,
): TTipologyState => {
  switch (action.type) {
    case TActionType.tipologyListSuccess:
      return { list: { ...action.payload } };
    case TActionType.tipologyListReset:
      return defaultState;
    default:
      return state;
  }
};
