/** @format */

import React, { FC, useState } from "react";
import { UIRow, UICol } from "@rstore-react-packages/template-ui";
import { useSelector } from "react-redux";
import branchListColumns from "../../../../constants/tables/branchListColumns";
import { Input, Table } from "antd";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import styled from "styled-components";
import { TStore } from "../../../../redux-modules/declarations/store";
import { selectorBranchList } from "../../../../redux-modules/selectors/selectorsBranch";
import { TBranch } from "../../../../redux-modules/declarations/maps/branch";

interface Props {
  filteredByCustomer?: number | false;
  onSelect: (i: React.Key[], el: TBranch[]) => void;
}

const LayoutBranchesTable: FC<Props> = ({
  filteredByCustomer = false,
  onSelect,
}: Props): JSX.Element => {
  //const dispatch = useDispatch();
  const l = useLanguage();
  const [filteredTable, setFilteredTable] = useState<TBranch[] | null>(null);
  const formattedBranchList = useSelector((store: TStore) => selectorBranchList(store)).map(el => ({
    ...el,
    key: el.id,
  }));

  /*   //@ts-ignore
  const onChange = (selectedRowKeys: React.Key[], selectedRows: DataType[]): void => {
    onSelect(selectedRowKeys, selectedRows);
  }; */

  const handleSearch = (value: string): void => {
    const filterTable = formattedBranchList.filter(o =>
      Object.keys(o).some(k => String(o[k]).toLowerCase().includes(value.toLowerCase())),
    );

    setFilteredTable(filterTable);
  };
  return (
    <>
      <UIRow justify="end" gutter={[16, 16]}>
        <UICol>
          <StyledUISearchInput placeholder={l("searchPlaceholder")} onSearch={handleSearch} />
        </UICol>
      </UIRow>
      <UIRow style={{ marginTop: "10px" }} gutter={[16, 16]}>
        <UICol span={24}>
          <Table
            size="middle"
            /*             rowSelection={{ type: "checkbox", onChange: onChange }}
             */ columns={branchListColumns}
            dataSource={filteredTable || formattedBranchList}
          />
        </UICol>
      </UIRow>
    </>
  );
};

const StyledUISearchInput = styled(Input.Search)`
  .anticon {
    color: #000 !important;
  }
`;

export default LayoutBranchesTable;
