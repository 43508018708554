/** @format */

import { AxiosError, AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { apiRoleList } from "../../api/role/apiRoleList";
import { arrayToObject } from "../../utils/format/arrayToObject";
import { actionRoleListFailure, actionRoleListSuccess } from "../actions/actionsRole";

import { actionAlertGenerate } from "../actions/actionsUI";
import { TActionRoleListRequest } from "../declarations/actions/roles";
import { TAPIValidationError } from "../declarations/apis/general";

export function* sagaRoleList(action: TActionRoleListRequest): unknown {
  const {
    response,
    error,
  }: { response?: AxiosResponse; error?: AxiosError<{ errors: TAPIValidationError }> } = yield call(
    apiRoleList,
  );

  if (!error && response) {
    //@ts-ignore
    yield put(actionRoleListSuccess(arrayToObject(response.data.data, "id")));
  } else {
    yield put(
      //@ts-ignore
      actionRoleListFailure((error?.response?.data?.errors as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.errors?.toString() || "",
      }),
    );
  }
}
