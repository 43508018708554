/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import {
  sagaGroupAdminCreate,
  sagaGroupAdminDelete,
  sagaGroupCreate,
  sagaGroupList,
  sagaGroupUpdate,
} from "../sagas/sagasGroup";

export function* watcherGroup(): unknown {
  yield takeLatest(TActionType.groupListRequest, sagaGroupList);
  yield takeLatest(TActionType.groupCreateRequest, sagaGroupCreate);
  yield takeLatest(TActionType.groupUpdateRequest, sagaGroupUpdate);
  yield takeLatest(TActionType.groupAdminCreateRequest, sagaGroupAdminCreate);
  yield takeLatest(TActionType.groupAdminDeleteRequest, sagaGroupAdminDelete);
}
