/** @format */

import { TListStringKey } from "../../redux-modules/declarations/maps/general";

export const arrayToObject = (
  arr: { [key: string]: unknown }[],
  indexKey: string | number,
): TListStringKey<unknown> => {
  return arr.reduce((acc, item) => {
    acc[`${item[indexKey]}`] = { ...item };
    return acc;
  }, {});
};
