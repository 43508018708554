/** @format */

import { UIRow, UICol, UIInput, UIButton } from "@rstore-react-packages/template-ui";
import { Form } from "antd";
import React from "react";
import { TCustomer } from "../../../../redux-modules/declarations/maps/customer";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";

interface Props {
  customer: TCustomer;
  onFinishCallback: (form) => void;
  onCloseForm: () => void;
}
const FormEditCustomer = ({ customer, onFinishCallback, onCloseForm }: Props): JSX.Element => {
  const l = useLanguage();

  return (
    <UIRow style={{ marginTop: "10px", marginBottom: "20px" }}>
      <UICol xs={24}>
        <Form
          name="edit-customer"
          layout="horizontal"
          onFinish={onFinishCallback}
          initialValues={customer}
        >
          <UIRow>
            <UICol xs={24} md={12}>
              <Form.Item name="vat" label={l("vat")}>
                <UIInput />
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={24} md={12}>
              <Form.Item name="address" label={l("address")}>
                <UIInput />
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={24} md={12}>
              <Form.Item name="phone" label={l("phone")}>
                <UIInput />
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={24} md={12}>
              <Form.Item name="email" label={l("email")}>
                <UIInput />
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={24} md={12}>
              <UIRow justify="end">
                <UICol>
                  <UIButton style={{ color: "black" }} onClick={onCloseForm}>
                    {l("dismiss")}
                  </UIButton>
                </UICol>
                <UICol offset={1}>
                  <Form.Item>
                    <UIButton style={{ color: "black" }} htmlType="submit">
                      {l("save")}
                    </UIButton>
                  </Form.Item>
                </UICol>
              </UIRow>
            </UICol>
          </UIRow>
        </Form>
      </UICol>
    </UIRow>
  );
};

export default FormEditCustomer;
