/** @format */

import { AxiosError, AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../../constants/api";

import {
  TMassiveStoreBranchRequest,
  TMassiveStoreBrandRequest,
  TMassiveStoreStatusRequest,
  TMassiveStorePartnerRequest,
} from "../../redux-modules/declarations/maps/store";
import apiClient from "../../utils/apiClient";

export const apiMassiveStoreBranch = (
  data: TMassiveStoreBranchRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .put(API_ENDPOINTS.STORES_MASSIVE, data)
    .then(response => ({ response }))
    .catch(error => ({ error }));

export const apiMassiveStoreBrand = (
  data: TMassiveStoreBrandRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .put(API_ENDPOINTS.STORES_MASSIVE, data)
    .then(response => ({ response }))
    .catch(error => ({ error }));

export const apiMassiveStoreStatus = (
  data: TMassiveStoreStatusRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .put(API_ENDPOINTS.STORES_MASSIVE, data)
    .then(response => ({ response }))
    .catch(error => ({ error }));

export const apiMassiveStorePartner = (
  data: TMassiveStorePartnerRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .put(API_ENDPOINTS.STORES_MASSIVE, data)
    .then(response => ({ response }))
    .catch(error => ({ error }));
