/** @format */

import { TUser } from "../declarations/maps/auth";
/** @format */

import { TStore } from "../declarations/store";

export const selectorAuthenticated = (store: TStore): boolean => !!store.auth?.user;

export const selectorLoggedUser = (store: TStore): TUser | undefined => store.auth.user;

export const selectorIsAdmin = (store: TStore): boolean => !!store.auth.isAdmin;

export const selectorIsVuemPartner = (store: TStore, user_id: string): boolean =>
  store.people.list[user_id].role === "VM Partner" || false;

export const selectorUserCustomerId = (store: TStore): number => store.auth.user?.customer_id || 0;

// static TRUE
/* export const selectorAuthenticated = (store: TStore): boolean => false; */
