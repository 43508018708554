/** @format */

import { put, call, select, all } from "redux-saga/effects";
import { TActionLoginRequest } from "../declarations/actions/auth";
import {
  actionLoginSuccess,
  actionLoginFailure,
  actionLogoutFailure,
  actionLogoutSuccess,
  actionLogoutRequest,
  actionFailureApiKeyCheck,
  actionEndApiKeyCheck,
  actionStartApiKeyCheck,
} from "../actions/actionsAuth";
import { apiLogout } from "../../api/auth/apiLogout";
import { utilitySaveToLocalStorage } from "../../utils/cache/saveToLocalStorage";
import { utilityGetFromLocalStorage } from "../../utils/cache/getFromLocalStorage";
import {
  selectorIsAdmin,
  selectorLoggedUser,
  selectorUserCustomerId,
} from "../selectors/selectorsAuth";
import { sleep } from "../../utils/sleep";
import { TAPIValidationError } from "../declarations/apis/general";
import { actionCustomerListRequest } from "../actions/actionsCustomer";
import { actionGroupListRequest } from "../actions/actionsGroup";
import { actionLicenseListRequest } from "../actions/actionsLicense";
import { actionTipologyListRequest } from "../actions/actionsTipology";
import { actionAccountTypeListRequest } from "../actions/actionsAccountType";
import { apiLogin } from "../../api/auth/apiLogin";
import { AxiosError, AxiosResponse } from "axios";
import { actionPeopleListRequest } from "../actions/actionsPeople";
import { actionBranchListRequest } from "../actions/actionsBranch";
import { actionBrandListRequest } from "../actions/actionsBrand";
import { actionAdministratorListRequest } from "../actions/actionsAdministrator";
import { actionRoleListRequest } from "../actions/actionsRole";
import { actionStoreListRequest } from "../actions/actionsStore";
import { actionPartnerListRequest } from "../actions/actionsPartner";
import { actionAlertRemove, actionCustomerSelect } from "../actions/actionsUI";
import {
  actionStoreReloadSuccess,
  actionStoreTimestampSetRequest,
} from "../actions/actionsGeneral";

export function* sagaLogin(action: TActionLoginRequest): unknown {
  const {
    response,
    error,
  }: { response?: AxiosResponse; error?: AxiosError<{ errors: TAPIValidationError }> } = yield call(
    apiLogin,
    {
      email: action.payload.email,
      password: action.payload.password,
    },
  );

  const tokenResponse = response?.data;

  if (!error && tokenResponse) {
    const token = tokenResponse.data.token;
    let userData = tokenResponse.data;
    utilitySaveToLocalStorage("apiKey", token);

    yield all([
      put(actionGroupListRequest()),
      put(actionCustomerListRequest()),
      put(actionPeopleListRequest()),
      put(actionLicenseListRequest()),
      put(actionTipologyListRequest()),
      put(actionAccountTypeListRequest()),
      put(actionBranchListRequest()),
      put(actionBrandListRequest()),
      put(actionAdministratorListRequest()),
      put(actionRoleListRequest()),
      put(actionStoreListRequest()),
      put(actionPartnerListRequest()),
      put(actionLoginSuccess(userData)),
      put(actionStartApiKeyCheck()),
    ]);

    if (!!tokenResponse.data.isAdmin) {
      // Se admin -> viene selezionato il customer in automatico
      yield put(actionCustomerSelect(userData.customer_id));
    }
  } else {
    //@ts-ignore
    yield put(actionLoginFailure((error?.response?.data?.errors as TAPIValidationError) || {}));
  }
}

export function* sagaStoreReload(): unknown {
  const customerId = yield select(selectorUserCustomerId);
  const isAdmin = yield select(selectorIsAdmin);
  yield all([
    put(actionGroupListRequest()),
    put(actionCustomerListRequest()),
    put(actionPeopleListRequest()),
    put(actionLicenseListRequest()),
    put(actionTipologyListRequest()),
    put(actionAccountTypeListRequest()),
    put(actionBranchListRequest()),
    put(actionBrandListRequest()),
    put(actionAdministratorListRequest()),
    put(actionRoleListRequest()),
    put(actionStoreListRequest()),
    put(actionPartnerListRequest()),
    put(actionStartApiKeyCheck()),
    put(actionStoreTimestampSetRequest(Date.now())),
  ]);

  if (!!isAdmin) {
    // Se admin -> viene selezionato il customer in automatico
    yield put(actionCustomerSelect(customerId));
  }

  yield put(actionStoreReloadSuccess());
}

export function* sagaLogout(): unknown {
  try {
    try {
      yield call(apiLogout);
    } catch (e) {
      console.error(e.stack);
    }
    yield put(actionAlertRemove());
    yield put(actionLogoutSuccess());
  } catch {
    yield put(actionLogoutFailure());
  }
}

export function* sagaCheckApiToken(): unknown {
  while (true) {
    try {
      const userUid = yield select(selectorLoggedUser);
      if (!userUid) return;
      const apiKey = utilityGetFromLocalStorage("apiKey");
      if (!apiKey) {
        yield put(actionLogoutRequest());
        yield put(actionEndApiKeyCheck());
        return;
      }
      //yield put(actionSuccessApiKeyCheck());
    } catch (e) {
      yield put(actionFailureApiKeyCheck(e.stack));
    }
    yield sleep(6000);
  }
}
