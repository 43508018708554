/** @format */

import React, { FC, useState } from "react";
import { UIRow, UICol, UIButton } from "@rstore-react-packages/template-ui";
import { useSelector } from "react-redux";
import { selectorGroupListForTables } from "../../../../redux-modules/selectors/selectorsGroup";
import groupListColumns from "../../../../constants/tables/groupListColumns";
import { selectorGroupListLoading } from "../../../../redux-modules/selectors/selectorsUI";
import { Input, Table } from "antd";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import styled from "styled-components";
import { TGroupForTable } from "../../../../redux-modules/declarations/maps/group";
import { TStore } from "../../../../redux-modules/declarations/store";
import FormAddGroup from "../../Forms/Group/addGroup";
import { selectorIsAdmin } from "../../../../redux-modules/selectors/selectorsAuth";

interface Props {
  filteredByCustomer?: number | false;
}

const LayoutGroupsTable: FC<Props> = ({ filteredByCustomer = false }: Props): JSX.Element => {
  //const dispatch = useDispatch();
  const l = useLanguage();
  const isAdmin = useSelector(selectorIsAdmin);
  const groupListLoading = useSelector(selectorGroupListLoading);
  const [addingGroup, setAddingGroup] = useState(false);
  const [filteredTable, setFilteredTable] = useState<TGroupForTable[] | null>(null);
  const toggleAddingGroup = (): void => setAddingGroup(!addingGroup);
  const formattedGroupList = useSelector((store: TStore) =>
    selectorGroupListForTables(store, filteredByCustomer),
  );

  const handleSearch = (value: string): void => {
    const filterTable = formattedGroupList.filter(o =>
      Object.keys(o).some(k => String(o[k]).toLowerCase().includes(value.toLowerCase())),
    );

    setFilteredTable(filterTable);
  };
  return (
    <>
      {filteredByCustomer && (
        <>
          {!!isAdmin && (
            <UIRow>
              <UICol>
                <UIButton onClick={toggleAddingGroup} style={{ color: "#000" }}>
                  {addingGroup ? l("dismiss") : l("addGroup")}
                </UIButton>
              </UICol>
            </UIRow>
          )}

          {addingGroup && (
            <FormAddGroup customerId={filteredByCustomer} onCreateCallback={toggleAddingGroup} />
          )}
          <UIRow justify="end" gutter={[16, 16]}>
            <UICol>
              <StyledUISearchInput placeholder={l("searchPlaceholder")} onSearch={handleSearch} />
            </UICol>
          </UIRow>
        </>
      )}
      <UIRow style={{ marginTop: "10px" }} gutter={[16, 16]}>
        <UICol span={24}>
          <Table
            size="middle"
            loading={groupListLoading}
            columns={groupListColumns}
            dataSource={filteredTable || formattedGroupList}
          />
        </UICol>
      </UIRow>
    </>
  );
};

const StyledUISearchInput = styled(Input.Search)`
  .anticon {
    color: #000 !important;
  }
`;

export default LayoutGroupsTable;
