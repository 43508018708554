/** @format */

import {
  TActionBrandAdminCreateFailure,
  TActionBrandAdminCreateRequest,
  TActionBrandAdminCreateSuccess,
  TActionBrandAdminDeleteFailure,
  TActionBrandAdminDeleteRequest,
  TActionBrandAdminDeleteSuccess,
  TActionBrandCreateFailure,
  TActionBrandCreateRequest,
  TActionBrandCreateSuccess,
  TActionBrandListFailure,
  TActionBrandListRequest,
  TActionBrandListSuccess,
  TActionBrandUpdateFailure,
  TActionBrandUpdateRequest,
  TActionBrandUpdateSuccess,
} from "../declarations/actions/brands";
import { TActionType } from "../declarations/actionTypes";
import {
  TBrandAdminCreateRequest,
  TBrandCreateRequest,
  TBrandList,
  TBrandUpdateRequest,
} from "../declarations/maps/brand";

export const actionBrandListRequest = (): TActionBrandListRequest => ({
  type: TActionType.brandListRequest,
});

export const actionBrandListSuccess = (payload: TBrandList): TActionBrandListSuccess => ({
  type: TActionType.brandListSuccess,
  payload,
});

export const actionBrandListFailure = (payload: string): TActionBrandListFailure => ({
  type: TActionType.brandListFailure,
  reason: payload,
});

// Create

export const actionBrandCreateRequest = (
  payload: TBrandCreateRequest,
): TActionBrandCreateRequest => ({
  type: TActionType.brandCreateRequest,
  payload,
});

export const actionBrandCreateSuccess = (): TActionBrandCreateSuccess => ({
  type: TActionType.brandCreateSuccess,
});

export const actionBrandCreateFailure = (payload: string): TActionBrandCreateFailure => ({
  type: TActionType.brandCreateFailure,
  reason: payload,
});

// Update
export const actionBrandUpdateRequest = (
  payload: TBrandUpdateRequest,
): TActionBrandUpdateRequest => ({
  type: TActionType.brandUpdateRequest,
  payload,
});

export const actionBrandUpdateSuccess = (): TActionBrandUpdateSuccess => ({
  type: TActionType.brandUpdateSuccess,
});

export const actionBrandUpdateFailure = (payload: string): TActionBrandUpdateFailure => ({
  type: TActionType.brandUpdateFailure,
  reason: payload,
});

// Create Brand Admin
export const actionBrandAdminCreateRequest = (
  payload: TBrandAdminCreateRequest,
): TActionBrandAdminCreateRequest => ({
  type: TActionType.brandAdminCreateRequest,
  payload,
});

export const actionBrandAdminCreateSuccess = (): TActionBrandAdminCreateSuccess => ({
  type: TActionType.brandAdminCreateSuccess,
});

export const actionBrandAdminCreateFailure = (payload: string): TActionBrandAdminCreateFailure => ({
  type: TActionType.brandAdminCreateFailure,
  reason: payload,
});

// Delete Group Admin
export const actionBrandAdminDeleteRequest = (
  payload: TBrandAdminCreateRequest,
): TActionBrandAdminDeleteRequest => ({
  type: TActionType.brandAdminDeleteRequest,
  payload,
});

export const actionBrandAdminDeleteSuccess = (): TActionBrandAdminDeleteSuccess => ({
  type: TActionType.brandAdminDeleteSuccess,
});

export const actionBrandAdminDeleteFailure = (payload: string): TActionBrandAdminDeleteFailure => ({
  type: TActionType.brandAdminDeleteFailure,
  reason: payload,
});
