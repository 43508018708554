/** @format */

import { AxiosError, AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { apiAdministratorList } from "../../api/people/apiAdministratorList";
import { arrayToObject } from "../../utils/format/arrayToObject";
import {
  actionAdministratorListFailure,
  actionAdministratorListSuccess,
} from "../actions/actionsAdministrator";

import { actionAlertGenerate } from "../actions/actionsUI";
import { TActionAdministratorListRequest } from "../declarations/actions/people";
import { TAPIValidationError } from "../declarations/apis/general";

export function* sagaAdministratorList(action: TActionAdministratorListRequest): unknown {
  const {
    response,
    error,
  }: { response?: AxiosResponse; error?: AxiosError<{ errors: TAPIValidationError }> } = yield call(
    apiAdministratorList,
  );

  if (!error && response) {
    //@ts-ignore
    yield put(actionAdministratorListSuccess(arrayToObject(response.data.data, "id")));
  } else {
    yield put(
      //@ts-ignore
      actionAdministratorListFailure((error?.response?.data?.errors as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.errors?.toString() || "",
      }),
    );
  }
}
