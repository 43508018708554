/** @format */

import { TCustomer } from "../declarations/maps/customer";
import { TStore } from "../declarations/store";

export const selectorCustomerList = (store: TStore): TCustomer[] =>
  Object.values(store.customer.list) || [];

export const selectorCustomer = (store: TStore, customerId: string): TCustomer | null =>
  store.customer.list[customerId] || null;
