/** @format */

import { AxiosError, AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { apiLicenseCreate } from "../../api/license/apiLicenseCreate";
import { apiLicenseDelete } from "../../api/license/apiLicenseDelete";
import { apiLicenseList } from "../../api/license/apiLicenseList";
import { apiLicenseUpdate } from "../../api/license/apiLicenseUpdate";
import { arrayToObject } from "../../utils/format/arrayToObject";
import { actionGroupListRequest } from "../actions/actionsGroup";
import {
  actionLicenseCreateFailure,
  actionLicenseCreateSuccess,
  actionLicenseDeleteFailure,
  actionLicenseDeleteSuccess,
  actionLicenseListFailure,
  actionLicenseListRequest,
  actionLicenseListSuccess,
  actionLicenseUpdateFailure,
  actionLicenseUpdateSuccess,
} from "../actions/actionsLicense";
import {
  actionAlertGenerate,
  actionLicenseListLoadingStart,
  actionLicenseListLoadingStop,
} from "../actions/actionsUI";
import {
  TActionLicenseCreateRequest,
  TActionLicenseDeleteRequest,
  TActionLicenseListRequest,
  TActionLicenseUpdateRequest,
} from "../declarations/actions/licenses";
import { TAPIValidationError } from "../declarations/apis/general";

export function* sagaLicenseList(action: TActionLicenseListRequest): unknown {
  yield put(actionLicenseListLoadingStart());

  try {
    const response = yield call(apiLicenseList);
    const data = response?.data.data;
    yield put(actionLicenseListLoadingStop());
    //@ts-ignore
    yield put(actionLicenseListSuccess(arrayToObject(data, "id")));
  } catch (e) {
    yield put(actionLicenseListLoadingStop());
    yield put(
      //@ts-ignore
      actionLicenseListFailure((e?.response?.data?.errors as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        description: e?.response?.data?.errors?.toString() || "",
      }),
    );
  }
}

export function* sagaLicenseCreate(action: TActionLicenseCreateRequest): unknown {
  yield put(actionLicenseListLoadingStart());

  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiLicenseCreate, action.payload);

  if (!error && response) {
    yield put(actionLicenseCreateSuccess());
    yield put(actionLicenseListRequest());
    yield put(actionGroupListRequest());
  } else {
    yield put(actionLicenseListLoadingStop());
    yield put(
      //@ts-ignore
      actionLicenseCreateFailure((error?.response?.data?.message as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}

export function* sagaLicenseUpdate(action: TActionLicenseUpdateRequest): unknown {
  yield put(actionLicenseListLoadingStart());

  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiLicenseUpdate, action.payload);

  if (!error && response) {
    yield put(actionLicenseUpdateSuccess());
    yield put(
      actionAlertGenerate({
        type: "success",
        message: "Success",
        //@ts-ignore
        description: "Operation successfully completed!",
      }),
    );
    yield put(actionLicenseListRequest());
  } else {
    yield put(actionLicenseListLoadingStop());
    yield put(
      //@ts-ignore
      actionLicenseUpdateFailure((error?.response?.data?.message as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}

export function* sagaLicenseDelete(action: TActionLicenseDeleteRequest): unknown {
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiLicenseDelete, action.payload);

  if (!error && response) {
    yield put(actionLicenseDeleteSuccess());
    yield put(actionLicenseListRequest());
    yield put(actionGroupListRequest());
  } else {
    yield put(
      //@ts-ignore
      actionLicenseDeleteFailure((error?.response?.data?.message as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}
