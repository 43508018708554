/** @format */

import {
  TActionRoleListFailure,
  TActionRoleListRequest,
  TActionRoleListSuccess,
} from "../declarations/actions/roles";
import { TActionType } from "../declarations/actionTypes";
import { TRoleList } from "../declarations/maps/role";

export const actionRoleListRequest = (): TActionRoleListRequest => ({
  type: TActionType.roleListRequest,
});

export const actionRoleListSuccess = (payload: TRoleList): TActionRoleListSuccess => ({
  type: TActionType.roleListSuccess,
  payload,
});

export const actionRoleListFailure = (payload: string): TActionRoleListFailure => ({
  type: TActionType.roleListFailure,
  reason: payload,
});
