/** @format */

export const API_PARAMS = {
  BASE_URL: process.env.REACT_APP_API_URL,
};
console.log("@API_BASE_URL@ " + process.env.REACT_APP_API_URL);

export enum API_ENDPOINTS {
  CSRF_TOKEN = "/sanctum/csrf-cookie",
  LOGIN = "/login",
  LOGOUT = "/logout",
  USERS_LIST = "/users",
  USER_AVATAR = "users/:id_user/avatar",
  CUSTOMER_LIST = "/customers",
  CUSTOMER_UPDATE = "/customers/:id",
  LICENSE = "/licenses",
  LICENSE_DELETE = "/licenses/:id",
  TIPOLOGY_LIST = "/tipologies",
  PEOPLE_LIST = "/people",
  PEOPLE_DETAIL = "/people/:id",
  PEOPLE_IMPORT = "/imports",
  PEOPLE_MASSIVE = "/group-people",
  IMPORT_STATUS = "/imports/:id",
  ADMINISTRATOR_LIST = "/administrators",
  ROLES = "/roles",
  GROUP = "/groups",
  GROUP_UPDATE = "/groups/:id",
  GROUP_ADMIN = "group-administrator",
  GROUP_ADMIN_DELETE = "group-administrator/:id_group/:id_person",
  BRAND_ADMIN = "brand-administrator",
  BRAND_ADMIN_DELETE = "brand-administrator/:id_brand/:id_person",
  LOCATIONS = "/offices",
  NOTICES = "/notices",
  DOCUMENTS = "/documents",
  DOCUMENT_TYPES = "/document-types",
  ACCOUNT_TYPE_LIST = "/account-types",
  BRAND_LIST = "/brands",
  BRAND = "/brands",
  BRAND_UPDATE = "/brands/:id",
  BRANCH_LIST = "/branches",
  BRANCH = "/branches",
  BRANCH_UPDATE = "/branches/:id",
  STORES = "/stores",
  STORE_DETAIL = "/stores/:id",
  STORES_MASSIVE = "/brand-stores",
  PARTNERS = "/partners",
  PARTNERS_DELETE = "/partners/:id",
  CITIES = "/cities",
  COUNTRIES = "/countries",
  STORETYPES = "/storeTypes",
}
