/** @format */

import { Breadcrumb } from "antd";
import { UICol, UIRow, UITitle, UITransition } from "@rstore-react-packages/template-ui";
import { NavLink } from "react-router-dom";
import { Routes } from "../../../utils/Routes";
import { useLanguage } from "../../../redux-modules/hooks/useLanguage";
import { useGenericAlert } from "../../../redux-modules/hooks/useGenericAlert";
import React from "react";
import LayoutBrandsTable from "../../layout/Tables/Brands";
import { useSelector } from "react-redux";
import { selectorCustomerSelected } from "../../../redux-modules/selectors/selectorsUI";
import { LayoutCustomerMenu } from "../../layout/Menu/CustomerMenu";

const ViewBrandList = (): JSX.Element => {
  //@ts-ignore

  const Alert = useGenericAlert(6000);
  const customer = useSelector(selectorCustomerSelected);

  const l = useLanguage();

  return (
    <>
      <LayoutCustomerMenu />
      <UITransition>
        {Alert}
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>
            <NavLink to={Routes.home}>Home</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <NavLink to={Routes.customer.replace(":id", `${customer?.id}`)}>
              {customer?.name}
            </NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Brands</Breadcrumb.Item>
        </Breadcrumb>
        <UIRow style={{ marginTop: "35px" }}>
          <UICol xs={24}>
            <UITitle level={3}>{l("brands")}</UITitle>
          </UICol>
        </UIRow>
        <LayoutBrandsTable />
      </UITransition>
    </>
  );
};

export default ViewBrandList;
