/** @format */

import { UIRow, UICol, UIInput } from "@rstore-react-packages/template-ui";
import { Button, Form } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { actionMassivePeopleReportsRequest } from "../../../../redux-modules/actions/actionsGeneral";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import StyledModal from "../../../ui/Modal";

/** @format */
interface Props {
  visible: boolean;
  handleOk?: () => void;
  handleCancel?: () => void;
  people: number[];
}
const ModalChangeReport = ({
  visible = false,
  handleCancel,
  handleOk,
  people,
}: Props): JSX.Element => {
  const l = useLanguage();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleFinish = (form): void => {
    dispatch(
      actionMassivePeopleReportsRequest({
        users_ids: people,
        action: "usageReports",
        report_usage_limit: form.report,
      }),
    );
    //@ts-ignore
    handleOk && handleOk();
  };

  const handleReturn = (): void => {
    handleCancel && handleCancel();
  };

  return (
    <StyledModal
      title={l("editReportUsageLimit")}
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleReturn}>
          Return
        </Button>,
        <Button key="submit" onClick={(): void => form.submit()}>
          Submit
        </Button>,
      ]}
    >
      <UIRow>
        <UICol xs={24}>
          <Form form={form} name="edit-people-report" layout="horizontal" onFinish={handleFinish}>
            <Form.Item
              name="report"
              label={"You are editing " + people.length + "'s people report usage limit with:"}
            >
              <UIInput />
            </Form.Item>
          </Form>
        </UICol>
      </UIRow>
    </StyledModal>
  );
};
export default ModalChangeReport;
