/** @format */

import React, { FC, useState } from "react";
import { UIRow, UICol } from "@rstore-react-packages/template-ui";
import { useSelector } from "react-redux";
import partnerListColumns from "../../../../constants/tables/partnerListColumns";
import { Input, Table } from "antd";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import styled from "styled-components";
import { TStore } from "../../../../redux-modules/declarations/store";
import { selectorPartnerList } from "../../../../redux-modules/selectors/selectorsPartner";
import { TPartner } from "../../../../redux-modules/declarations/maps/partner";
import { selectorPartnerListLoading } from "../../../../redux-modules/selectors/selectorsUI";

interface Props {
  filteredByCustomer?: number | false;
  onSelect: (i: React.Key[], el: TPartner[]) => void;
}

const LayoutPartneresTable: FC<Props> = ({
  filteredByCustomer = false,
  onSelect,
}: Props): JSX.Element => {
  //const dispatch = useDispatch();
  const l = useLanguage();
  const isTableLoading = useSelector(selectorPartnerListLoading);
  const [filteredTable, setFilteredTable] = useState<TPartner[] | null>(null);
  const formattedPartnerList = useSelector((store: TStore) => selectorPartnerList(store))
    .map(el => ({
      ...el,
      key: el.id,
      name: el.name.toUpperCase(),
    }))
    .sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  /*   //@ts-ignore
  const onChange = (selectedRowKeys: React.Key[], selectedRows: DataType[]): void => {
    onSelect(selectedRowKeys, selectedRows);
  }; */

  const handleSearch = (value: string): void => {
    const filterTable = formattedPartnerList.filter(o =>
      Object.keys(o).some(k => String(o[k]).toLowerCase().includes(value.toLowerCase())),
    );

    setFilteredTable(filterTable);
  };
  return (
    <>
      <UIRow justify="end" gutter={[16, 16]}>
        <UICol>
          <StyledUISearchInput placeholder={l("searchPlaceholder")} onSearch={handleSearch} />
        </UICol>
      </UIRow>
      <UIRow style={{ marginTop: "10px" }} gutter={[16, 16]}>
        <UICol span={24}>
          <Table
            size="middle"
            loading={isTableLoading}
            /*             rowSelection={{ type: "checkbox", onChange: onChange }}
             */ columns={partnerListColumns}
            dataSource={filteredTable || formattedPartnerList}
          />
        </UICol>
      </UIRow>
    </>
  );
};

const StyledUISearchInput = styled(Input.Search)`
  .anticon {
    color: #000 !important;
  }
`;

export default LayoutPartneresTable;
