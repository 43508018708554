/** @format */

import { TActionType } from "../declarations/actionTypes";
import { TActionLoginSuccess, TActionLogoutSuccess } from "../declarations/actions/auth";
import { TAuth } from "../declarations/maps/auth";

type Actions = TActionLoginSuccess | TActionLogoutSuccess;

export const reducerAuth = (prevStore: TAuth = {}, action: Actions): TAuth => {
  switch (action.type) {
    case TActionType.loginSuccess:
      return { user: action.payload.user, isAdmin: action.payload.isAdmin };
    case TActionType.logoutSuccess:
      return {};
    default:
      return prevStore;
  }
};
