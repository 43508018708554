/** @format */

import React, { FC } from "react";
import { Footer } from "antd/lib/layout/layout";

const LayoutFooter: FC = (): JSX.Element => (
  <Footer style={{ textAlign: "center" }}>
    Do you need any help?{" "}
    <a href="mailto:support@vuem.app" target="__blank" style={{ color: "rgb(77, 151, 254)" }}>
      Open a Ticket
    </a>
  </Footer>
);

export default LayoutFooter;
