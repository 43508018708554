/** @format */

import { AxiosError, AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { apiBranchCreate } from "../../api/branch/apiBranchCreate";
import { apiBranchList } from "../../api/branch/apiBranchList";
import { apiBranchUpdate } from "../../api/branch/apiBranchUpdate";
import { arrayToObject } from "../../utils/format/arrayToObject";
import {
  actionBranchCreateFailure,
  actionBranchCreateSuccess,
  actionBranchListFailure,
  actionBranchListRequest,
  actionBranchListSuccess,
  actionBranchUpdateFailure,
  actionBranchUpdateSuccess,
} from "../actions/actionsBranch";

import { actionAlertGenerate } from "../actions/actionsUI";
import {
  TActionBranchCreateRequest,
  TActionBranchListRequest,
  TActionBranchUpdateRequest,
} from "../declarations/actions/branches";
import { TAPIValidationError } from "../declarations/apis/general";

export function* sagaBranchList(action: TActionBranchListRequest): unknown {
  const {
    response,
    error,
  }: { response?: AxiosResponse; error?: AxiosError<{ errors: TAPIValidationError }> } = yield call(
    apiBranchList,
  );

  if (!error && response) {
    //@ts-ignore
    yield put(actionBranchListSuccess(arrayToObject(response.data.data, "id")));
  } else {
    yield put(
      //@ts-ignore
      actionBranchListFailure((error?.response?.data?.errors as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.errors?.toString() || "",
      }),
    );
  }
}

export function* sagaBranchCreate(action: TActionBranchCreateRequest): unknown {
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiBranchCreate, action.payload);

  if (!error && response) {
    yield put(actionBranchCreateSuccess());
    //@ts-ignore
    yield put(actionBranchListRequest());
  } else {
    yield put(
      //@ts-ignore
      actionBranchCreateFailure((error?.response?.data?.message as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}

export function* sagaBranchUpdate(action: TActionBranchUpdateRequest): unknown {
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiBranchUpdate, action.payload);

  if (!error && response) {
    yield put(actionBranchUpdateSuccess());
    yield put(actionBranchListRequest());
  } else {
    yield put(
      //@ts-ignore
      actionBranchUpdateFailure((error?.response?.data?.message as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}
