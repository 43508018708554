/** @format */

import {
  TActionAlertGenerate,
  TActionAlertRemove,
  TActionCustomerListLoadingStart,
  TActionCustomerListLoadingStop,
  TActionCustomerSelect,
  TActionGroupListLoadingStart,
  TActionGroupListLoadingStop,
  TActionImportPeopleLoadingStart,
  TActionImportPeopleLoadingStop,
  TActionLicenseListLoadingStart,
  TActionLicenseListLoadingStop,
  TActionNavigationFailure,
  TActionNavigationRequest,
  TActionNavigationSuccess,
  TActionPartnerListLoadingStart,
  TActionPartnerListLoadingStop,
  TActionPeopleListLoadingStart,
  TActionPeopleListLoadingStop,
  TActionSetLanguage,
  TActionStaticAlertGenerate,
  TActionStaticAlertRemove,
  TActionStoreListLoadingStart,
  TActionStoreListLoadingStop,
} from "../declarations/actions/ui";
/** @format */

import { TActionType } from "../declarations/actionTypes";
import { TLanguage, TAlert } from "../declarations/maps/ui";

export const actionNavigationRequest = (payload: number): TActionNavigationRequest => ({
  type: TActionType.navigationRequest,
  payload,
});

export const actionNavigationSuccess = (payload: unknown): TActionNavigationSuccess => ({
  type: TActionType.navigationSuccess,
  payload,
});

export const actionNavigationFailure = (): TActionNavigationFailure => ({
  type: TActionType.navigationFailure,
});

// Alerts
export const actionAlertGenerate = (payload: TAlert): TActionAlertGenerate => ({
  type: TActionType.alertGenerate,
  payload,
});

export const actionAlertRemove = (): TActionAlertRemove => ({
  type: TActionType.alertRemove,
});

export const actionStaticAlertGenerate = (payload: TAlert): TActionStaticAlertGenerate => ({
  type: TActionType.staticAlertGenerate,
  payload,
});

export const actionStaticAlertRemove = (): TActionStaticAlertRemove => ({
  type: TActionType.staticAlertRemove,
});

// Language
export const actionLanguageSet = (payload: TLanguage): TActionSetLanguage => ({
  type: TActionType.setLanguage,
  payload,
});

export const actionCustomerSelect = (payload: number): TActionCustomerSelect => ({
  type: TActionType.customerSelect,
  payload,
});

// Loaders
export const actionCustomerListLoadingStart = (): TActionCustomerListLoadingStart => ({
  type: TActionType.customerListLoadingStart,
});
export const actionCustomerListLoadingStop = (): TActionCustomerListLoadingStop => ({
  type: TActionType.customerListLoadingStop,
});

export const actionPartnerListLoadingStart = (): TActionPartnerListLoadingStart => ({
  type: TActionType.partnerListLoadingStart,
});
export const actionPartnerListLoadingStop = (): TActionPartnerListLoadingStop => ({
  type: TActionType.partnerListLoadingStop,
});

export const actionGroupListLoadingStart = (): TActionGroupListLoadingStart => ({
  type: TActionType.groupListLoadingStart,
});
export const actionGroupListLoadingStop = (): TActionGroupListLoadingStop => ({
  type: TActionType.groupListLoadingStop,
});

export const actionLicenseListLoadingStart = (): TActionLicenseListLoadingStart => ({
  type: TActionType.licenseListLoadingStart,
});
export const actionLicenseListLoadingStop = (): TActionLicenseListLoadingStop => ({
  type: TActionType.licenseListLoadingStop,
});

export const actionPeopleListLoadingStart = (): TActionPeopleListLoadingStart => ({
  type: TActionType.peopleListLoadingStart,
});
export const actionPeopleListLoadingStop = (): TActionPeopleListLoadingStop => ({
  type: TActionType.peopleListLoadingStop,
});

export const actionImportPeopleLoadingStart = (): TActionImportPeopleLoadingStart => ({
  type: TActionType.importPeopleLoadingStart,
});
export const actionImportPeopleLoadingStop = (): TActionImportPeopleLoadingStop => ({
  type: TActionType.importPeopleLoadingStop,
});

export const actionStoreListLoadingStart = (): TActionStoreListLoadingStart => ({
  type: TActionType.storeListLoadingStart,
});
export const actionStoreListLoadingStop = (): TActionStoreListLoadingStop => ({
  type: TActionType.storeListLoadingStop,
});
