/** @format */

import React, { FC } from "react";
import { UIRow, UICol, UITitle, UICard } from "@rstore-react-packages/template-ui";
import Meta from "antd/lib/card/Meta";
import styled from "styled-components";
import { useLanguage } from "../../../redux-modules/hooks/useLanguage";
import { useDispatch, useSelector } from "react-redux";
import { actionCustomerSelect } from "../../../redux-modules/actions/actionsUI";
import { selectorCustomerList } from "../../../redux-modules/selectors/selectorsCustomer";
import { useHistory } from "react-router";
import { Routes } from "../../../utils/Routes";
import { selectorCustomerListLoading } from "../../../redux-modules/selectors/selectorsUI";

const LayoutCustomerSelection: FC = (): JSX.Element => {
  const history = useHistory();
  const l = useLanguage();
  const dispatch = useDispatch();
  const customers = useSelector(selectorCustomerList);
  const customersLoading = useSelector(selectorCustomerListLoading);

  const handleSelection = (number): void => {
    dispatch(actionCustomerSelect(number));
    history.push(Routes.customer.replace(":id", `${number}`));
  };
  return (
    <>
      <UIRow style={{ marginTop: "30px" }}>
        <UICol xs={24}>
          <UITitle level={3}>{l("selectCustomer")}</UITitle>
        </UICol>
      </UIRow>
      <UIRow style={{ marginTop: "20px", marginBottom: "20px" }} data-cy-customer-list>
        {customersLoading ? (
          <>
            {Array.from(Array(4).keys()).map(i => (
              <UICol
                key={i}
                sm={12}
                lg={6}
                xxl={4}
                xs={24}
                //@ts-ignore
                style={{ textAlign: "-webkit-center", marginTop: "5px", marginBottom: "5px" }}
              >
                <UICard style={{ width: 230, marginTop: 16 }} loading={customersLoading}></UICard>
              </UICol>
            ))}
          </>
        ) : (
          customers.map(customer => (
            <UICol
              key={customer.id}
              sm={12}
              lg={6}
              xxl={4}
              xs={24}
              //@ts-ignore
              style={{ textAlign: "-webkit-center", marginTop: "5px", marginBottom: "5px" }}
            >
              <StyledUICard
                hoverable
                onClick={(): void => handleSelection(customer.id)}
                style={{ width: 240 }}
                cover={<img alt={customer.name} src={customer.logo} />}
              >
                <Meta title={customer.name} />
              </StyledUICard>
            </UICol>
          ))
        )}
      </UIRow>
    </>
  );
};

const StyledUICard = styled(UICard)`
  .ant-card-cover {
    margin: 0 !important;
  }
`;
export default LayoutCustomerSelection;
