/** @format */

import {
  TActionPeopleListRequest,
  TActionPeopleListSuccess,
  TActionPeopleListFailure,
  TActionPeopleImportRequest,
  TActionPeopleImportSuccess,
  TActionPeopleImportFailure,
  TActionPeopleImportPollingStart,
  TActionPeopleImportPollingStop,
  TActionPeopleImportPollingError,
  TActionPeopleUpdateRequest,
  TActionPeopleUpdateSuccess,
  TActionPeopleUpdateFailure,
  TActionPeopleCreateFailure,
  TActionPeopleCreateRequest,
  TActionPeopleCreateSuccess,
} from "../declarations/actions/people";
import { TActionType } from "../declarations/actionTypes";
import {
  TPeopleCreateRequest,
  TPeopleImportRequest,
  TPeopleList,
  TPeopleUpdateRequest,
} from "../declarations/maps/people";

export const actionPeopleListRequest = (): TActionPeopleListRequest => ({
  type: TActionType.peopleListRequest,
});

export const actionPeopleListSuccess = (payload: TPeopleList): TActionPeopleListSuccess => ({
  type: TActionType.peopleListSuccess,
  payload,
});

export const actionPeopleListFailure = (payload: string): TActionPeopleListFailure => ({
  type: TActionType.peopleListFailure,
  reason: payload,
});

export const actionPeopleCreateRequest = (
  payload: TPeopleCreateRequest,
): TActionPeopleCreateRequest => ({
  type: TActionType.peopleCreateRequest,
  payload,
});

export const actionPeopleCreateSuccess = (): TActionPeopleCreateSuccess => ({
  type: TActionType.peopleCreateSuccess,
});

export const actionPeopleCreateFailure = (payload: string): TActionPeopleCreateFailure => ({
  type: TActionType.peopleCreateFailure,
  reason: payload,
});

export const actionPeopleUpdateRequest = (
  payload: TPeopleUpdateRequest,
): TActionPeopleUpdateRequest => ({
  type: TActionType.peopleUpdateRequest,
  payload,
});

export const actionPeopleUpdateSuccess = (): TActionPeopleUpdateSuccess => ({
  type: TActionType.peopleUpdateSuccess,
});

export const actionPeopleUpdateFailure = (payload: string): TActionPeopleUpdateFailure => ({
  type: TActionType.peopleUpdateFailure,
  reason: payload,
});

// Import
export const actionPeopleImportRequest = (
  payload: TPeopleImportRequest,
): TActionPeopleImportRequest => ({
  type: TActionType.peopleImportRequest,
  payload,
});

export const actionPeopleImportSuccess = (): TActionPeopleImportSuccess => ({
  type: TActionType.peopleImportSuccess,
});

export const actionPeopleImportFailure = (payload: string): TActionPeopleImportFailure => ({
  type: TActionType.peopleImportFailure,
  reason: payload,
});

export const actionPeoplePollingStart = (payload: number): TActionPeopleImportPollingStart => ({
  type: TActionType.peopleImportPollingStart,
  payload,
});

export const actionPeoplePollingStop = (): TActionPeopleImportPollingStop => ({
  type: TActionType.peopleImportPollingStop,
});

export const actionPeoplePollingError = (): TActionPeopleImportPollingError => ({
  type: TActionType.peopleImportPollingError,
});
