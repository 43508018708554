/** @format */

export enum Routes {
  home = "/",
  login = "/login",
  forgotPassword = "/recovery/password",
  settings = "/configurazione",
  customer = "/customer/:id",
  group = "/group/:id",
  brands = "/brands",
  brand = "/brand/:id",
  branch = "/branches",
  partners = "/partners",
  peopleDetail = "/people/:id",
  storeDetail = "/store/:id",
}
