/** @format */

import { AxiosError, AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../../constants/api";
import { TGroupCreateRequest } from "../../redux-modules/declarations/maps/group";
import apiClient from "../../utils/apiClient";

export const apiGroupCreate = (
  data: TGroupCreateRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .post(API_ENDPOINTS.GROUP, data)
    .then(response => ({ response }))
    .catch(error => ({ error }));
