/** @format */

import { TStore } from "../declarations/store";
import { TAPIValidationError } from "../declarations/apis/general";
import { TLanguage, TAlert } from "../declarations/maps/ui";
import { TCustomer } from "../declarations/maps/customer";

export const selectorLoginValidation = (store: TStore): TAPIValidationError =>
  store.ui.loginValidation || {};

export const selectorAlert = (store: TStore): TAlert | undefined => store.ui.alert;
export const selectorStaticAlert = (store: TStore): TAlert | undefined => store.ui.staticAlert;

export const selectorLanguage = (store: TStore): TLanguage | undefined => store.ui.lang;

export const selectorCustomerSelected = (store: TStore): TCustomer | undefined => {
  const customerId = store.ui.selectedCustomerId;
  return !!customerId ? store.customer.list[customerId] : undefined;
};

export const selectorReduxStoreAge = (store: TStore): number => store.ui.timestamp;
// Loaders
export const selectorCustomerListLoading = (store: TStore): boolean =>
  store.ui.loaders.customerList;
export const selectorGroupListLoading = (store: TStore): boolean => store.ui.loaders.groupList;
export const selectorLicenseListLoading = (store: TStore): boolean => store.ui.loaders.licenseList;
export const selectorPeopleListLoading = (store: TStore): boolean => store.ui.loaders.peopleList;
export const selectorStoreListLoading = (store: TStore): boolean => store.ui.loaders.storeList;
export const selectorPartnerListLoading = (store: TStore): boolean => store.ui.loaders.partnerList;
export const selectorImportPeopleLoading = (store: TStore): boolean =>
  store.ui.loaders.importPeople;
