/** @format */

import {
  TActionAccountTypeListFailure,
  TActionAccountTypeListRequest,
  TActionAccountTypeListSuccess,
} from "../declarations/actions/accountTypes";
import { TActionType } from "../declarations/actionTypes";
import { TAccountTypeList } from "../declarations/maps/accountType";

export const actionAccountTypeListRequest = (): TActionAccountTypeListRequest => ({
  type: TActionType.accountTypeListRequest,
});

export const actionAccountTypeListSuccess = (
  payload: TAccountTypeList,
): TActionAccountTypeListSuccess => ({
  type: TActionType.accountTypeListSuccess,
  payload,
});

export const actionAccountTypeListFailure = (payload: string): TActionAccountTypeListFailure => ({
  type: TActionType.accountTypeListFailure,
  reason: payload,
});
