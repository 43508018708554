/** @format */

import { Col, Row, Tag } from "antd";
import React from "react";
import { ColumnsType } from "antd/lib/table";
import { NavLink } from "react-router-dom";
import { Routes } from "../../utils/Routes";
import { SettingOutlined } from "@ant-design/icons";

export const peopleListColumns: ColumnsType<object> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Surname",
    dataIndex: "surname",
    key: "surname",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Brands",
    dataIndex: "brands",
    key: "brands",
  },
  {
    title: "Branch / Partner",
    dataIndex: "branchpartner",
    key: "branchpartner",
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
  },
  {
    title: "Account Type",
    dataIndex: "account_type",
    key: "account_type",
  },
  {
    title: "License",
    dataIndex: "license_id",
    key: "license_id",
  },
  {
    title: "Plan Starting",
    dataIndex: "plan_start_date",
    key: "plan_start_date",
  },
  {
    title: "Plan Ending",
    dataIndex: "plan_end_date",
    key: "plan_end_date",
  },
  {
    title: "Reports",
    dataIndex: "reports",
    key: "reports",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    // eslint-disable-next-line react/display-name
    render: (e, r, i): JSX.Element => <Tags text={e} row={r} index={i} />,
  },
  {
    align: "center",
    title: <SettingOutlined />,
    dataIndex: "actions",
    key: "actions",
    //@ts-ignore
    // eslint-disable-next-line react/display-name
    render: (text, row, index): JSX.Element => (
      <ActionDropdown text={text} row={row} index={index} />
    ),
  },
];

//@ts-ignore
const ActionDropdown = ({ text, row, index }): JSX.Element => {
  return (
    <Row gutter={[12, 0]}>
      <Col>
        {
          <NavLink to={Routes.peopleDetail.replace(":id", row.id)} style={{ color: "#0e7cdc" }}>
            View
          </NavLink>
        }
      </Col>
    </Row>
  );
};

//@ts-ignore
const Tags = ({ text, row, index }): JSX.Element => {
  switch (row.status) {
    case "Active":
      return <Tag color="green">Active</Tag>;
    case "Pending":
      return <Tag color="geekblue">Pending</Tag>;
    case "Paused":
      return <Tag color="orange">Paused</Tag>;
    case "Deleted":
      return <Tag color="red">Deleted</Tag>;
  }
  return <></>;
};
