/** @format */

import { TActionStoreListSuccess } from "../declarations/actions/store";
import { TActionType } from "../declarations/actionTypes";
import { TTStoreState } from "../declarations/maps/store";

type Actions = TActionStoreListSuccess;

const defaultState = {
  list: {
    1: {
      id: 1,
      name: "EA",
      brands: [{ id: 1, name: "EA", customer_id: 1 }],
      branch: { id: 1, name: "XX", customer_id: 1, canDelete: false },
      partner: { id: 18, name: "Carmi", customer_id: 1, canDelete: true },
      address: "xxx",
      details: {
        data: [
          {
            id: 1,
            key: "line",
            value: "EA RTW + ACC",
          },
        ],
      },
    },
  },
};

export const reducerStore = (state: TTStoreState = defaultState, action: Actions): TTStoreState => {
  switch (action.type) {
    case TActionType.storeListSuccess:
      return { list: { ...action.payload } };
    default:
      return state;
  }
};
