/** @format */

import {
  TActionPartnerListRequest,
  TActionPartnerListSuccess,
  TActionPartnerListFailure,
  TActionPartnerDeleteFailure,
  TActionPartnerDeleteRequest,
  TActionPartnerDeleteSuccess,
} from "../declarations/actions/partner";
import { TActionType } from "../declarations/actionTypes";
import { TPartnerList } from "../declarations/maps/partner";

export const actionPartnerListRequest = (): TActionPartnerListRequest => ({
  type: TActionType.partnerListRequest,
});

export const actionPartnerListSuccess = (payload: TPartnerList): TActionPartnerListSuccess => ({
  type: TActionType.partnerListSuccess,
  payload,
});

export const actionPartnerListFailure = (payload: string): TActionPartnerListFailure => ({
  type: TActionType.partnerListFailure,
  reason: payload,
});

export const actionPartnerDeleteRequest = (payload: number): TActionPartnerDeleteRequest => ({
  type: TActionType.partnerDeleteRequest,
  payload,
});

export const actionPartnerDeleteSuccess = (): TActionPartnerDeleteSuccess => ({
  type: TActionType.partnerDeleteSuccess,
});

export const actionPartnerDeleteFailure = (payload: string): TActionPartnerDeleteFailure => ({
  type: TActionType.partnerDeleteFailure,
  reason: payload,
});
