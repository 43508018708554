/** @format */

import { call, put } from "redux-saga/effects";
import { apiTipologyList } from "../../api/tipology/apiTipologyList";
import { arrayToObject } from "../../utils/format/arrayToObject";
import { actionTipologyListFailure, actionTipologyListSuccess } from "../actions/actionsTipology";
import { actionAlertGenerate } from "../actions/actionsUI";
import { TActionTipologyListRequest } from "../declarations/actions/tipologies";
import { TAPIValidationError } from "../declarations/apis/general";

export function* sagaTipologyList(action: TActionTipologyListRequest): unknown {
  try {
    const response = yield call(apiTipologyList);
    const data = response?.data.data;

    //@ts-ignore
    yield put(actionTipologyListSuccess(arrayToObject(data, "id")));
  } catch (e) {
    yield put(
      //@ts-ignore
      actionTipologyListFailure((e?.response?.data?.errors as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        description: e?.response?.data?.errors?.toString() || "",
      }),
    );
  }
}
