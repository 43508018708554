/** @format */

import { UIRow, UICol, UIInput, UITextArea, UIButton } from "@rstore-react-packages/template-ui";
import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGroupAdminCreateRequest,
  actionGroupAdminDeleteRequest,
} from "../../../../redux-modules/actions/actionsGroup";
import { TGroup } from "../../../../redux-modules/declarations/maps/group";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import { selectorAdministratorListForSelect } from "../../../../redux-modules/selectors/selectorsPeople";
const { Option } = Select;

interface Props {
  group: TGroup;
  onFinishCallback: (form) => void;
  onCloseForm: () => void;
}
const FormEditGroup = ({ group, onFinishCallback, onCloseForm }: Props): JSX.Element => {
  const l = useLanguage();
  const dispatch = useDispatch();
  const administrators = useSelector(selectorAdministratorListForSelect);
  const [selectedAdministrators, setSelectedAdministrators] = useState(group.administrators);

  const handleSelect = (val, opt): void => {
    if (val.length < selectedAdministrators.length) {
      const removed = selectedAdministrators.find(x => !val.includes(x.person_id))?.person_id;
      dispatch(
        actionGroupAdminDeleteRequest({
          group_id: group.id,
          //@ts-ignore
          person_id: removed,
        }),
      );
    }
    if (val.length > selectedAdministrators.length) {
      dispatch(
        actionGroupAdminCreateRequest({
          group_id: group.id,
          person_id: val[val.length - 1],
        }),
      );
    }
  };

  useEffect(() => {
    setSelectedAdministrators(group.administrators);
  }, [group.administrators]);

  return (
    <Form
      name="edit-customer"
      layout="horizontal"
      onFinish={onFinishCallback}
      initialValues={group}
    >
      <UIRow style={{ marginTop: "10px" }}>
        <UICol xs={24} md={12}>
          <UIRow>
            <UICol xs={24}>
              <Form.Item name="name" label={l("name")}>
                <UIInput />
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={24}>
              <Form.Item name="tipology" label={l("tipology")}>
                <UIInput />
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={24}>
              <Form.Item name="contractor_name" label={l("contractorName")}>
                <UIInput />
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={24}>
              <Form.Item name="contractor_phone" label={l("contractorPhone")}>
                <UIInput />
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={24}>
              <Form.Item name="contractor_email" label={l("contractorEmail")}>
                <UIInput />
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={24}>
              <Form.Item name="note" label={l("note")}>
                <UITextArea />
              </Form.Item>
            </UICol>
          </UIRow>
        </UICol>
        <UICol xs={24} md={11} offset={1}>
          <Form.Item
            label={l("administrators")}
            name="admins"
            initialValue={group.administrators.map(el => el.person_id)}
          >
            <Select mode="multiple" onChange={(val, opt): void => handleSelect(val, opt)}>
              {administrators.map(el => {
                return (
                  <Option value={el.value} key={el.key}>
                    {el.name} {el.surname}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </UICol>
      </UIRow>
      <UIRow>
        <UICol xs={24} md={12}>
          <UIRow>
            <UICol xs={24}>
              <UIRow justify="end">
                <UICol>
                  <UIButton style={{ color: "black" }} onClick={onCloseForm}>
                    {l("dismiss")}
                  </UIButton>
                </UICol>
                <UICol offset={1}>
                  <Form.Item>
                    <UIButton style={{ color: "black" }} htmlType="submit">
                      {l("save")}
                    </UIButton>
                  </Form.Item>
                </UICol>
              </UIRow>
            </UICol>
          </UIRow>
        </UICol>
      </UIRow>
    </Form>
  );
};

export default FormEditGroup;
