/** @format */

import {
  TActionStoreListRequest,
  TActionStoreListSuccess,
  TActionStoreListFailure,
  TActionMassiveStoreBrandRequest,
  TActionMassiveStoreBrandSuccess,
  TActionMassiveStoreBrandFailure,
  TActionMassiveStoreBranchRequest,
  TActionMassiveStoreBranchSuccess,
  TActionMassiveStoreBranchFailure,
  TActionMassiveStorePartnerRequest,
  TActionMassiveStorePartnerSuccess,
  TActionMassiveStorePartnerFailure,
  TActionMassiveStoreStatusRequest,
  TActionMassiveStoreStatusSuccess,
  TActionMassiveStoreStatusFailure,
  TActionStoreUpdateRequest,
  TActionStoreUpdateSuccess,
  TActionStoreUpdateFailure,
  TActionStoreCreateFailure,
  TActionStoreCreateRequest,
  TActionStoreCreateSuccess,
} from "../declarations/actions/store";
import { TActionType } from "../declarations/actionTypes";

import {
  TTStoreList,
  TMassiveStoreBrandRequest,
  TMassiveStoreBranchRequest,
  TMassiveStorePartnerRequest,
  TMassiveStoreStatusRequest,
  TTStoreUpdateRequest,
  TTStoreCreateRequest,
} from "../declarations/maps/store";

export const actionStoreListRequest = (): TActionStoreListRequest => ({
  type: TActionType.storeListRequest,
});

export const actionStoreListSuccess = (payload: TTStoreList): TActionStoreListSuccess => ({
  type: TActionType.storeListSuccess,
  payload,
});

export const actionStoreListFailure = (payload: string): TActionStoreListFailure => ({
  type: TActionType.storeListFailure,
  reason: payload,
});

export const actionStoreCreateRequest = (
  payload: TTStoreCreateRequest,
): TActionStoreCreateRequest => ({
  type: TActionType.storeCreateRequest,
  payload,
});

export const actionStoreCreateSuccess = (): TActionStoreCreateSuccess => ({
  type: TActionType.storeCreateSuccess,
});

export const actionStoreCreateFailure = (payload: string): TActionStoreCreateFailure => ({
  type: TActionType.storeCreateFailure,
  reason: payload,
});

export const actionStoreUpdateRequest = (
  payload: TTStoreUpdateRequest,
): TActionStoreUpdateRequest => ({
  type: TActionType.storeUpdateRequest,
  payload,
});

export const actionStoreUpdateSuccess = (): TActionStoreUpdateSuccess => ({
  type: TActionType.storeUpdateSuccess,
});

export const actionStoreUpdateFailure = (payload: string): TActionStoreUpdateFailure => ({
  type: TActionType.storeUpdateFailure,
  reason: payload,
});

// Massive Store Brand
export const actionMassiveStoreBrandRequest = (
  payload: TMassiveStoreBrandRequest,
): TActionMassiveStoreBrandRequest => ({
  type: TActionType.massiveStoreBrandRequest,
  payload,
});

export const actionMassiveStoreBrandSuccess = (): TActionMassiveStoreBrandSuccess => ({
  type: TActionType.massiveStoreBrandSuccess,
});

export const actionMassiveStoreBrandFailure = (
  payload: string,
): TActionMassiveStoreBrandFailure => ({
  type: TActionType.massiveStoreBrandFailure,
  reason: payload,
});

// Massive Store Branch
export const actionMassiveStoreBranchRequest = (
  payload: TMassiveStoreBranchRequest,
): TActionMassiveStoreBranchRequest => ({
  type: TActionType.massiveStoreBranchRequest,
  payload,
});

export const actionMassiveStoreBranchSuccess = (): TActionMassiveStoreBranchSuccess => ({
  type: TActionType.massiveStoreBranchSuccess,
});

export const actionMassiveStoreBranchFailure = (
  payload: string,
): TActionMassiveStoreBranchFailure => ({
  type: TActionType.massiveStoreBranchFailure,
  reason: payload,
});

// Massive Store Partner
export const actionMassiveStorePartnerRequest = (
  payload: TMassiveStorePartnerRequest,
): TActionMassiveStorePartnerRequest => ({
  type: TActionType.massiveStorePartnerRequest,
  payload,
});

export const actionMassiveStorePartnerSuccess = (): TActionMassiveStorePartnerSuccess => ({
  type: TActionType.massiveStorePartnerSuccess,
});

export const actionMassiveStorePartnerFailure = (
  payload: string,
): TActionMassiveStorePartnerFailure => ({
  type: TActionType.massiveStorePartnerFailure,
  reason: payload,
});

// Massive Store Status
export const actionMassiveStoreStatusRequest = (
  payload: TMassiveStoreStatusRequest,
): TActionMassiveStoreStatusRequest => ({
  type: TActionType.massiveStoreStatusRequest,
  payload,
});

export const actionMassiveStoreStatusSuccess = (): TActionMassiveStoreStatusSuccess => ({
  type: TActionType.massiveStoreStatusSuccess,
});

export const actionMassiveStoreStatusFailure = (
  payload: string,
): TActionMassiveStoreStatusFailure => ({
  type: TActionType.massiveStoreStatusFailure,
  reason: payload,
});
