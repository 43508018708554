/** @format */

import { AxiosError, AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../../constants/api";
import { TGroupUpdateRequest } from "../../redux-modules/declarations/maps/group";
import apiClient from "../../utils/apiClient";

export const apiGroupUpdate = (
  data: TGroupUpdateRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .put(API_ENDPOINTS.GROUP_UPDATE.replace(":id", `${data.id}`), data)
    .then(response => ({ response }))
    .catch(error => ({ error }));
