/** @format */

import { AxiosError, AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { apiPartnerDelete } from "../../api/partner/apiPartnerDelete";
import { apiPartnerList } from "../../api/partner/apiPartnerList";
import { arrayToObject } from "../../utils/format/arrayToObject";
import { actionGroupListRequest } from "../actions/actionsGroup";
import {
  actionPartnerDeleteFailure,
  actionPartnerDeleteSuccess,
  actionPartnerListFailure,
  actionPartnerListRequest,
  actionPartnerListSuccess,
} from "../actions/actionsPartner";

import {
  actionAlertGenerate,
  actionPartnerListLoadingStart,
  actionPartnerListLoadingStop,
} from "../actions/actionsUI";
import {
  TActionPartnerDeleteRequest,
  TActionPartnerListRequest,
} from "../declarations/actions/partner";
import { TAPIValidationError } from "../declarations/apis/general";

export function* sagaPartnerList(action: TActionPartnerListRequest): unknown {
  const {
    response,
    error,
  }: { response?: AxiosResponse; error?: AxiosError<{ errors: TAPIValidationError }> } = yield call(
    apiPartnerList,
  );

  if (!error && response) {
    //@ts-ignore
    yield put(actionPartnerListSuccess(arrayToObject(response.data.data, "id")));
  } else {
    yield put(
      //@ts-ignore
      actionPartnerListFailure((error?.response?.data?.errors as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.errors?.toString() || "",
      }),
    );
  }
}

export function* sagaPartnerDelete(action: TActionPartnerDeleteRequest): unknown {
  yield put(actionPartnerListLoadingStart());
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiPartnerDelete, action.payload);

  if (!error && response) {
    yield put(actionPartnerDeleteSuccess());
    yield put(actionPartnerListRequest());
    yield put(actionPartnerListLoadingStop());
    yield put(actionGroupListRequest());
  } else {
    yield put(actionPartnerListLoadingStop());
    yield put(
      //@ts-ignore
      actionPartnerDeleteFailure((error?.response?.data?.message as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}
