/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import {
  sagaLicenseCreate,
  sagaLicenseDelete,
  sagaLicenseList,
  sagaLicenseUpdate,
} from "../sagas/sagasLicense";

export function* watcherLicense(): unknown {
  yield takeLatest(TActionType.licenseListRequest, sagaLicenseList);
  yield takeLatest(TActionType.licenseCreateRequest, sagaLicenseCreate);
  yield takeLatest(TActionType.licenseUpdateRequest, sagaLicenseUpdate);
  yield takeLatest(TActionType.licenseDeleteRequest, sagaLicenseDelete);
}
