/** @format */

import { UIRow, UICol, UIText } from "@rstore-react-packages/template-ui";
import { Colors } from "@rstore-react-packages/template-ui/lib/theme/maps/Colors";
import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionBrandAdminDeleteRequest,
  actionBrandAdminCreateRequest,
} from "../../../../redux-modules/actions/actionsBrand";
import { TBrand } from "../../../../redux-modules/declarations/maps/brand";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import { selectorAdministratorListForSelect } from "../../../../redux-modules/selectors/selectorsPeople";
const { Option } = Select;

interface Props {
  brand: TBrand;
  onFinishCallback: (form) => void;
  onCloseForm: () => void;
}
const FormEditBrand = ({ brand, onFinishCallback, onCloseForm }: Props): JSX.Element => {
  const l = useLanguage();
  const dispatch = useDispatch();
  const administrators = useSelector(selectorAdministratorListForSelect);
  const [selectedAdministrators, setSelectedAdministrators] = useState(brand.administrators);

  const handleSelect = (val, opt): void => {
    //@ts-ignore
    if (val.length < selectedAdministrators.length) {
      const removed = selectedAdministrators?.find(x => !val.includes(x.person_id))?.person_id;

      dispatch(
        actionBrandAdminDeleteRequest({
          brand_id: brand.id,
          //@ts-ignore
          person_id: removed,
        }),
      );
    }
    //@ts-ignore
    if (val.length > selectedAdministrators?.length) {
      dispatch(
        actionBrandAdminCreateRequest({
          brand_id: brand.id,
          person_id: val[val.length - 1],
        }),
      );
    }
  };

  useEffect(() => {
    setSelectedAdministrators(brand.administrators);
  }, [brand.administrators]);

  return (
    <Form
      name="edit-customer"
      layout="horizontal"
      onFinish={onFinishCallback}
      initialValues={brand}
    >
      <UIRow style={{ marginTop: "10px" }}>
        <UICol xs={24} md={12}>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol style={{ display: "inline-flex" }}>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("name")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                {brand.name}
              </UIText>
            </UICol>
          </UIRow>
        </UICol>
        <UICol xs={24} md={11} offset={1}>
          <Form.Item
            label={l("administrators")}
            name="admins"
            initialValue={brand.administrators?.map(el => el.person_id)}
          >
            <Select mode="multiple" onChange={(val, opt): void => handleSelect(val, opt)}>
              {administrators.map(el => {
                return (
                  <Option value={el.value} key={el.key}>
                    {el.name} {el.surname}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </UICol>
      </UIRow>
    </Form>
  );
};

export default FormEditBrand;
