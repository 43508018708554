/** @format */

import { UIRow, UICol, UIText, UITitle } from "@rstore-react-packages/template-ui";
import { Divider } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { actionPeopleUpdateRequest } from "../../../../redux-modules/actions/actionsPeople";
/* import { useDispatch } from "react-redux";
import { actionPeopleUpdateRequest } from "../../../../redux-modules/actions/actionsPeople"; */
import { TPeople } from "../../../../redux-modules/declarations/maps/people";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import { Colors } from "../../../../theme/maps/Colors";
import FormEditPeople from "../../Forms/People/editPeople";

interface Props {
  person: TPeople | null;
}

const PeopleInfoPanel = ({ person }: Props): JSX.Element => {
  const l = useLanguage();
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const handleEditing = (): void => setIsEditing(!isEditing);
  const handleFinish = (form): void => {
    const newForm = { ...Object.fromEntries(Object.entries(form).filter(([_, v]) => v != null)) };
    dispatch(
      //@ts-ignore
      actionPeopleUpdateRequest({
        ...person,
        ...newForm,
      }),
    );
    handleEditing();
  };

  if (!!!person) return <></>;
  if (!!isEditing)
    return (
      <FormEditPeople people={person} onFinishCallback={handleFinish} onCloseForm={handleEditing} />
    );
  return (
    <>
      <UIRow style={{ marginTop: "10px", marginBottom: "30px" }}>
        <UICol xs={24} md={12}>
          <UIRow>
            <UICol xs={24}>
              <UITitle level={4}>Personal Info</UITitle>
            </UICol>
          </UIRow>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol style={{ display: "inline-flex" }}>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("name")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph style={{ display: "inline" }} editable={{ onStart: handleEditing }}>
                  {person.name}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol style={{ display: "inline-flex" }}>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("surname")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph style={{ display: "inline" }} editable={{ onStart: handleEditing }}>
                  {person.surname}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol style={{ display: "inline-flex" }}>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("email")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph style={{ display: "inline" }} editable={{ onStart: handleEditing }}>
                  {person.email}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol style={{ display: "inline-flex" }}>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("mobile")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph style={{ display: "inline" }} editable={{ onStart: handleEditing }}>
                  {person.details?.find(el => el.key === "mobile")?.value || ""}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
          <Divider />
          <UIRow>
            <UICol xs={24}>
              <UITitle level={4}>Account Informations</UITitle>
            </UICol>
          </UIRow>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol style={{ display: "inline-flex" }}>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("accountType")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph style={{ display: "inline" }} editable={{ onStart: handleEditing }}>
                  {person.account_type}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol style={{ display: "inline-flex" }}>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("nickname")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph style={{ display: "inline" }} editable={{ onStart: handleEditing }}>
                  {person.details?.find(el => el.key === "nickname")?.value || ""}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol style={{ display: "inline-flex" }}>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("role")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph style={{ display: "inline" }} editable={{ onStart: handleEditing }}>
                  {person.role}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>

          {!!person.partner && (
            <UIRow style={{ marginTop: "8px" }}>
              <UICol style={{ display: "inline-flex" }}>
                <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                  {l("partner")}:
                </UIText>{" "}
                <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                  <Paragraph style={{ display: "inline" }} editable={{ onStart: handleEditing }}>
                    {person.partner.name}
                  </Paragraph>
                </UIText>
              </UICol>
            </UIRow>
          )}
        </UICol>
        <UICol xs={24} md={12}>
          <UIRow>
            <UICol xs={24}>
              <UITitle level={4}>Job Informations</UITitle>
            </UICol>
          </UIRow>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol style={{ display: "inline-flex" }}>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("branch")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph style={{ display: "inline" }} editable={{ onStart: handleEditing }}>
                  {person.branch?.name || ""}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol style={{ display: "inline-flex" }}>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("brands")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph style={{ display: "inline" }} editable={{ onStart: handleEditing }}>
                  {Object.values(person.brands.map(e => e.name)).toString()}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol style={{ display: "inline-flex" }}>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("jobTitle")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph style={{ display: "inline" }} editable={{ onStart: handleEditing }}>
                  {person.job_title || ""}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
        </UICol>
      </UIRow>
    </>
  );
};

export default PeopleInfoPanel;
