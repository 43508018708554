/** @format */

import {
  TActionCustomerListFailure,
  TActionCustomerListRequest,
  TActionCustomerListSuccess,
  TActionCustomerUpdateFailure,
  TActionCustomerUpdateRequest,
  TActionCustomerUpdateSuccess,
} from "../declarations/actions/customers";
import { TActionType } from "../declarations/actionTypes";
import { TCustomerList, TCustomerUpdateRequest } from "../declarations/maps/customer";

export const actionCustomerListRequest = (): TActionCustomerListRequest => ({
  type: TActionType.customerListRequest,
});

export const actionCustomerListSuccess = (payload: TCustomerList): TActionCustomerListSuccess => ({
  type: TActionType.customerListSuccess,
  payload,
});

export const actionCustomerListFailure = (payload: string): TActionCustomerListFailure => ({
  type: TActionType.customerListFailure,
  reason: payload,
});

// Update
export const actionCustomerUpdateRequest = (
  payload: TCustomerUpdateRequest,
): TActionCustomerUpdateRequest => ({
  type: TActionType.customerUpdateRequest,
  payload,
});

export const actionCustomerUpdateSuccess = (): TActionCustomerUpdateSuccess => ({
  type: TActionType.customerUpdateSuccess,
});

export const actionCustomerUpdateFailure = (payload: string): TActionCustomerUpdateFailure => ({
  type: TActionType.customerUpdateFailure,
  reason: payload,
});
