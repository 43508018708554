/** @format */

import { ColumnsType } from "antd/lib/table";

const branchListColumns: ColumnsType<object> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
];

export default branchListColumns;
