/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import { sagaCustomerList, sagaCustomerUpdate } from "../sagas/sagasCustomer";

export function* watcherCustomer(): unknown {
  yield takeLatest(TActionType.customerListRequest, sagaCustomerList);
  yield takeLatest(TActionType.customerUpdateRequest, sagaCustomerUpdate);
}
