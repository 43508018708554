/** @format */

import { useDispatch, useSelector } from "react-redux";
import Labels from "../../utils/locale";
import { actionLanguageSet } from "../actions/actionsUI";
import { selectorLanguage } from "../selectors/selectorsUI";

type Return = (key: string) => string;

export const useLanguage = (): Return => {
  const dispatch = useDispatch();
  const selectedLang = useSelector(selectorLanguage);

  if (!!!selectedLang) dispatch(actionLanguageSet("en"));

  if (selectedLang) return (key: string): string => Labels[selectedLang][key];
  return (key: string): string => key;
};
