/** @format */

import React, { FC, useEffect, useState } from "react";
import { UIRow, UICol, UIButton } from "@rstore-react-packages/template-ui";
import { useDispatch, useSelector } from "react-redux";

import { Input } from "antd";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import styled from "styled-components";
import { storeListColumns } from "../../../../constants/tables/storeListColumns";
import { selectorStoreListForTables } from "../../../../redux-modules/selectors/selectorsStore";
import { TStore } from "../../../../redux-modules/declarations/store";
import { actionPeopleImportRequest } from "../../../../redux-modules/actions/actionsPeople";
import { TTStoreForTable } from "../../../../redux-modules/declarations/maps/store";
import ModalImportStore from "../../Modals/Store/importStore";
import {
  selectorImportPeopleLoading,
  selectorStoreListLoading,
} from "../../../../redux-modules/selectors/selectorsUI";
import MenuStoreTableActions from "../../Menu/TableActions/storeTableActions";
import FormAddStore from "../../Forms/Store/addStore";
import StyledTable from "../../../ui/Table";

interface Props {
  filteredByBrand?: number | false;
}

const LayoutStoreTable: FC<Props> = ({ filteredByBrand = false }: Props): JSX.Element => {
  const l = useLanguage();
  const dispatch = useDispatch();
  const [searchingVal, setSearchingVal] = useState("");
  const [selectedRows, setSelectedRow] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isTableLoading = useSelector(selectorStoreListLoading);
  const [addingStore, setAddingStore] = useState(false);
  const [filteredTable, setFilteredTable] = useState<TTStoreForTable[] | null>(null);
  const formattedStoreList = useSelector((store: TStore) =>
    selectorStoreListForTables(store, filteredByBrand),
  );

  useEffect(() => {
    //console.log(formattedStoreList.map(e => e.id));
    if (
      !!filteredTable &&
      JSON.stringify(filteredTable) !==
        JSON.stringify(
          formattedStoreList.filter(o =>
            Object.keys(o).some(k =>
              String(o[k]).toLowerCase().includes(searchingVal.toLowerCase()),
            ),
          ),
        )
    ) {
      handleSearch(searchingVal, formattedStoreList);
    }
  }, [formattedStoreList]);

  const toggleAddingStore = (): void => setAddingStore(!addingStore);

  const importPeopleLoading = useSelector(selectorImportPeopleLoading);

  //@ts-ignore
  const onSelectRow = (selectedRowKeys: React.Key[], selectedRows: DataType[]): void => {
    //@ts-ignore
    setSelectedRow(selectedRows);
    //@ts-ignore
    setSelectedRowKeys(selectedRowKeys);
  };

  const unselectRow = (): void => {
    setSelectedRow([]);
    setSelectedRowKeys([]);
  };

  const handleSearch = (value: string, list: TTStoreForTable[] | false = false): void => {
    const filterTable = list
      ? list.filter(o =>
          Object.keys(o).some(k => String(o[k]).toLowerCase().includes(value.toLowerCase())),
        )
      : formattedStoreList.filter(o =>
          Object.keys(o).some(k => String(o[k]).toLowerCase().includes(value.toLowerCase())),
        );
    setFilteredTable(filterTable);
  };

  const handleModalOk = (file, name): void => {
    setIsModalVisible(false);

    //dispatch some
    !!file &&
      filteredByBrand &&
      dispatch(
        actionPeopleImportRequest({
          csv: file,
          filename: name,
          type: "stores",
          importable_id: filteredByBrand,
        }),
      );
  };
  const handleModalCancel = (): void => {
    setIsModalVisible(false);
  };

  return (
    <>
      {isModalVisible && (
        <ModalImportStore
          visible={isModalVisible}
          handleOk={handleModalOk}
          handleCancel={handleModalCancel}
        />
      )}
      <UIRow justify="space-between" gutter={[16, 16]}>
        <UICol>
          <UIButton
            loading={importPeopleLoading}
            onClick={(): void => setIsModalVisible(true)}
            style={{ color: "#000" }}
          >
            {l("import")}
          </UIButton>
          {/*           <UIButton
            onClick={toggleAddingStore}
            style={{
              color: addingStore ? "#FFF" : "#000",
              backgroundColor: addingStore ? "#931c22" : "white",
              marginLeft: "10px",
            }}
          >
            {addingStore ? l("dismiss") : l("addStore")}
          </UIButton> */}
          {!!selectedRows.length && (
            <MenuStoreTableActions
              idList={
                //@ts-ignore
                selectedRows.map(e => e.id)
              }
              unselectAll={unselectRow}
            />
          )}
        </UICol>
      </UIRow>
      <UIRow>
        <UICol xs={24}>
          <>
            {addingStore && filteredByBrand && (
              <FormAddStore brandId={filteredByBrand} onCreateCallback={toggleAddingStore} />
            )}
          </>
        </UICol>
      </UIRow>
      <UIRow justify="end" gutter={[16, 16]}>
        <UICol>
          <StyledUISearchInput
            placeholder={l("searchPlaceholder")}
            onChange={(e): void => setSearchingVal(e.target.value)}
            onSearch={(e): void => handleSearch(e)}
          />
        </UICol>
      </UIRow>
      <UIRow style={{ marginTop: "10px" }} gutter={[16, 16]}>
        <UICol span={24}>
          <StyledTable
            size="middle"
            loading={isTableLoading}
            rowSelection={{ type: "checkbox", onChange: onSelectRow, selectedRowKeys }}
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            rowKey={r => {
              return `${r.id}`;
            }}
            columns={storeListColumns}
            dataSource={filteredTable || formattedStoreList}
            pagination={{ showSizeChanger: true }}
          />
        </UICol>
      </UIRow>
    </>
  );
};
const StyledUISearchInput = styled(Input.Search)`
  .anticon {
    color: #000 !important;
  }
`;

export default LayoutStoreTable;
