/** @format */

import {
  TActionGroupListSuccess,
  TActionGroupListReset,
  TActionGroupDeleteSuccess,
} from "../declarations/actions/groups";
import { TActionType } from "../declarations/actionTypes";
import { TGroupState } from "../declarations/maps/group";

type Actions = TActionGroupListSuccess | TActionGroupListReset | TActionGroupDeleteSuccess;

const defaultState = {
  list: {
    1: {
      id: 1,
      customer_id: 1,
      name: "Group Test",
      contractor_name: "Contractor Name",
      contractor_phone: "3516100029",
      contractor_email: "contractor@email.it",
      note: "XXX xxx yyy",
      tipology: "X",
      licenses: 100,
      reports: 100,
      people: 1,
      plan_starting_date: "DD/MM/YYYY",
      plan_ending_date: "DD/MM/YYYY",
      administrators: [
        {
          id: 1,
          person_id: 2,
          person: {
            id: 2,
            name: "Comb",
            surname: "User 1",
            email: "test@test.it",
            mobile: "1234",
            nickname: "tester",
            job_title: "tester",
            brands: [
              {
                id: 1,
                name: "Emporio Armani",
              },
              {
                id: 2,
                name: "Giorgio Armani",
              },
              {
                id: 3,
                name: "Armani Exchange",
              },
            ],
            branch: {
              id: 1,
              name: "GA Head Quarter",
              customer_id: 1,
              canDelete: false,
            },
            role: "Super Admin",
            account_type_id: 1,
            account_type: "Premium",
            status: "Active",
            plan_start_date: "10/05/2021",
            plan_end_date: "31/12/2021",
            reports: 500,
            groups: [
              {
                id: 2,
                person_id: 2,
                group_id: 3,
                license_id: 4,
                people: 1,
                start_date: "2021-01-08T00:00:00.000000Z",
                end_date: "2021-02-15T00:00:00.000000Z",
                account_type_id: 1,
                report_usage_limit: "unlimited",
                deleted_at: null,
                created_at: "2021-05-03T13:09:01.000000Z",
                updated_at: "2021-05-03T13:09:01.000000Z",
              },
            ],
          },
        },
      ],
    },
  },
};

export const reducerGroup = (state: TGroupState = defaultState, action: Actions): TGroupState => {
  switch (action.type) {
    case TActionType.groupListSuccess:
      return { list: { ...action.payload } };
    case TActionType.groupListReset:
      return defaultState;
    case TActionType.groupDeleteSuccess:
      const { [action.payload]: _, ...newState } = state.list;
      return { list: newState };
    default:
      return state;
  }
};
