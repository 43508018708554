/** @format */

import { TRole, TRoleForSelect } from "../declarations/maps/role";
import { TStore } from "../declarations/store";

export const selectorRoleList = (store: TStore): TRole[] => Object.values(store.role.list) || [];

export const selectorRole = (store: TStore, roleId: string): TRole | null =>
  store.role.list[roleId] || null;

export const selectorRoleListForTables = (store: TStore): TRoleForSelect[] => {
  const roleList = Object.values(store.role.list);
  if (!!!roleList) return [];

  return roleList.map(el => {
    const newEl = (({ ...o }): TRoleForSelect => ({
      ...o,
      value: o.id,
      description: o.name,
    }))(el);

    return newEl;
  });
};
