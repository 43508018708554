/** @format */

import { AxiosError, AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../../constants/api";
import { TBranchUpdateRequest } from "../../redux-modules/declarations/maps/branch";
import apiClient from "../../utils/apiClient";

export const apiBranchUpdate = (
  data: TBranchUpdateRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .put(API_ENDPOINTS.BRANCH_UPDATE.replace(":id", `${data.id}`), data)
    .then(response => ({ response }))
    .catch(error => ({ error }));
