/** @format */

import {
  UITransition,
  UIRow,
  UICol,
  UICard,
  UIInput,
  UITitle,
  UIButton,
} from "@rstore-react-packages/template-ui";
import { Form, Select } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionPeopleCreateRequest } from "../../../../redux-modules/actions/actionsPeople";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import { selectorIsAdmin } from "../../../../redux-modules/selectors/selectorsAuth";
import { selectorBranchListForSelect } from "../../../../redux-modules/selectors/selectorsBranch";
import { selectorBrandListForSelect } from "../../../../redux-modules/selectors/selectorsBrand";
import { selectorPartnerList } from "../../../../redux-modules/selectors/selectorsPartner";
import { selectorRoleList } from "../../../../redux-modules/selectors/selectorsRole";
import { utilityGenerateNickname } from "../../../../utils/format/generateNickname";
import { labelWithRequired } from "../../../../utils/format/labelWithRequired";
const { Option } = Select;

interface Props {
  groupId: number;
  onCreateCallback?: () => void;
}
const FormAddPeople: FC<Props> = ({ groupId, onCreateCallback }: Props): JSX.Element => {
  const l = useLanguage();
  const dispatch = useDispatch();
  const isAdmin = useSelector(selectorIsAdmin);
  const roles = useSelector(selectorRoleList);
  const branches = useSelector(selectorBranchListForSelect);
  const brands = useSelector(selectorBrandListForSelect);
  const partners = useSelector(selectorPartnerList);
  const [needsPartner, setNeedsPartner] = useState(false);
  const [generatedNickname, setGeneratedNickname] = useState("");
  const [form] = Form.useForm();

  const handleFinish = (form): void => {
    form.group_id = groupId;

    dispatch(
      actionPeopleCreateRequest(
        //@ts-ignore
        Object.fromEntries(Object.entries(form).filter(([_, v]) => v != null)),
      ),
    );
    if (onCreateCallback) onCreateCallback();
  };

  const generateNickname = (): void => {
    setGeneratedNickname(
      utilityGenerateNickname(form.getFieldValue("name"), form.getFieldValue("surname")),
    );
  };

  useEffect(() => {
    form.setFieldsValue({ nickname: generatedNickname });
  }, [generatedNickname]);

  useEffect(() => {
    if (!!needsPartner) {
      form.setFieldsValue({ branch_id: null });
    }
  }, [needsPartner]);
  return (
    <UITransition>
      <UIRow style={{ marginTop: "20px", marginBottom: "20px" }}>
        <UICol xs={24}>
          <UICard>
            <Form
              name="add-people"
              layout="vertical"
              form={form}
              onFinish={handleFinish}
              requiredMark={false}
            >
              <UIRow>
                <UICol xs={24} md={8}>
                  <UIRow>
                    <UICol xs={24}>
                      <UITitle level={4}>Personal Info</UITitle>
                    </UICol>
                  </UIRow>
                  <UIRow>
                    <UICol xs={24}>
                      <Form.Item
                        name="name"
                        label={labelWithRequired(l("name"))}
                        rules={[
                          {
                            required: true,
                            message: "Please insert a name!",
                          },
                        ]}
                      >
                        <UIInput onChange={generateNickname} />
                      </Form.Item>
                    </UICol>
                  </UIRow>
                  <UIRow>
                    <UICol xs={24}>
                      <Form.Item
                        name="surname"
                        label={labelWithRequired(l("surname"))}
                        rules={[
                          {
                            required: true,
                            message: "Please insert a surname!",
                          },
                        ]}
                      >
                        <UIInput onChange={generateNickname} />
                      </Form.Item>
                    </UICol>
                  </UIRow>
                  <UIRow>
                    <UICol xs={24}>
                      <Form.Item
                        name="email"
                        label={labelWithRequired(l("email"))}
                        rules={[
                          {
                            required: true,
                            message: "Please insert an email!",
                          },
                        ]}
                      >
                        <UIInput />
                      </Form.Item>
                    </UICol>
                  </UIRow>
                  <UIRow>
                    <UICol xs={24}>
                      <Form.Item
                        name="mobile"
                        label={l("mobile")}
                        rules={[
                          {
                            required: false,
                            message: "Please insert a mobile!",
                          },
                        ]}
                      >
                        <UIInput />
                      </Form.Item>
                    </UICol>
                  </UIRow>
                  <UIRow>
                    <UICol xs={24}>
                      <UITitle level={4}>Account Informations</UITitle>
                    </UICol>
                  </UIRow>
                  <UIRow>
                    <UICol xs={24}>
                      <Form.Item
                        name="role_id"
                        label={labelWithRequired(l("role"))}
                        rules={[
                          {
                            required: true,
                            message: "Please select a role!",
                          },
                        ]}
                      >
                        <Select
                          onChange={(e): void => {
                            if (e === 9) setNeedsPartner(true);
                            else setNeedsPartner(false);
                          }}
                        >
                          {!!isAdmin
                            ? roles.map(role => (
                                <Option key={role.id} value={role.id}>
                                  {role.name}
                                </Option>
                              ))
                            : roles
                                .filter(e => e.id !== 1 && e.id !== 2 && e.id !== 3)
                                .map(role => (
                                  <Option key={role.id} value={role.id}>
                                    {role.name}
                                  </Option>
                                ))}
                        </Select>
                      </Form.Item>
                    </UICol>
                  </UIRow>
                  {!!needsPartner && (
                    <UITransition>
                      <UIRow>
                        <UICol xs={24}>
                          <Form.Item
                            name="partner_id"
                            label={labelWithRequired(l("partner"))}
                            rules={[
                              {
                                required: true,
                                message: "Please select a partner!",
                              },
                            ]}
                          >
                            <Select>
                              {partners
                                .map(el => ({
                                  ...el,
                                  key: el.id,
                                  name: el.name.toUpperCase(),
                                }))
                                .sort(function (a, b) {
                                  if (a.name < b.name) {
                                    return -1;
                                  }
                                  if (a.name > b.name) {
                                    return 1;
                                  }
                                  return 0;
                                })
                                .map(part => (
                                  <Option key={part.id} value={part.id}>
                                    {part.name}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </UICol>
                      </UIRow>
                    </UITransition>
                  )}
                  <UIRow>
                    <UICol xs={24}>
                      <Form.Item name="nickname" label={l("nickname")}>
                        <UIInput value={generatedNickname} />
                      </Form.Item>
                    </UICol>
                  </UIRow>
                </UICol>
                <UICol xs={24} md={8} offset={1}>
                  <UIRow>
                    <UICol xs={24}>
                      <UITitle level={4}>Job Informations</UITitle>
                    </UICol>
                  </UIRow>
                  <UIRow>
                    <UICol xs={24}>
                      <Form.Item
                        name="branch_id"
                        label={!!!needsPartner ? labelWithRequired(l("branch")) : l("branch")}
                        rules={[
                          {
                            required: !!!needsPartner,
                            message: "Please select at least one branch!",
                          },
                        ]}
                      >
                        <Select
                          optionFilterProp="label"
                          disabled={!!needsPartner}
                          showSearch
                          filterOption={(input, option): boolean =>
                            //@ts-ignore
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {branches.map(branch => (
                            <Option value={branch.value} key={branch.key}>
                              {branch.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </UICol>
                  </UIRow>
                  <UIRow>
                    <UICol xs={24}>
                      <Form.Item
                        name="brands_ids"
                        label={labelWithRequired(l("brands"))}
                        rules={[
                          {
                            required: true,
                            message: "Please select at least one brand!",
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          optionFilterProp="label"
                          showSearch
                          filterOption={(input, option): boolean =>
                            //@ts-ignore
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {brands.map(brand => (
                            <Option value={brand.value} key={brand.key}>
                              {brand.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </UICol>
                  </UIRow>
                  <UIRow>
                    <UICol xs={24}>
                      <Form.Item name="job_title" label={l("jobTitle")}>
                        <UIInput />
                      </Form.Item>
                    </UICol>
                  </UIRow>
                </UICol>
              </UIRow>
              <UIRow>
                <UICol xs={24} md={8}>
                  <Form.Item>
                    <UIButton htmlType="submit" style={{ color: "#000" }}>
                      {l("save")}
                    </UIButton>
                  </Form.Item>
                </UICol>
              </UIRow>
            </Form>
          </UICard>
        </UICol>
      </UIRow>
    </UITransition>
  );
};

export default FormAddPeople;
