/** @format */

import { UIButton, UIText } from "@rstore-react-packages/template-ui";
import { Dropdown, Menu } from "antd";
import React, { useState } from "react";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import ModalChandeStoreBranch from "../../Modals/Store/changeBranch";
import ModalChandeStoreBrand from "../../Modals/Store/changeBrand";
import ModalChangeStorePartner from "../../Modals/Store/changePartner";
import ModalChandeStoreStatus from "../../Modals/Store/changeStatus";
import ModalChangeStoreSwitchStatus from "../../Modals/Store/switchStatus";

interface Props {
  idList: number[];
  unselectAll: () => void;
}

const MenuStoreTableActions = ({ idList, unselectAll }: Props): JSX.Element => {
  const l = useLanguage();
  const [branchModalVisible, setBranchModalVisible] = useState(false);
  const [brandModalVisible, setBrandModalVisible] = useState(false);
  const [statusModalVisible, setStatusModalVisible] = useState(false);
  const [switchStatusModalVisible, setSwitchStatusModalVisible] = useState(false);
  const [partnerModalVisible, setPartnerModalVisible] = useState(false);

  const menu = (idList: number[]): JSX.Element => {
    return (
      <Menu>
        <Menu.Item onClick={(): void => setBrandModalVisible(true)}>
          <UIText>{l("editBrand")}</UIText>
        </Menu.Item>
        <Menu.Item onClick={(): void => setBranchModalVisible(true)}>
          <UIText>{l("editBranch")}</UIText>
        </Menu.Item>
        <Menu.Item onClick={(): void => setPartnerModalVisible(true)}>
          <UIText>{l("editPartner")}</UIText>
        </Menu.Item>
        <Menu.Item onClick={(): void => setSwitchStatusModalVisible(true)}>
          <UIText>{l("editStatus")}</UIText>
        </Menu.Item>
        <Menu.Item onClick={(): void => setStatusModalVisible(true)}>
          <UIText>{l("delete")}</UIText>
        </Menu.Item>
      </Menu>
    );
  };
  return (
    <>
      <Dropdown overlay={(): JSX.Element => menu(idList)} placement="bottomLeft">
        <UIButton style={{ color: "#000", marginLeft: "10px" }}>Actions</UIButton>
      </Dropdown>
      {branchModalVisible && (
        <ModalChandeStoreBranch
          visible={branchModalVisible}
          store={idList}
          handleOk={(): void => {
            setBranchModalVisible(false);
            unselectAll();
          }}
          handleCancel={(): void => setBranchModalVisible(false)}
        />
      )}
      {brandModalVisible && (
        <ModalChandeStoreBrand
          visible={brandModalVisible}
          store={idList}
          handleOk={(): void => {
            setBrandModalVisible(false);
            unselectAll();
          }}
          handleCancel={(): void => setBrandModalVisible(false)}
        />
      )}
      {statusModalVisible && (
        <ModalChandeStoreStatus
          visible={statusModalVisible}
          store={idList}
          handleOk={(): void => {
            setStatusModalVisible(false);
            unselectAll();
          }}
          handleCancel={(): void => setStatusModalVisible(false)}
        />
      )}
      {switchStatusModalVisible && (
        <ModalChangeStoreSwitchStatus
          visible={switchStatusModalVisible}
          store={idList}
          handleOk={(): void => {
            setSwitchStatusModalVisible(false);
            unselectAll();
          }}
          handleCancel={(): void => setSwitchStatusModalVisible(false)}
        />
      )}
      {partnerModalVisible && (
        <ModalChangeStorePartner
          visible={partnerModalVisible}
          store={idList}
          handleOk={(): void => {
            setPartnerModalVisible(false);
            unselectAll();
          }}
          handleCancel={(): void => setPartnerModalVisible(false)}
        />
      )}
    </>
  );
};
export default MenuStoreTableActions;
