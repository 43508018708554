/** @format */

import React from "react";
import { ColumnsType } from "antd/lib/table";
import { NavLink } from "react-router-dom";
import { Routes } from "../../utils/Routes";

const groupListColumns: ColumnsType<object> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    // eslint-disable-next-line react/display-name
    render: (e, r, i): JSX.Element => <LinkTo text={e} row={r} index={i} />,
  },
  {
    title: "Licenses",
    dataIndex: "count_licenses",
    key: "count_licenses",
  },
  {
    title: "Reports",
    dataIndex: "reports",
    key: "reports",
  },
  {
    title: "People",
    dataIndex: "people",
    key: "people",
  },
  {
    title: "Plan Starting",
    dataIndex: "plan_starting_date",
    key: "plan_starting_date",
  },
  {
    title: "Plan Ending",
    dataIndex: "plan_ending_date",
    key: "plan_ending_date",
  },
];

//@ts-ignore
const LinkTo = ({ text, row, index }): JSX.Element => {
  return (
    <NavLink key={row.id} to={Routes.group.replace(":id", row.id)} style={{ color: "#0e7cdc" }}>
      {row.name}
    </NavLink>
  );
};

export default groupListColumns;
