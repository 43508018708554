/** @format */

import React from "react";
import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import styled from "styled-components";
import { UserOutlined, DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { TActionLogoutRequest } from "../../../../redux-modules/declarations/actions/auth";
import { actionLogoutRequest } from "../../../../redux-modules/actions/actionsAuth";
import { selectorLoggedUser } from "../../../../redux-modules/selectors/selectorsAuth";

const CustomMenu = styled(Menu)`
  float: right;
`;

const LayoutUserMenu = (): JSX.Element => {
  const dispatch = useDispatch();
  const handleLogout = (): TActionLogoutRequest => dispatch(actionLogoutRequest());
  const user = useSelector(selectorLoggedUser);

  return (
    <CustomMenu theme="dark" mode="horizontal">
      <SubMenu
        key="user"
        title={
          <>
            <UserOutlined />
            {user?.name}
            <DownOutlined style={{ marginLeft: "5px", fontSize: 12 }} />
          </>
        }
      >
        <Menu.Item onClick={handleLogout}>
          <div>Log Out</div>
        </Menu.Item>
      </SubMenu>
    </CustomMenu>
  );
};

export default LayoutUserMenu;
