/** @format */

import { UIRow, UICol, UIInput } from "@rstore-react-packages/template-ui";
import { Button, ConfigProvider, Form, Select } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionLicenseUpdateRequest } from "../../../../redux-modules/actions/actionsLicense";
import { TStore } from "../../../../redux-modules/declarations/store";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import { selectorAccountTypeListForTables } from "../../../../redux-modules/selectors/selectorsAccountType";
import { selectorLicense } from "../../../../redux-modules/selectors/selectorsLicense";

import en_GB from "antd/lib/locale-provider/en_GB";
import moment from "moment";
import "moment/locale/en-gb";
import StyledDatePicker from "../../../ui/DatePicker";
import StyledModal from "../../../ui/Modal";
moment.locale("en-gb", { week: { dow: 1, doy: 4 } });
const { Option } = Select;

interface Props {
  visible: boolean;
  licenseRow: unknown;
  closeModal: () => void;
}

const EditLicenseModal: FC<Props> = ({ visible, licenseRow, closeModal }): JSX.Element => {
  const dispatch = useDispatch();
  const l = useLanguage();
  const [form] = Form.useForm();
  const accountTypes = useSelector(selectorAccountTypeListForTables);

  const [isUnlimited, setIsUnlimited] = useState(false);

  //@ts-ignore
  const license = useSelector((store: TStore) => selectorLicense(store, licenseRow.id));
  useEffect(() => {
    if (license?.report_usage_limit === "unlimited") setIsUnlimited(true);
  }, [license]);

  const handleFinish = (formVal): void => {
    dispatch(
      actionLicenseUpdateRequest({
        ...formVal,
        id: license?.id,
        group_id: license?.group_id,
        end_date: moment(formVal.end_date).format("DD-MM-YYYY"),
        start_date: moment(formVal.start_date).format("DD-MM-YYYY"),
        report_usage_limit: !!isUnlimited ? "unlimited" : formVal.report_usage_limit,
      }),
    );
    closeModal();
  };
  return (
    <StyledModal
      title={l("editAccountType")}
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Return
        </Button>,
        <Button key="submit" onClick={(): void => form.submit()}>
          Submit
        </Button>,
      ]}
    >
      <UIRow>
        <UICol xs={24}>
          <Form
            form={form}
            name="edit-license"
            layout="horizontal"
            onFinish={handleFinish}
            initialValues={license || undefined}
          >
            <UIRow>
              <UICol xs={24}>
                <Form.Item name="account_type_id" label={"Account Type"}>
                  <Select>
                    {accountTypes.map(accType => (
                      <Option value={accType.value} key={accType.value}>
                        {accType.description}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </UICol>
            </UIRow>
            <ConfigProvider locale={en_GB}>
              <UIRow>
                <UICol xs={12}>
                  <Form.Item name="start_date" label={"Start Plan Date:"}>
                    <StyledDatePicker format={"DD/MM/YYYY"} />
                  </Form.Item>
                </UICol>
                <UICol xs={11} offset={1}>
                  <Form.Item name="end_date" label={"End Plan Date:"}>
                    <StyledDatePicker format={"DD/MM/YYYY"} />
                  </Form.Item>
                </UICol>
              </UIRow>
            </ConfigProvider>
            <UIRow>
              <UICol xs={12}>
                <Form.Item name="report_usage_limit" label={"Report Limit:"}>
                  <UIInput
                    type="number"
                    disabled={!!isUnlimited}
                    addonAfter={
                      <div
                        onClick={(): void => setIsUnlimited(!isUnlimited)}
                        style={{
                          cursor: "pointer",
                          textShadow: !!isUnlimited
                            ? "0 0 5px #017be4, 0 0 10px #017be4, 0 0 10px #017be4, 0 0 15px #017be4"
                            : "none",
                        }}
                      >
                        ∞
                      </div>
                    }
                  />
                </Form.Item>
              </UICol>
              <UICol xs={11} offset={1}>
                <Form.Item name="people_usage_limit" label={"People Limit:"}>
                  <UIInput type="number" />
                </Form.Item>
              </UICol>
            </UIRow>
          </Form>
        </UICol>
      </UIRow>
    </StyledModal>
  );
};
export default EditLicenseModal;
