/** @format */

import { UITransition, UIRow, UICol, UITitle } from "@rstore-react-packages/template-ui";
import { Breadcrumb } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { TStore } from "../../../redux-modules/declarations/store";
import { useGenericAlert } from "../../../redux-modules/hooks/useGenericAlert";
import { selectorGroup } from "../../../redux-modules/selectors/selectorsGroup";
import { selectorStore } from "../../../redux-modules/selectors/selectorsStore";
import { selectorCustomerSelected } from "../../../redux-modules/selectors/selectorsUI";
import { Routes } from "../../../utils/Routes";
import StoreInfoPanel from "../../layout/InfoPanels/StoreInfoPanel";

const ViewStoreDetail = (): JSX.Element => {
  //@ts-ignore
  const { id } = useParams();
  const Alert = useGenericAlert(6000);
  const sstore = useSelector((store: TStore) => selectorStore(store, id));
  const customer = useSelector(selectorCustomerSelected);
  const brand = useSelector((store: TStore) => selectorGroup(store, `${sstore?.brands[0].id}`));
  return (
    <>
      <UITransition>
        {Alert}
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>
            <NavLink to={Routes.home}>Home</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <NavLink to={Routes.customer.replace(":id", `${customer?.id}`)}>
              {customer?.name}
            </NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <NavLink to={Routes.brand.replace(":id", `${brand?.id}`)}>{brand?.name}</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{sstore?.name}</Breadcrumb.Item>
        </Breadcrumb>
        <UIRow style={{ marginTop: "30px" }}>
          <UICol>
            <UITitle>{sstore?.name}</UITitle>
          </UICol>
        </UIRow>
        <StoreInfoPanel store={sstore} />
      </UITransition>
    </>
  );
};
export default ViewStoreDetail;
