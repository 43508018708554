/** @format */

import { UIRow, UICol, UIText } from "@rstore-react-packages/template-ui";
import { Form, Select } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGroupAdminCreateRequest,
  actionGroupAdminDeleteRequest,
  actionGroupUpdateRequest,
} from "../../../../redux-modules/actions/actionsGroup";
import { TGroup } from "../../../../redux-modules/declarations/maps/group";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import { selectorIsAdmin } from "../../../../redux-modules/selectors/selectorsAuth";
import { selectorAdministratorListForSelect } from "../../../../redux-modules/selectors/selectorsPeople";
import { Colors } from "../../../../theme/maps/Colors";
import FormEditGroup from "../../Forms/Group/editGroup";
const { Option } = Select;

interface Props {
  group: TGroup | null;
}

const GroupInfoPanel = ({ group }: Props): JSX.Element => {
  const l = useLanguage();
  const dispatch = useDispatch();
  const isAdmin = useSelector(selectorIsAdmin);
  const [isEditing, setIsEditing] = useState(false);
  const handleEditing = (): void => setIsEditing(!isEditing);
  const handleFinish = (form): void => {
    dispatch(actionGroupUpdateRequest({ ...group, ...form }));
    handleEditing();
  };

  const administrators = useSelector(selectorAdministratorListForSelect);
  //@ts-ignore
  const [selectedAdministrators, setSelectedAdministrators] = useState(group.administrators);

  const handleSelect = (val, opt): void => {
    if (val.length < selectedAdministrators.length) {
      const removed = selectedAdministrators.find(x => !val.includes(x.person_id))?.person_id;
      dispatch(
        actionGroupAdminDeleteRequest({
          //@ts-ignore
          group_id: group.id,
          //@ts-ignore
          person_id: removed,
        }),
      );
    }
    if (val.length > selectedAdministrators.length) {
      dispatch(
        actionGroupAdminCreateRequest({
          //@ts-ignore
          group_id: group.id,
          person_id: val[val.length - 1],
        }),
      );
    }

    //setSelectedAdministrators(val.map(el => ({ person_id: el, group_id: group.id })));
  };

  useEffect(() => {
    //@ts-ignore
    setSelectedAdministrators(group.administrators);
    //@ts-ignore
  }, [group.administrators]);

  if (!!!group) return <></>;
  if (!!isEditing)
    return (
      <FormEditGroup group={group} onFinishCallback={handleFinish} onCloseForm={handleEditing} />
    );
  return (
    <>
      <UIRow style={{ marginTop: "10px", marginBottom: "30px" }}>
        <UICol xs={24} md={12}>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol style={{ display: "inline-flex" }}>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("name")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph style={{ display: "inline" }} editable={!!!isAdmin? false : { onStart: handleEditing }}>
                  {group.name}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("tipology")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph style={{ display: "inline" }} editable={!!!isAdmin? false : { onStart: handleEditing }}>
                  {group.tipology}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("contractorName")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph style={{ display: "inline" }} editable={!!!isAdmin? false : { onStart: handleEditing }}>
                  {group.contractor_name}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("contractorPhone")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph style={{ display: "inline" }} editable={!!!isAdmin? false : { onStart: handleEditing }}>
                  {group.contractor_phone}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("contractorEmail")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph style={{ display: "inline" }} editable={!!!isAdmin? false : { onStart: handleEditing }}>
                  {group.contractor_email}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("note")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph
                  style={{ display: "inline" }}
                  data-cy-edit-group
                  editable={!!!isAdmin? false : { onStart: handleEditing }}
                >
                  {group.note || ""}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
        </UICol>
        <UICol xs={24} md={11} offset={1}>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol xs={24}>
              {isAdmin ? (
                <Form>
                  <Form.Item
                    label={l("administrators")}
                    name="admins"
                    initialValue={group.administrators.map(el => el.person_id)}
                  >
                    <Select
                      mode="multiple"
                      onChange={(val, opt): void => handleSelect(val, opt)}
                      filterOption={(input, option): boolean =>
                        option?.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {administrators.map(el => {
                        return (
                          <Option value={el.value} key={el.key}>
                            {el.name} {el.surname}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Form>
              ) : (
                <>
                  <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                    {l("administrators")}:
                  </UIText>
                  <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                    <Paragraph style={{ display: "inline" }} editable={!!!isAdmin? false : { onStart: handleEditing }}>
                      {`${group.administrators.map(
                        (el, i) =>
                          ` ${el.person.name} ${el.person.surname}${
                            i !== group.administrators.length - 1 ? "" : ""
                          }`,
                      )}`}
                    </Paragraph>
                  </UIText>
                </>
              )}
            </UICol>
          </UIRow>
        </UICol>
      </UIRow>
    </>
  );
};

export default GroupInfoPanel;
