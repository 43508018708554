/** @format */

import { combineReducers } from "redux";
import { reducerAccountType } from "./reducerAccountType";
import { reducerAuth } from "./reducerAuth";
import { reducerBranch } from "./reducerBranch";
import { reducerBrand } from "./reducerBrand";
import { reducerCustomer } from "./reducerCustomer";
import { reducerGroup } from "./reducerGroup";
import { reducerLicense } from "./reducerLicense";
import { reducerTipology } from "./reducerTipology";
import { reducerUI } from "./reducerUI";
import { reducerPeople } from "./reducerPeople";
import { reducerAdministrator } from "./reducerAdministrator";
import { reducerRole } from "./reducerRole";
import { reducerStore } from "./reducerStore";
import { reducerPartner } from "./reducerPartner";

export const reducers = combineReducers({
  ui: reducerUI,
  auth: reducerAuth,
  customer: reducerCustomer,
  group: reducerGroup,
  license: reducerLicense,
  people: reducerPeople,
  tipology: reducerTipology,
  accountType: reducerAccountType,
  brand: reducerBrand,
  branch: reducerBranch,
  administrator: reducerAdministrator,
  role: reducerRole,
  store: reducerStore,
  partner: reducerPartner,
});
