/** @format */

import { TAccountType, TAccountTypeForSelect } from "../declarations/maps/accountType";
import { TStore } from "../declarations/store";

export const selectorAccountTypeList = (store: TStore): TAccountType[] =>
  Object.values(store.accountType.list) || [];

export const selectorAccountType = (store: TStore, accountTypeId: string): TAccountType | null =>
  store.accountType.list[accountTypeId] || null;

export const selectorAccountTypeListForTables = (store: TStore): TAccountTypeForSelect[] => {
  const accountTypeList = Object.values(store.accountType.list);
  if (!!!accountTypeList) return [];

  return accountTypeList.map(el => {
    const newEl = (({ ...o }): TAccountTypeForSelect => ({
      ...o,
      value: o.id,
      description: o.name,
    }))(el);

    return newEl;
  });
};
