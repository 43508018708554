/** @format */

import { AxiosResponse, AxiosError } from "axios";
import { call, put } from "redux-saga/effects";
import { apiStoreList } from "../../api/store/apiStoreList";
import { apiStoreUpdate } from "../../api/store/apiStoreUpdate";
import { apiStoreCreate } from "../../api/store/apiStoreCreate";
import { arrayToObject } from "../../utils/format/arrayToObject";
import {
  actionStoreListSuccess,
  actionStoreListFailure,
  actionStoreUpdateSuccess,
  actionStoreUpdateFailure,
  actionStoreListRequest,
  actionStoreCreateFailure,
  actionStoreCreateSuccess,
} from "../actions/actionsStore";
import {
  actionAlertGenerate,
  actionImportPeopleLoadingStop,
  actionStoreListLoadingStart,
  actionStoreListLoadingStop,
} from "../actions/actionsUI";
import {
  TActionStoreCreateRequest,
  TActionStoreListRequest,
  TActionStoreUpdateRequest,
} from "../declarations/actions/store";
import { TAPIValidationError } from "../declarations/apis/general";

export function* sagaStoreList(action: TActionStoreListRequest): unknown {
  yield put(actionStoreListLoadingStart());
  const {
    response,
    error,
  }: { response?: AxiosResponse; error?: AxiosError<{ errors: TAPIValidationError }> } = yield call(
    apiStoreList,
  );

  if (!error && response) {
    yield put(actionStoreListLoadingStop());
    yield put(actionImportPeopleLoadingStop());
    //@ts-ignore
    yield put(actionStoreListSuccess(arrayToObject(response.data.data, "id")));
  } else {
    yield put(actionStoreListLoadingStop());
    //@ts-ignore
    yield put(actionStoreListFailure((error?.response?.data?.errors as TAPIValidationError) || {}));
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.errors?.toString() || "",
      }),
    );
  }
}

export function* sagaStoreCreate(action: TActionStoreCreateRequest): unknown {
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiStoreCreate, action.payload);

  if (!error && response) {
    yield put(actionStoreCreateSuccess());
    yield put(actionStoreListRequest());
  } else {
    yield put(
      //@ts-ignore
      actionStoreCreateFailure((error?.response?.data?.message as TAPIValidationError) || {}),
    );

    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}

export function* sagaStoreUpdate(action: TActionStoreUpdateRequest): unknown {
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiStoreUpdate, action.payload);

  if (!error && response) {
    yield put(actionStoreUpdateSuccess());
    yield put(actionStoreListRequest());
  } else {
    yield put(
      //@ts-ignore
      actionStoreUpdateFailure((error?.response?.data?.message as TAPIValidationError) || {}),
    );

    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}
