/** @format */

import { Modal } from "antd";
import styled from "styled-components";

const StyledModal = styled(Modal)`
  .ant-btn {
    color: #000;
  }
  .ant-btn:disabled {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background-color: white;
  }
`;

export default StyledModal;
