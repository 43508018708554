/** @format */

import it from "./langs/it.json";
import en from "./langs/en.json";

const Labels = {
  it: it,
  en: en,
};

export default Labels;
