/** @format */

import { UITransition, UIRow, UICol, UITitle } from "@rstore-react-packages/template-ui";
import { Breadcrumb } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { TStore } from "../../../redux-modules/declarations/store";
import { useGenericAlert } from "../../../redux-modules/hooks/useGenericAlert";
import { selectorGroup } from "../../../redux-modules/selectors/selectorsGroup";
import { selectorPeople } from "../../../redux-modules/selectors/selectorsPeople";
import { selectorCustomerSelected } from "../../../redux-modules/selectors/selectorsUI";
import { Routes } from "../../../utils/Routes";
import PeopleInfoPanel from "../../layout/InfoPanels/PeopleInfoPanel";

const ViewPeopleDetail = (): JSX.Element => {
  //@ts-ignore
  const { id } = useParams();
  const Alert = useGenericAlert(6000);
  const person = useSelector((store: TStore) => selectorPeople(store, id));
  const customer = useSelector(selectorCustomerSelected);
  const group = useSelector((store: TStore) =>
    selectorGroup(store, `${person?.groups[0].group_id}`),
  );
  console.log(person);
  return (
    <>
      <UITransition>
        {Alert}
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>
            <NavLink to={Routes.home}>Home</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <NavLink to={Routes.customer.replace(":id", `${customer?.id}`)}>
              {customer?.name}
            </NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <NavLink to={Routes.group.replace(":id", `${group?.id}`)}>{group?.name}</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{person?.name + " " + person?.surname}</Breadcrumb.Item>
        </Breadcrumb>
        <UIRow style={{ marginTop: "30px" }}>
          <UICol>
            <UITitle>{person?.name + " " + person?.surname}</UITitle>
          </UICol>
        </UIRow>
        <PeopleInfoPanel person={person} />
      </UITransition>
    </>
  );
};
export default ViewPeopleDetail;
