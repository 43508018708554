/** @format */

import { Row, Col } from "antd";
import React, { useState } from "react";
import { SettingOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import EditLicenseModal from "../../components/layout/Modals/License/editLicense";
import ModalDeleteLicense from "../../components/layout/Modals/License/deleteLicense";

export const licenseListColumns: ColumnsType<object> = [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Account Type",
    dataIndex: "account_type",
    key: "account_type",
  },
  {
    title: "Plan Starting",
    dataIndex: "start_date",
    key: "start_date",
  },
  {
    title: "Plan Ending",
    dataIndex: "end_date",
    key: "end_date",
  },
  {
    title: "Report Usage Limit",
    dataIndex: "report_usage_limit",
    key: "report_usage_limit",
  },
  {
    title: "People Usage Limit",
    dataIndex: "people_usage_limit",
    key: "people_usage_limit",
  },
  {
    align: "center",
    title: <SettingOutlined />,
    dataIndex: "actions",
    key: "actions",
    //@ts-ignore
    // eslint-disable-next-line react/display-name
    render: (text, row, index): JSX.Element => (
      <ActionDropdown text={text} row={row} index={index} />
    ),
  },
];

//@ts-ignore
const ActionDropdown = ({ text, row, index }): JSX.Element => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const closeModal = (): void => setEditModalVisible(false);
  const closeDeleteModal = (): void => setDeleteModalVisible(false);
  return (
    <>
      {editModalVisible && (
        <EditLicenseModal licenseRow={row} closeModal={closeModal} visible={editModalVisible} />
      )}
      {deleteModalVisible && (
        <ModalDeleteLicense
          license={row}
          handleCancel={closeDeleteModal}
          handleOk={closeDeleteModal}
          visible={deleteModalVisible}
        />
      )}
      <Row justify="center">
        <Col>
          <div
            onClick={(): void => setEditModalVisible(true)}
            style={{ cursor: "pointer", color: "#0e7cdc" }}
          >
            Edit
          </div>
        </Col>
        <Col offset={2}>
          <div
            onClick={(): void => setDeleteModalVisible(true)}
            style={{ cursor: "pointer", color: "#0e7cdc" }}
          >
            Delete
          </div>
        </Col>
      </Row>
    </>
  );
};
