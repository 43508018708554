/** @format */

import { UIRow, UICol, UIText, UIInput } from "@rstore-react-packages/template-ui";
import { Button, Form } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { actionLicenseDeleteRequest } from "../../../../redux-modules/actions/actionsLicense";
import { TLicenseForTable } from "../../../../redux-modules/declarations/maps/license";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import StyledModal from "../../../ui/Modal";

/** @format */
interface Props {
  visible: boolean;
  handleOk?: () => void;
  handleCancel?: () => void;
  license: TLicenseForTable;
}
const ModalDeleteLicense = ({
  visible = false,
  handleCancel,
  handleOk,
  license,
}: Props): JSX.Element => {
  const l = useLanguage();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [canDelete, setCanDelete] = useState(false);
  const [typedValue, setTypedValue] = useState("");

  const handleFinish = (form): void => {
    dispatch(actionLicenseDeleteRequest(license.key));
    //@ts-ignore
    handleOk && handleOk();
  };

  const handleReturn = (): void => {
    handleCancel && handleCancel();
  };

  useEffect(() => {
    if (typedValue === "delete license") setCanDelete(true);
    else setCanDelete(false);
  }, [typedValue]);

  return (
    <StyledModal
      title={l("delete")}
      visible={visible}
      //@ts-ignore
      canDelete={canDelete}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleReturn}>
          Return
        </Button>,
        <Button key="submit" disabled={!canDelete} onClick={(): void => form.submit()}>
          Submit
        </Button>,
      ]}
    >
      <UIRow>
        <UICol xs={24}>
          <Form form={form} name="edit-license-status" layout="inline" onFinish={handleFinish}>
            <UIText>{"You are deleting a license."}</UIText>
          </Form>
        </UICol>
      </UIRow>
      <UIRow style={{ marginTop: "10px" }}>
        <UIText>To confirm the operation type "delete license":</UIText>
        <UIInput onChange={(e): void => setTypedValue(e.target.value)} />
      </UIRow>
    </StyledModal>
  );
};

export default ModalDeleteLicense;
