/** @format */

import { TActionAdministratorListSuccess } from "../declarations/actions/people";
import { TActionType } from "../declarations/actionTypes";
import { TPeopleState } from "../declarations/maps/people";

type Actions = TActionAdministratorListSuccess;

const defaultState = {
  list: {
    1: {
      id: 2,
      name: "Comb",
      email: "test@test.it",
      surname: "User 1",
      brands: [
        {
          id: 1,
          name: "Emporio Armani",
        },
        {
          id: 2,
          name: "Giorgio Armani",
        },
        {
          id: 3,
          name: "Armani Exchange",
        },
      ],
      branch: {
        id: 1,
        name: "GA Head Quarter",
        customer_id: 1,
        canDelete: false,
      },
      role: "Super Admin",
      account_type_id: 1,
      account_type: "Premium",
      status: "Active",
      plan_start_date: "10/05/2021",
      plan_end_date: "31/12/2021",
      reports: 500,
      groups: [
        {
          id: 2,
          person_id: 2,
          group_id: 3,
          license_id: 4,
          start_date: "2021-01-08T00:00:00.000000Z",
          end_date: "2021-02-15T00:00:00.000000Z",
          account_type_id: 1,
          report_usage_limit: "unlimited",
          deleted_at: null,
          created_at: "2021-05-03T13:09:01.000000Z",
          updated_at: "2021-05-03T13:09:01.000000Z",
        },
        {
          id: 4,
          person_id: 2,
          group_id: 1,
          license_id: 8,
          start_date: "2021-03-11T00:00:00.000000Z",
          end_date: "2021-03-13T00:00:00.000000Z",
          account_type_id: 1,
          report_usage_limit: "unlimited",
          deleted_at: null,
          created_at: "2021-05-03T13:09:01.000000Z",
          updated_at: "2021-05-03T13:09:01.000000Z",
        },
        {
          id: 34,
          person_id: 2,
          group_id: 3,
          license_id: 2,
          start_date: "2021-04-22T00:00:00.000000Z",
          end_date: "2021-04-12T00:00:00.000000Z",
          account_type_id: 2,
          report_usage_limit: "unlimited",
          deleted_at: null,
          created_at: "2021-05-03T13:09:01.000000Z",
          updated_at: "2021-05-03T13:09:01.000000Z",
        },
        {
          id: 38,
          person_id: 2,
          group_id: 3,
          license_id: 20,
          start_date: "2021-01-13T00:00:00.000000Z",
          end_date: "2021-04-30T00:00:00.000000Z",
          account_type_id: 1,
          report_usage_limit: "unlimited",
          deleted_at: null,
          created_at: "2021-05-03T13:09:01.000000Z",
          updated_at: "2021-05-03T13:09:01.000000Z",
        },
      ],
    },
  },
};

export const reducerAdministrator = (
  state: TPeopleState = defaultState,
  action: Actions,
): TPeopleState => {
  switch (action.type) {
    case TActionType.administratorListSuccess:
      return { list: { ...action.payload } };
    default:
      return state;
  }
};
