/** @format */

import { useParams } from "react-router";

/** @format */

import React from "react";
import { Breadcrumb, Divider } from "antd";
import { UICol, UIRow, UITitle, UITransition } from "@rstore-react-packages/template-ui";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Routes } from "../../../utils/Routes";
import { selectorCustomer } from "../../../redux-modules/selectors/selectorsCustomer";
import { TStore } from "../../../redux-modules/declarations/store";
import { useLanguage } from "../../../redux-modules/hooks/useLanguage";
import { selectorGroup } from "../../../redux-modules/selectors/selectorsGroup";
import LayoutLicensesTable from "../../layout/Tables/Licenses";
import LayoutPeopleTable from "../../layout/Tables/People";
import {
  useGenericAlert,
  useGenericAlertClosable,
} from "../../../redux-modules/hooks/useGenericAlert";
import GroupInfoPanel from "../../layout/InfoPanels/GroupInfoPanel";
import { selectorIsAdmin } from "../../../redux-modules/selectors/selectorsAuth";
import LayoutGroupStatistics from "../../layout/GroupStatistics";

const ViewGroup = (): JSX.Element => {
  //@ts-ignore

  const { id } = useParams();
  const Alert = useGenericAlert(12000);
  const StaticAlert = useGenericAlertClosable();
  const isAdmin = useSelector(selectorIsAdmin);
  const group = useSelector((store: TStore) => selectorGroup(store, id));
  const customer = useSelector((store: TStore) => selectorCustomer(store, `${group?.customer_id}`));

  const l = useLanguage();

  return (
    <UITransition>
      {Alert}
      {StaticAlert}
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <NavLink to={Routes.home}>Home</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <NavLink to={Routes.customer.replace(":id", `${customer?.id}`)}>{customer?.name}</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{group?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <UIRow style={{ marginTop: "30px" }}>
        <UICol>
          <UITitle>{group?.name}</UITitle>
        </UICol>
      </UIRow>
      <GroupInfoPanel group={group} />
      <Divider />
      <LayoutGroupStatistics group={group} />
      {isAdmin && (
        <>
          <UIRow style={{ marginTop: "35px" }}>
            <UICol xs={24}>
              <UITitle level={3}>{l("licenses")}</UITitle>
            </UICol>
          </UIRow>
          <LayoutLicensesTable filteredByGroup={id} />
          <Divider />
        </>
      )}

      <UIRow style={{ marginTop: "35px" }}>
        <UICol xs={24}>
          <UITitle level={3}>{l("people")}</UITitle>
        </UICol>
      </UIRow>
      <LayoutPeopleTable filteredByGroup={id} />
    </UITransition>
  );
};

export default ViewGroup;
