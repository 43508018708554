/** @format */
import React, { useEffect } from "react";
import "./App.less";
import { Switch } from "react-router-dom";
import { RouteGuest } from "./components/utils/RouteGuest";
import { Routes } from "./utils/Routes";
import { ViewLogin } from "./components/views/ViewLogin";
import { ViewForgotPassword } from "./components/views/ViewForgotPassword";
import { RouteLogged } from "./components/utils/RouteLogged";
import ViewHome from "./components/views/ViewHome";
import { Layout } from "antd";
import LayoutFooter from "./components/layout/Footer";
import LayoutHeader from "./components/layout/Header";
import ViewCustomer from "./components/views/ViewCustomer";
import ViewGroup from "./components/views/ViewGroup";
import ViewBranch from "./components/views/ViewBranch";
import ViewBrandList from "./components/views/ViewBrandList";
import ViewBrand from "./components/views/ViewBrand";
import ViewPartnerList from "./components/views/ViewPartnerList";
import ViewPeopleDetail from "./components/views/ViewPeopleDetail";
import ViewStoreDetail from "./components/views/ViewStoreDetail";
import { useDispatch, useSelector } from "react-redux";
import { selectorReduxStoreAge } from "./redux-modules/selectors/selectorsUI";
import { actionStoreReloadRequest } from "./redux-modules/actions/actionsGeneral";
import { actionLogoutRequest } from "./redux-modules/actions/actionsAuth";
const { Content } = Layout;

const App = (): JSX.Element => {
  const reduxStoreAge = useSelector(selectorReduxStoreAge);
  const dispatch = useDispatch();
  useEffect(() => {
    if (Date.now() - reduxStoreAge > 1800000) {
      // se sono passati 10 min
      dispatch(actionLogoutRequest());
    }
    if (Date.now() - reduxStoreAge > 1000) {
      //se dall'ultimo aggiornamento è passato almeno 1 sec
      dispatch(actionStoreReloadRequest());
    }
  }, []);
  return (
    <>
      <Layout style={{ minHeight: "calc(95vh - 100px)" }}>
        <LayoutHeader />
        <Content
          className="site-layout"
          style={{ padding: "0 50px", marginTop: 64, minHeight: "calc(95vh - 100px)" }}
        >
          <Switch>
            <RouteLogged path={Routes.customer} exact component={ViewCustomer} />
            <RouteLogged path={Routes.group} exact component={ViewGroup} />
            <RouteLogged path={Routes.brand} exact component={ViewBrand} />
            <RouteLogged path={Routes.brands} exact component={ViewBrandList} />
            <RouteLogged path={Routes.branch} exact component={ViewBranch} />
            <RouteLogged path={Routes.partners} exact component={ViewPartnerList} />
            <RouteLogged path={Routes.peopleDetail} exact component={ViewPeopleDetail} />
            <RouteLogged path={Routes.storeDetail} exact component={ViewStoreDetail} />
            <RouteLogged path={Routes.home} exact component={ViewHome} />
            <RouteGuest path={Routes.forgotPassword} component={ViewForgotPassword} />
            <RouteGuest path="/" component={ViewLogin} />
          </Switch>
        </Content>
      </Layout>
      <LayoutFooter />
    </>
  );
};

export default App;
