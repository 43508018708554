/** @format */

import { AxiosError, AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../../constants/api";
import { TPeopleImportRequest } from "../../redux-modules/declarations/maps/people";
import apiClient from "../../utils/apiClient";

export const apiPeopleImport = (
  data: TPeopleImportRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .post(API_ENDPOINTS.PEOPLE_IMPORT, data)
    .then(response => ({ response }))
    .catch(error => ({ error }));

export const apiPeopleImportPolling = (
  id: number,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .get(API_ENDPOINTS.IMPORT_STATUS.replace(":id", `${id}`))
    .then(response => ({ response }))
    .catch(error => ({ error }));
