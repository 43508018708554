/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import { sagaCheckApiToken, sagaLogin, sagaLogout, sagaStoreReload } from "../sagas/sagasAuth";

export function* watcherAuth(): unknown {
  yield takeLatest(TActionType.loginRequest, sagaLogin);
  yield takeLatest(TActionType.logoutRequest, sagaLogout);
  yield takeLatest(TActionType.startCheckStatus, sagaCheckApiToken);
  yield takeLatest(TActionType.storeReloadRequest, sagaStoreReload);
}
