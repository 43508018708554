/** @format */

import {
  TActionAccountTypeListSuccess,
  TActionAccountTypeListReset,
} from "../declarations/actions/accountTypes";
import { TActionType } from "../declarations/actionTypes";
import { TAccountTypeState } from "../declarations/maps/accountType";

type Actions = TActionAccountTypeListSuccess | TActionAccountTypeListReset;

const defaultState = {
  list: {
    1: {
      id: 1,
      name: "xxx",
      platform_account_type: "01",
    },
    2: {
      id: 2,
      name: "yyy",
      platform_account_type: "01",
    },
  },
};

export const reducerAccountType = (
  state: TAccountTypeState = defaultState,
  action: Actions,
): TAccountTypeState => {
  switch (action.type) {
    case TActionType.accountTypeListSuccess:
      return { list: { ...action.payload } };
    case TActionType.accountTypeListReset:
      return defaultState;
    default:
      return state;
  }
};
