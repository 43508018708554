/** @format */

import { AxiosError, AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { apiBrandAdminCreate, apiBrandAdminDelete } from "../../api/brand/apiBrandAdmin";
import { apiBrandCreate } from "../../api/brand/apiBrandCreate";
import { apiBrandList } from "../../api/brand/apiBrandList";
import { apiBrandUpdate } from "../../api/brand/apiBrandUpdate";
import { arrayToObject } from "../../utils/format/arrayToObject";
import {
  actionBrandAdminCreateFailure,
  actionBrandAdminCreateSuccess,
  actionBrandAdminDeleteFailure,
  actionBrandAdminDeleteSuccess,
  actionBrandCreateFailure,
  actionBrandCreateSuccess,
  actionBrandListFailure,
  actionBrandListRequest,
  actionBrandListSuccess,
  actionBrandUpdateFailure,
  actionBrandUpdateSuccess,
} from "../actions/actionsBrand";

import { actionAlertGenerate } from "../actions/actionsUI";
import {
  TActionBrandAdminCreateRequest,
  TActionBrandAdminDeleteRequest,
  TActionBrandCreateRequest,
  TActionBrandListRequest,
  TActionBrandUpdateRequest,
} from "../declarations/actions/brands";
import { TAPIValidationError } from "../declarations/apis/general";

export function* sagaBrandList(action: TActionBrandListRequest): unknown {
  const {
    response,
    error,
  }: { response?: AxiosResponse; error?: AxiosError<{ errors: TAPIValidationError }> } = yield call(
    apiBrandList,
  );

  if (!error && response) {
    //@ts-ignore
    yield put(actionBrandListSuccess(arrayToObject(response.data.data, "id")));
  } else {
    yield put(
      //@ts-ignore
      actionBrandListFailure((error?.response?.data?.errors as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.errors?.toString() || "",
      }),
    );
  }
}

export function* sagaBrandCreate(action: TActionBrandCreateRequest): unknown {
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiBrandCreate, action.payload);

  if (!error && response) {
    yield put(actionBrandCreateSuccess());
    //@ts-ignore
    yield put(actionBrandListRequest());
  } else {
    yield put(
      //@ts-ignore
      actionBrandCreateFailure((error?.response?.data?.message as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}

export function* sagaBrandUpdate(action: TActionBrandUpdateRequest): unknown {
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiBrandUpdate, action.payload);

  if (!error && response) {
    yield put(actionBrandUpdateSuccess());
    yield put(actionBrandListRequest());
  } else {
    yield put(
      //@ts-ignore
      actionBrandUpdateFailure((error?.response?.data?.message as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}

export function* sagaBrandAdminCreate(action: TActionBrandAdminCreateRequest): unknown {
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiBrandAdminCreate, action.payload);

  if (!error && response) {
    yield put(actionBrandAdminCreateSuccess());

    yield put(actionBrandListRequest());
  } else {
    yield put(
      //@ts-ignore
      actionBrandAdminCreateFailure((error?.response?.data?.message as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}

export function* sagaBrandAdminDelete(action: TActionBrandAdminDeleteRequest): unknown {
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiBrandAdminDelete, action.payload);

  if (!error && response) {
    yield put(actionBrandAdminDeleteSuccess());
    //@ts-ignore
    yield put(actionBrandListRequest());
  } else {
    yield put(
      //@ts-ignore
      actionBrandAdminDeleteFailure((error?.response?.data?.message as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}
