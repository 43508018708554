/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import { sagaPartnerDelete, sagaPartnerList } from "../sagas/sagasPartner";

export function* watcherPartner(): unknown {
  yield takeLatest(TActionType.partnerListRequest, sagaPartnerList);
  yield takeLatest(TActionType.partnerDeleteRequest, sagaPartnerDelete);
}
