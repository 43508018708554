/** @format */

import { AxiosError, AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { apiCustomerList } from "../../api/customer/apiCustomerList";
import { apiCustomerUpdate } from "../../api/customer/apiCustomerUpdate";
import { arrayToObject } from "../../utils/format/arrayToObject";
import {
  actionCustomerListFailure,
  actionCustomerListRequest,
  actionCustomerListSuccess,
  actionCustomerUpdateFailure,
  actionCustomerUpdateSuccess,
} from "../actions/actionsCustomer";
import {
  actionAlertGenerate,
  actionCustomerListLoadingStart,
  actionCustomerListLoadingStop,
} from "../actions/actionsUI";
import {
  TActionCustomerListRequest,
  TActionCustomerUpdateRequest,
} from "../declarations/actions/customers";
import { TAPIValidationError } from "../declarations/apis/general";

export function* sagaCustomerList(action: TActionCustomerListRequest): unknown {
  yield put(actionCustomerListLoadingStart());
  try {
    const response = yield call(apiCustomerList);
    const data = response?.data.data;

    //@ts-ignore
    yield put(actionCustomerListSuccess(arrayToObject(data, "id")));
    yield put(actionCustomerListLoadingStop());
  } catch (e) {
    yield put(
      //@ts-ignore
      actionCustomerListFailure((e?.response?.data?.errors as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        description: e?.response?.data?.errors?.toString() || "",
      }),
    );
  }
}

export function* sagaCustomerUpdate(action: TActionCustomerUpdateRequest): unknown {
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiCustomerUpdate, action.payload);

  if (!error && response) {
    yield put(actionCustomerUpdateSuccess());
    yield put(actionCustomerListRequest());
  } else {
    yield put(
      //@ts-ignore
      actionCustomerUpdateFailure((error?.response?.data?.message as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}
