/** @format */

export enum TActionType {
  //region Login

  loginRequest = "[Action] - Create Login Request",
  loginSuccess = "[Event] - Login Success",
  loginFailure = "[Event] - Login Failure",
  loginReset = "[Action] - Login Reset",

  logoutRequest = "[Action] - Create Logout Request",
  logoutSuccess = "[Event] - Logout Success",
  logoutFailure = "[Event] - Logout Failure",

  startCheckStatus = "[Action] - Start Api Key Check",
  successCheckStatus = "[Event] - Success Api Key Check",
  failureCheckStatus = "[Event] - Failure Api Key Check",
  endCheckStatus = "[Event] - End Api Key Check",

  //endregion

  //region Navigation

  navigationRequest = "[Action] - Navigation Request",
  navigationSuccess = "[Action] - Navigation Success",
  navigationFailure = "[Action] - Navigation Failure",

  appLoadingRequest = "[Action] - App Loading Request",
  appLoadingSuccess = "[Action] - App Loading Success",
  appLoadingFailure = "[Action] - App Loading Failure",

  //endregion

  //region Cache

  cacheSaveSuccess = "[Event] - Save to Local Storage Success",
  cacheSaveFailure = "[Event] - Save to Local Storage Failure",

  cacheRemoveSuccess = "[Event] - Remove from Local Storage Success",
  cacheRemoveFailure = "[Event] - Remove from Local Storage Success",
  //endregion

  //region Alert
  alertGenerate = "[Event] - Alert Generate",
  alertRemove = "[Event] - Alert Remove",
  //endregion

  //region Static Alert
  staticAlertGenerate = "[Event] - Static Alert Generate",
  staticAlertRemove = "[Event] - Static Alert Remove",
  //endregion

  // region Language
  setLanguage = "[Event] - Set Language",
  //endregion

  //region Customer
  customerSelect = "[Event] - Customer Select",
  customerListLoadingStart = "[Event] - Customer List Loading Start",
  customerListLoadingStop = "[Event] - Customer List Loading Stop",

  customerListRequest = "[Action] - Customer List Request",
  customerListSuccess = "[Event] - Customer List Success",
  customerListFailure = "[Event] - Customer List Failure",
  customerListReset = "[Action] - Customer List Reset",

  customerCreateRequest = "[Action] - Customer Create Request",
  customerCreateSuccess = "[Event] - Customer Create Success",
  customerCreateFailure = "[Event] - Customer Create Failure",

  customerUpdateRequest = "[Action] - Customer Update Request",
  customerUpdateSuccess = "[Event] - Customer Update Success",
  customerUpdateFailure = "[Event] - Customer Update Failure",
  customerUpdateReset = "[Action] - Customer Update Reset",

  customerDeleteRequest = "[Action] - Customer Delete Request",
  customerDeleteSuccess = "[Event] - Customer Delete Success",
  customerDeleteFailure = "[Event] - Customer Delete Failure",
  customerDeleteReset = "[Action] - Customer Delete Reset",
  //endregion

  //region Group
  groupListLoadingStart = "[Event] - Group List Loading Start",
  groupListLoadingStop = "[Event] - Group List Loading Stop",

  groupListRequest = "[Action] - Group List Request",
  groupListSuccess = "[Event] - Group List Success",
  groupListFailure = "[Event] - Group List Failure",
  groupListReset = "[Action] - Group List Reset",

  groupCreateRequest = "[Action] - Group Create Request",
  groupCreateSuccess = "[Event] - Group Create Success",
  groupCreateFailure = "[Event] - Group Create Failure",

  groupAdminCreateRequest = "[Action] - Group Admin Create Request",
  groupAdminCreateSuccess = "[Event] - Group Admin Create Success",
  groupAdminCreateFailure = "[Event] - Group Admin Create Failure",

  groupAdminDeleteRequest = "[Action] - Group Admin Delete Request",
  groupAdminDeleteSuccess = "[Event] - Group Admin Delete Success",
  groupAdminDeleteFailure = "[Event] - Group Admin Delete Failure",

  groupUpdateRequest = "[Action] - Group Update Request",
  groupUpdateSuccess = "[Event] - Group Update Success",
  groupUpdateFailure = "[Event] - Group Update Failure",
  groupUpdateReset = "[Action] - Group Update Reset",

  groupDeleteRequest = "[Action] - Group Delete Request",
  groupDeleteSuccess = "[Event] - Group Delete Success",
  groupDeleteFailure = "[Event] - Group Delete Failure",
  groupDeleteReset = "[Action] - Group Delete Reset",
  //endregion

  //region License
  licenseListLoadingStart = "[Event] - License List Loading Start",
  licenseListLoadingStop = "[Event] - License List Loading Stop",

  licenseListRequest = "[Action] - License List Request",
  licenseListSuccess = "[Event] - License List Success",
  licenseListFailure = "[Event] - License List Failure",
  licenseListReset = "[Action] - License List Reset",

  licenseCreateRequest = "[Action] - License Create Request",
  licenseCreateSuccess = "[Event] - License Create Success",
  licenseCreateFailure = "[Event] - License Create Failure",

  licenseUpdateRequest = "[Action] - License Update Request",
  licenseUpdateSuccess = "[Event] - License Update Success",
  licenseUpdateFailure = "[Event] - License Update Failure",
  licenseUpdateReset = "[Action] - License Update Reset",

  licenseDeleteRequest = "[Action] - License Delete Request",
  licenseDeleteSuccess = "[Event] - License Delete Success",
  licenseDeleteFailure = "[Event] - License Delete Failure",
  licenseDeleteReset = "[Action] - License Delete Reset",
  //endregion

  //region People
  peopleListLoadingStart = "[Event] - People List Loading Start",
  peopleListLoadingStop = "[Event] - People List Loading Stop",
  importPeopleLoadingStart = "[Event] - Import People Loading Start",
  importPeopleLoadingStop = "[Event] - Import People Loading Stop",

  peopleListRequest = "[Action] - People List Request",
  peopleListSuccess = "[Event] - People List Success",
  peopleListFailure = "[Event] - People List Failure",
  peopleListReset = "[Action] - People List Reset",

  peopleCreateRequest = "[Action] - People Create Request",
  peopleCreateSuccess = "[Event] - People Create Success",
  peopleCreateFailure = "[Event] - People Create Failure",

  peopleUpdateRequest = "[Action] - People Update Request",
  peopleUpdateSuccess = "[Event] - People Update Success",
  peopleUpdateFailure = "[Event] - People Update Failure",
  peopleUpdateReset = "[Action] - People Update Reset",

  peopleDeleteRequest = "[Action] - People Delete Request",
  peopleDeleteSuccess = "[Event] - People Delete Success",
  peopleDeleteFailure = "[Event] - People Delete Failure",
  peopleDeleteReset = "[Action] - People Delete Reset",

  peopleImportRequest = "[Action] - People Import Request",
  peopleImportSuccess = "[Event] - People Import Success",
  peopleImportFailure = "[Event] - People Import Failure",
  peopleImportReset = "[Action] - People Import Reset",

  peopleImportPollingStart = "[Action] - People Import Polling Start",
  peopleImportPollingStop = "[Action] - People Import Polling Stop",
  peopleImportPollingError = "[Action] - People Import Polling Error",
  //endregion

  //region Administrator
  administratorListRequest = "[Action] - Administrator List Request",
  administratorListSuccess = "[Event] - Administrator List Success",
  administratorListFailure = "[Event] - Administrator List Failure",

  //region Brand
  brandListLoadingStart = "[Event] - Brand List Loading Start",
  brandListLoadingStop = "[Event] - Brand List Loading Stop",

  brandListRequest = "[Action] - Brand List Request",
  brandListSuccess = "[Event] - Brand List Success",
  brandListFailure = "[Event] - Brand List Failure",
  brandListReset = "[Action] - Brand List Reset",

  brandCreateRequest = "[Action] - Brand Create Request",
  brandCreateSuccess = "[Event] - Brand Create Success",
  brandCreateFailure = "[Event] - Brand Create Failure",

  brandUpdateRequest = "[Action] - Brand Update Request",
  brandUpdateSuccess = "[Event] - Brand Update Success",
  brandUpdateFailure = "[Event] - Brand Update Failure",
  brandUpdateReset = "[Action] - Brand Update Reset",

  brandDeleteRequest = "[Action] - Brand Delete Request",
  brandDeleteSuccess = "[Event] - Brand Delete Success",
  brandDeleteFailure = "[Event] - Brand Delete Failure",
  brandDeleteReset = "[Action] - Brand Delete Reset",

  brandAdminCreateRequest = "[Action] - Brand Admin Create Request",
  brandAdminCreateSuccess = "[Event] - Brand Admin Create Success",
  brandAdminCreateFailure = "[Event] - Brand Admin Create Failure",

  brandAdminDeleteRequest = "[Action] - Brand Admin Delete Request",
  brandAdminDeleteSuccess = "[Event] - Brand Admin Delete Success",
  brandAdminDeleteFailure = "[Event] - Brand Admin Delete Failure",

  //endregion

  //region Branch
  branchListLoadingStart = "[Event] - Branch List Loading Start",
  branchListLoadingStop = "[Event] - Branch List Loading Stop",

  branchListRequest = "[Action] - Branch List Request",
  branchListSuccess = "[Event] - Branch List Success",
  branchListFailure = "[Event] - Branch List Failure",
  branchListReset = "[Action] - Branch List Reset",

  branchCreateRequest = "[Action] - Branch Create Request",
  branchCreateSuccess = "[Event] - Branch Create Success",
  branchCreateFailure = "[Event] - Branch Create Failure",

  branchUpdateRequest = "[Action] - Branch Update Request",
  branchUpdateSuccess = "[Event] - Branch Update Success",
  branchUpdateFailure = "[Event] - Branch Update Failure",
  branchUpdateReset = "[Action] - Branch Update Reset",

  branchDeleteRequest = "[Action] - Branch Delete Request",
  branchDeleteSuccess = "[Event] - Branch Delete Success",
  branchDeleteFailure = "[Event] - Branch Delete Failure",
  branchDeleteReset = "[Action] - Branch Delete Reset",
  //endregion

  //region Tipology
  tipologyListRequest = "[Action] - Tipology List Request",
  tipologyListSuccess = "[Event] - Tipology List Success",
  tipologyListFailure = "[Event] - Tipology List Failure",
  tipologyListReset = "[Action] - Tipology List Reset",
  //endregion

  //region Account Type
  accountTypeListRequest = "[Action] - Account Type List Request",
  accountTypeListSuccess = "[Event] - Account Type List Success",
  accountTypeListFailure = "[Event] - Account Type List Failure",
  accountTypeListReset = "[Action] - Account Type List Reset",
  //endregion

  //region Roles
  roleListRequest = "[Action] - Role List Request",
  roleListSuccess = "[Event] - Role List Success",
  roleListFailure = "[Event] - Role List Failure",
  roleListReset = "[Action] - Role List Reset",
  //endregion

  //region People Massive Request
  massivePeopleBrandRequest = "[Action] - People Brand Massive Request",
  massivePeopleBrandSuccess = "[Event] - People Brand Massive Success",
  massivePeopleBrandFailure = "[Action] - People Brand Massive Failure",

  massivePeopleBranchRequest = "[Action] - People Branch Massive Request",
  massivePeopleBranchSuccess = "[Event] - People Branch Massive Success",
  massivePeopleBranchFailure = "[Action] - People Branch Massive Failure",

  massivePeoplePlanRequest = "[Action] - People Plan Massive Request",
  massivePeoplePlanSuccess = "[Event] - People Plan Massive Success",
  massivePeoplePlanFailure = "[Action] - People Plan Massive Failure",

  massivePeopleStatusRequest = "[Action] - People Status Massive Request",
  massivePeopleStatusSuccess = "[Event] - People Status Massive Success",
  massivePeopleStatusFailure = "[Action] - People Status Massive Failure",

  massivePeopleAccountTypeRequest = "[Action] - People AccountType Massive Request",
  massivePeopleAccountTypeSuccess = "[Event] - People AccountType Massive Success",
  massivePeopleAccountTypeFailure = "[Action] - People AccountType Massive Failure",

  massivePeopleRoleRequest = "[Action] - People Role Massive Request",
  massivePeopleRoleSuccess = "[Event] - People Role Massive Success",
  massivePeopleRoleFailure = "[Action] - People Role Massive Failure",

  massivePeopleReportsRequest = "[Action] - People Reports Massive Request",
  massivePeopleReportsSuccess = "[Event] - People Reports Massive Success",
  massivePeopleReportsFailure = "[Action] - People Reports Massive Failure",

  massivePeopleGroupRequest = "[Action] - People Group Massive Request",
  massivePeopleGroupSuccess = "[Event] - People Group Massive Success",
  massivePeopleGroupFailure = "[Action] - People Group Massive Failure",

  massivePeopleLicenseRequest = "[Action] - People License Massive Request",
  massivePeopleLicenseSuccess = "[Event] - People License Massive Success",
  massivePeopleLicenseFailure = "[Action] - People License Massive Failure",

  massivePeopleActivationRequest = "[Action] - People Activation Massive Request",
  massivePeopleActivationSuccess = "[Event] - People Activation Massive Success",
  massivePeopleActivationFailure = "[Action] - People Activation Massive Failure",

  //region Store
  storeListLoadingStart = "[Event] - Store List Loading Start",
  storeListLoadingStop = "[Event] - Store List Loading Stop",

  storeListRequest = "[Action] - Store List Request",
  storeListSuccess = "[Event] - Store List Success",
  storeListFailure = "[Event] - Store List Failure",

  storeUpdateRequest = "[Action] - Store Update Request",
  storeUpdateSuccess = "[Event] - Store Update Success",
  storeUpdateFailure = "[Event] - Store Update Failure",

  storeCreateRequest = "[Action] - Store Create Request",
  storeCreateSuccess = "[Event] - Store Create Success",
  storeCreateFailure = "[Event] - Store Create Failure",
  //endregion

  //region Partner
  partnerListLoadingStart = "[Event] - Partner List Loading Start",
  partnerListLoadingStop = "[Event] - Partner List Loading Stop",

  partnerListRequest = "[Action] - Partner List Request",
  partnerListSuccess = "[Event] - Partner List Success",
  partnerListFailure = "[Event] - Partner List Failure",

  partnerDeleteRequest = "[Action] - Partner Delete Request",
  partnerDeleteSuccess = "[Event] - Partner Delete Success",
  partnerDeleteFailure = "[Event] - Partner Delete Failure",
  //endregion

  //region Store Massive
  massiveStoreBrandRequest = "[Action] - Store Brand Massive Request",
  massiveStoreBrandSuccess = "[Event] - Store Brand Massive Success",
  massiveStoreBrandFailure = "[Action] - Store Brand Massive Failure",

  massiveStoreBranchRequest = "[Action] - Store Branch Massive Request",
  massiveStoreBranchSuccess = "[Event] - Store Branch Massive Success",
  massiveStoreBranchFailure = "[Action] - Store Branch Massive Failure",

  massiveStorePartnerRequest = "[Action] - Store Partner Massive Request",
  massiveStorePartnerSuccess = "[Event] - Store Partner Massive Success",
  massiveStorePartnerFailure = "[Action] - Store Partner Massive Failure",

  massiveStoreStatusRequest = "[Action] - Store Status Massive Request",
  massiveStoreStatusSuccess = "[Event] - Store Status Massive Success",
  massiveStoreStatusFailure = "[Action] - Store Status Massive Failure",
  //endregion

  //region Set Store Timestamp
  storeTimestampSetRequest = "[Action] - Store Timestamp Set Request",
  storeTimestampSetSuccess = "[Event] - Store Timestamp Set Success",
  storeTimestampSetFailure = "[Action] - Store Timestamp Set Failure",
  //endregion

  //region Reload Store
  storeReloadRequest = "[Action] - Store Reload Request",
  storeReloadSuccess = "[Event] - Store Reload Success",
  storeReloadFailure = "[Action] - Store Reload Failure",
  //endregion

  //region City
  cityListRequest = "[Action] - City List Request",
  cityListSuccess = "[Event] - City List Success",
  cityListFailure = "[Action] - City List Failure",
  //endregion

  //region Country
  countryListRequest = "[Action] - Country List Request",
  countryListSuccess = "[Event] - Country List Success",
  countryListFailure = "[Action] - Country List Failure",
  //endregion

  //region StoreType
  storeTypeListRequest = "[Action] - Store Type List Request",
  storeTypeListSuccess = "[Event] - Store Type List Success",
  storeTypeListFailure = "[Action] - Store Type List Failure",
  //endregion
}

export interface TAction<T = TActionType> {
  type: T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}
