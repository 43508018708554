/** @format */

import {
  UITransition,
  UIRow,
  UICol,
  UICard,
  UIInput,
  UITitle,
  UIButton,
} from "@rstore-react-packages/template-ui";
import { Form, Select } from "antd";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TSTORE_STATUS_LIST } from "../../../../constants/general";
import { actionStoreCreateRequest } from "../../../../redux-modules/actions/actionsStore";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import { selectorBranchListForSelect } from "../../../../redux-modules/selectors/selectorsBranch";
import { selectorBrandListForSelect } from "../../../../redux-modules/selectors/selectorsBrand";
import { selectorPartnerList } from "../../../../redux-modules/selectors/selectorsPartner";
import { labelWithRequired } from "../../../../utils/format/labelWithRequired";
const { Option } = Select;

interface Props {
  brandId: number;
  onCreateCallback?: () => void;
}
const FormAddStore: FC<Props> = ({ brandId, onCreateCallback }: Props): JSX.Element => {
  const l = useLanguage();
  const dispatch = useDispatch();
  const branches = useSelector(selectorBranchListForSelect);
  const brands = useSelector(selectorBrandListForSelect);
  const partners = useSelector(selectorPartnerList);

  const handleFinish = (form): void => {
    form.brandId = brandId;
    dispatch(actionStoreCreateRequest(form));
    if (onCreateCallback) onCreateCallback();
  };
  return (
    <UITransition>
      <UIRow style={{ marginTop: "20px", marginBottom: "20px" }}>
        <UICol xs={24}>
          <UICard>
            <UIRow>
              <UICol xs={24} style={{ marginBottom: "5px" }}>
                <UITitle level={3}>{l("addStore")}</UITitle>
              </UICol>
            </UIRow>
            <Form name="add-store" layout="vertical" onFinish={handleFinish} requiredMark={false}>
              <UIRow>
                <UICol xs={24} md={8}>
                  <UIRow>
                    <UICol xs={24}>
                      <Form.Item
                        name="name"
                        label={labelWithRequired(l("name"))}
                        rules={[
                          {
                            required: true,
                            message: "Please insert a name!",
                          },
                        ]}
                      >
                        <UIInput />
                      </Form.Item>
                    </UICol>
                  </UIRow>
                  <UIRow>
                    <UICol xs={24}>
                      <Form.Item
                        name="address"
                        label={labelWithRequired(l("address"))}
                        rules={[
                          {
                            required: true,
                            message: "Please insert an address!",
                          },
                        ]}
                      >
                        <UIInput />
                      </Form.Item>
                    </UICol>
                  </UIRow>
                  <UIRow>
                    <UICol xs={24}>
                      <Form.Item
                        name="branch_id"
                        label={labelWithRequired(l("branch"))}
                        rules={[
                          {
                            required: true,
                            message: "Please select a branch!",
                          },
                        ]}
                      >
                        <Select
                          optionFilterProp="label"
                          showSearch
                          filterOption={(input, option): boolean =>
                            //@ts-ignore
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {branches.map(branch => (
                            <Option value={branch.value} key={branch.key}>
                              {branch.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </UICol>
                  </UIRow>
                  <UIRow>
                    <UICol xs={24}>
                      <Form.Item
                        name="brands_ids"
                        label={labelWithRequired(l("brands"))}
                        rules={[
                          {
                            required: true,
                            message: "Please select at least one brand!",
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          optionFilterProp="label"
                          showSearch
                          filterOption={(input, option): boolean =>
                            //@ts-ignore
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {brands.map(brand => (
                            <Option value={brand.value} key={brand.key}>
                              {brand.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </UICol>
                  </UIRow>
                  <UIRow>
                    <UICol xs={24}>
                      <Form.Item
                        name="partner_id"
                        label={labelWithRequired(l("partner"))}
                        rules={[
                          {
                            required: true,
                            message: "Please select a partner!",
                          },
                        ]}
                      >
                        <Select>
                          {partners.map(part => (
                            <Option key={part.id} value={part.id}>
                              {part.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </UICol>
                  </UIRow>
                </UICol>
                <UICol xs={24} md={8} offset={1}>
                  <UIRow>
                    <UICol xs={24}>
                      <Form.Item
                        name="csHq"
                        label={labelWithRequired("Cs Hq")}
                        rules={[
                          {
                            required: true,
                            message: "Please pick one!",
                          },
                        ]}
                      >
                        <UIInput />
                      </Form.Item>
                    </UICol>
                  </UIRow>
                  <UIRow>
                    <UICol xs={24}>
                      <Form.Item
                        name="amHq"
                        label={labelWithRequired("Am Hq")}
                        rules={[
                          {
                            required: true,
                            message: "Please pick one!",
                          },
                        ]}
                      >
                        <UIInput />
                      </Form.Item>
                    </UICol>
                  </UIRow>
                  <UIRow>
                    <UICol xs={24}>
                      <Form.Item
                        name="contactList"
                        label={labelWithRequired("Contact List")}
                        rules={[
                          {
                            required: true,
                            message: "Please pick one!",
                          },
                        ]}
                      >
                        <Select
                          mode="tags"
                          style={{ width: "100%" }}
                          tokenSeparators={[";"]}
                        ></Select>
                      </Form.Item>
                    </UICol>
                  </UIRow>
                  <UIRow>
                    <UICol xs={24}>
                      <Form.Item
                        name="storeStatus"
                        label={labelWithRequired("Store Status")}
                        rules={[
                          {
                            required: true,
                            message: "Please pick one!",
                          },
                        ]}
                      >
                        <Select>
                          {Object.keys(TSTORE_STATUS_LIST).map(el => (
                            <Option key={el} value={el}>
                              {el}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </UICol>
                  </UIRow>
                  <UIRow>
                    <UICol xs={24}>
                      <Form.Item
                        name="gender"
                        label={labelWithRequired("Gender")}
                        rules={[
                          {
                            required: true,
                            message: "Please pick one!",
                          },
                        ]}
                      >
                        <UIInput />
                      </Form.Item>
                    </UICol>
                  </UIRow>
                  <UIRow>
                    <UICol xs={24}>
                      <Form.Item
                        name="line"
                        label={labelWithRequired("Line")}
                        rules={[
                          {
                            required: true,
                            message: "Please pick one!",
                          },
                        ]}
                      >
                        <UIInput />
                      </Form.Item>
                    </UICol>
                  </UIRow>
                  <UIRow>
                    <UICol xs={24}>
                      <Form.Item
                        name="doorCode"
                        label={labelWithRequired("Door Code")}
                        rules={[
                          {
                            required: true,
                            message: "Please pick one!",
                          },
                        ]}
                      >
                        <UIInput />
                      </Form.Item>
                    </UICol>
                  </UIRow>
                </UICol>
              </UIRow>
              <UIRow>
                <UICol xs={24} md={8}>
                  <Form.Item>
                    <UIButton htmlType="submit" style={{ color: "#000" }}>
                      {l("save")}
                    </UIButton>
                  </Form.Item>
                </UICol>
              </UIRow>
            </Form>
          </UICard>
        </UICol>
      </UIRow>
    </UITransition>
  );
};

export default FormAddStore;
