/** @format */

import { takeLatest } from "redux-saga/effects";

import { TActionType } from "../declarations/actionTypes";
import {
  pollTask,
  sagaPeopleCreate,
  sagaPeopleImport,
  sagaPeopleList,
  sagaPeopleUpdate,
} from "../sagas/sagasPeople";

export function* watcherPeople(): unknown {
  yield takeLatest(TActionType.peopleListRequest, sagaPeopleList);
  yield takeLatest(TActionType.peopleImportRequest, sagaPeopleImport);
  yield takeLatest(TActionType.peopleImportPollingStart, pollTask);
  yield takeLatest(TActionType.peopleUpdateRequest, sagaPeopleUpdate);
  yield takeLatest(TActionType.peopleCreateRequest, sagaPeopleCreate);
}
