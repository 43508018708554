/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import {
  sagaMassiveStoreBranch,
  sagaMassiveStoreBrand,
  sagaMassiveStorePartner,
  sagaMassiveStoreStatus,
} from "../sagas/sagasMassiveStore";

export function* watcherMassiveStoreAction(): unknown {
  yield takeLatest(TActionType.massiveStoreBrandRequest, sagaMassiveStoreBrand);
  yield takeLatest(TActionType.massiveStoreBranchRequest, sagaMassiveStoreBranch);
  yield takeLatest(TActionType.massiveStoreStatusRequest, sagaMassiveStoreStatus);
  yield takeLatest(TActionType.massiveStorePartnerRequest, sagaMassiveStorePartner);
}
