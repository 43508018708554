/** @format */

import React, { FC } from "react";
import { UIRow, UICol, UICard } from "@rstore-react-packages/template-ui";
import { Statistic } from "antd";
import { UserOutlined, FileProtectOutlined } from "@ant-design/icons";
import { TCustomer } from "../../../redux-modules/declarations/maps/customer";
import { useSelector } from "react-redux";
import { selectorIsAdmin } from "../../../redux-modules/selectors/selectorsAuth";

interface Props {
  customer: TCustomer | null;
}

const LayoutCustomerStatistics: FC<Props> = ({ customer }: Props): JSX.Element => {
  const isAdmin = useSelector(selectorIsAdmin);

  if (!!!isAdmin) return <></>;
  if (!!!customer)
    return (
      <UIRow justify="space-between">
        <UICol xs={11}>
          <UICard style={{ width: 230, marginTop: 16 }} loading></UICard>
        </UICol>
        <UICol xs={11}>
          <UICard style={{ width: 230, marginTop: 16 }} loading></UICard>
        </UICol>
      </UIRow>
    );
  return (
    <UIRow justify="space-between">
      <UICol xs={11}>
        <UICard>
          <Statistic
            title="People"
            value={customer.count_people}
            valueStyle={{ color: "#3f8600" }}
            prefix={<UserOutlined />}
          />
        </UICard>
      </UICol>
      <UICol xs={11} offset={2}>
        <UICard>
          <Statistic
            title="Reports"
            value={customer.count_reports}
            valueStyle={{ color: "#3f8600" }}
            prefix={<FileProtectOutlined />}
          />
        </UICard>
      </UICol>
    </UIRow>
  );
};

export default LayoutCustomerStatistics;
