/** @format */

import { UIRow, UICol } from "@rstore-react-packages/template-ui";
import { Button, Form, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionMassivePeopleRoleRequest } from "../../../../redux-modules/actions/actionsGeneral";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import { selectorIsAdmin } from "../../../../redux-modules/selectors/selectorsAuth";
import { selectorRoleList } from "../../../../redux-modules/selectors/selectorsRole";
import StyledModal from "../../../ui/Modal";
const { Option } = Select;

/** @format */
interface Props {
  visible: boolean;
  handleOk?: () => void;
  handleCancel?: () => void;
  people: number[];
}
const ModalChangeRole = ({
  visible = false,
  handleCancel,
  handleOk,
  people,
}: Props): JSX.Element => {
  const l = useLanguage();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const roles = useSelector(selectorRoleList);
  const isAdmin = useSelector(selectorIsAdmin);

  const handleFinish = (form): void => {
    dispatch(
      actionMassivePeopleRoleRequest({
        users_ids: people,
        action: "role",
        role_id: form.role,
      }),
    );
    //@ts-ignore
    handleOk && handleOk();
  };

  const handleReturn = (): void => {
    handleCancel && handleCancel();
  };

  return (
    <StyledModal
      title={l("editRole")}
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleReturn}>
          Return
        </Button>,
        <Button key="submit" onClick={(): void => form.submit()}>
          Submit
        </Button>,
      ]}
    >
      <UIRow>
        <UICol xs={24}>
          <Form form={form} name="edit-people-role" layout="horizontal" onFinish={handleFinish}>
            <Form.Item
              name="role"
              label={"You are editing " + people.length + "'s people role with:"}
            >
              <Select
                optionFilterProp="label"
                showSearch
                filterOption={(input, option): boolean =>
                  //@ts-ignore
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {!!isAdmin
                  ? roles.map(role => (
                      <Option key={role.id} value={role.id}>
                        {role.name}
                      </Option>
                    ))
                  : roles
                      .filter(e => e.id !== 1 && e.id !== 2 && e.id !== 3)
                      .map(role => (
                        <Option key={role.id} value={role.id}>
                          {role.name}
                        </Option>
                      ))}
              </Select>
            </Form.Item>
          </Form>
        </UICol>
      </UIRow>
    </StyledModal>
  );
};
export default ModalChangeRole;
