/** @format */

import { UIButton, UIText } from "@rstore-react-packages/template-ui";
import { Dropdown, Menu } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import { selectorIsAdmin } from "../../../../redux-modules/selectors/selectorsAuth";
import ModalChangeActivation from "../../Modals/People/changeActivation";
//import ModalChangeAccountType from "../../Modals/People/changeAccountType";
import ModalChangeBranch from "../../Modals/People/changeBranch";
import ModalChangeBrand from "../../Modals/People/changeBrand";
import ModalChangeGroup from "../../Modals/People/changeGroup";
import ModalChangeLicense from "../../Modals/People/changeLicense";
import ModalChangePlan from "../../Modals/People/changePlan";
import ModalChangeReport from "../../Modals/People/changeReport";
import ModalChangeRole from "../../Modals/People/changeRole";
import ModalChangeStatus from "../../Modals/People/changeStatus";

interface Props {
  idList: number[];
  unselectAll: () => void;
}

const MenuPeopleTableActions = ({ idList, unselectAll }: Props): JSX.Element => {
  const l = useLanguage();
  const isAdmin = useSelector(selectorIsAdmin);
  const [branchModalVisible, setBranchModalVisible] = useState(false);
  const [brandModalVisible, setBrandModalVisible] = useState(false);
  const [planModalVisible, setPlanModalVisible] = useState(false);
  const [statusModalVisible, setStatusModalVisible] = useState(false);
  //const [accountTypeModalVisible, setAccountTypeModalVisible] = useState(false);
  const [reportModalVisible, setReportModalVisible] = useState(false);
  const [roleModalVisible, setRoleModalVisible] = useState(false);
  const [groupModalVisible, setGroupModalVisible] = useState(false);
  const [licenseModalVisible, setLicenseModalVisible] = useState(false);
  const [activationModalVisible, setActivationModalVisible] = useState(false);

  const menu = (idList: number[]): JSX.Element => {
    return (
      <Menu>
        <Menu.Item onClick={(): void => setBrandModalVisible(true)}>
          <UIText>{l("editBrand")}</UIText>
        </Menu.Item>
        <Menu.Item onClick={(): void => setBranchModalVisible(true)}>
          <UIText>{l("editBranch")}</UIText>
        </Menu.Item>
        {isAdmin && (
          <Menu.Item onClick={(): void => setPlanModalVisible(true)}>
            <UIText>{l("editPlan")}</UIText>
          </Menu.Item>
        )}
        <Menu.Item onClick={(): void => setStatusModalVisible(true)}>
          <UIText>{l("editStatus")}</UIText>
        </Menu.Item>
        {/* <Menu.Item>
          <UIText onClick={(): void => setAccountTypeModalVisible(true)}>
            {l("editAccountType")}
          </UIText>
        </Menu.Item> */}
        {isAdmin && (
          <Menu.Item onClick={(): void => setReportModalVisible(true)}>
            <UIText>{l("editReportUsageLimit")}</UIText>
          </Menu.Item>
        )}
        <Menu.Item onClick={(): void => setRoleModalVisible(true)}>
          <UIText>{l("editRole")}</UIText>
        </Menu.Item>
        {isAdmin && (
          <Menu.Item onClick={(): void => setGroupModalVisible(true)}>
            <UIText>{l("editGroup")}</UIText>
          </Menu.Item>
        )}
        <Menu.Item onClick={(): void => setLicenseModalVisible(true)}>
          <UIText>{l("editLicense")}</UIText>
        </Menu.Item>
        <Menu.Item onClick={(): void => setActivationModalVisible(true)}>
          <UIText>{l("sendActivationMail")}</UIText>
        </Menu.Item>
      </Menu>
    );
  };
  return (
    <>
      <Dropdown overlay={(): JSX.Element => menu(idList)} placement="bottomLeft">
        <UIButton style={{ color: "#000", marginLeft: "10px" }}>Actions</UIButton>
      </Dropdown>
      {branchModalVisible && (
        <ModalChangeBranch
          visible={branchModalVisible}
          people={idList}
          handleOk={(): void => {
            setBranchModalVisible(false);
            unselectAll();
          }}
          handleCancel={(): void => setBranchModalVisible(false)}
        />
      )}
      {brandModalVisible && (
        <ModalChangeBrand
          visible={brandModalVisible}
          people={idList}
          handleOk={(): void => {
            setBrandModalVisible(false);
            unselectAll();
          }}
          handleCancel={(): void => setBrandModalVisible(false)}
        />
      )}
      {planModalVisible && (
        <ModalChangePlan
          visible={planModalVisible}
          people={idList}
          handleOk={(): void => {
            setPlanModalVisible(false);
            unselectAll();
          }}
          handleCancel={(): void => setPlanModalVisible(false)}
        />
      )}
      {statusModalVisible && (
        <ModalChangeStatus
          visible={statusModalVisible}
          people={idList}
          handleOk={(): void => {
            setStatusModalVisible(false);
            unselectAll();
          }}
          handleCancel={(): void => setStatusModalVisible(false)}
        />
      )}
      {/* {accountTypeModalVisible && (
        <ModalChangeAccountType
          visible={accountTypeModalVisible}
          people={idList}
          handleOk={(): void => {
            setAccountTypeModalVisible(false);
            unselectAll();
          }}
          handleCancel={(): void => setAccountTypeModalVisible(false)}
        />
      )} */}
      {reportModalVisible && (
        <ModalChangeReport
          visible={reportModalVisible}
          people={idList}
          handleOk={(): void => {
            setReportModalVisible(false);
            unselectAll();
          }}
          handleCancel={(): void => setReportModalVisible(false)}
        />
      )}
      {roleModalVisible && (
        <ModalChangeRole
          visible={roleModalVisible}
          people={idList}
          handleOk={(): void => {
            setRoleModalVisible(false);
            unselectAll();
          }}
          handleCancel={(): void => setRoleModalVisible(false)}
        />
      )}
      {groupModalVisible && (
        <ModalChangeGroup
          visible={groupModalVisible}
          people={idList}
          handleOk={(): void => {
            setGroupModalVisible(false);
            unselectAll();
          }}
          handleCancel={(): void => setGroupModalVisible(false)}
        />
      )}
      {licenseModalVisible && (
        <ModalChangeLicense
          visible={licenseModalVisible}
          people={idList}
          handleOk={(): void => {
            setLicenseModalVisible(false);
            unselectAll();
          }}
          handleCancel={(): void => setLicenseModalVisible(false)}
        />
      )}
      {activationModalVisible && (
        <ModalChangeActivation
          visible={activationModalVisible}
          people={idList}
          handleOk={(): void => {
            setActivationModalVisible(false);
            unselectAll();
          }}
          handleCancel={(): void => setActivationModalVisible(false)}
        />
      )}
    </>
  );
};
export default MenuPeopleTableActions;
