/** @format */

import { AxiosResponse, AxiosError } from "axios";
import { API_ENDPOINTS } from "../../constants/api";
import apiClient from "../../utils/apiClient";

export const apiAdministratorList = (): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .get(API_ENDPOINTS.ADMINISTRATOR_LIST)
    .then(response => ({ response }))
    .catch(error => ({ error }));
