/** @format */

import {
  TActionLicenseCreateFailure,
  TActionLicenseCreateRequest,
  TActionLicenseCreateSuccess,
  TActionLicenseDeleteFailure,
  TActionLicenseDeleteRequest,
  TActionLicenseDeleteSuccess,
  TActionLicenseListFailure,
  TActionLicenseListRequest,
  TActionLicenseListSuccess,
  TActionLicenseUpdateFailure,
  TActionLicenseUpdateRequest,
  TActionLicenseUpdateSuccess,
} from "../declarations/actions/licenses";
import { TActionType } from "../declarations/actionTypes";
import {
  TLicenseCreateRequest,
  TLicenseList,
  TLicenseUpdateRequest,
} from "../declarations/maps/license";

export const actionLicenseListRequest = (): TActionLicenseListRequest => ({
  type: TActionType.licenseListRequest,
});

export const actionLicenseListSuccess = (payload: TLicenseList): TActionLicenseListSuccess => ({
  type: TActionType.licenseListSuccess,
  payload,
});

export const actionLicenseListFailure = (payload: string): TActionLicenseListFailure => ({
  type: TActionType.licenseListFailure,
  reason: payload,
});

// Create

export const actionLicenseCreateRequest = (
  payload: TLicenseCreateRequest,
): TActionLicenseCreateRequest => ({
  type: TActionType.licenseCreateRequest,
  payload,
});

export const actionLicenseCreateSuccess = (): TActionLicenseCreateSuccess => ({
  type: TActionType.licenseCreateSuccess,
});

export const actionLicenseCreateFailure = (payload: string): TActionLicenseCreateFailure => ({
  type: TActionType.licenseCreateFailure,
  reason: payload,
});

// Update
export const actionLicenseUpdateRequest = (
  payload: TLicenseUpdateRequest,
): TActionLicenseUpdateRequest => ({
  type: TActionType.licenseUpdateRequest,
  payload,
});

export const actionLicenseUpdateSuccess = (): TActionLicenseUpdateSuccess => ({
  type: TActionType.licenseUpdateSuccess,
});

export const actionLicenseUpdateFailure = (payload: string): TActionLicenseUpdateFailure => ({
  type: TActionType.licenseUpdateFailure,
  reason: payload,
});

// Delete
export const actionLicenseDeleteRequest = (payload: number): TActionLicenseDeleteRequest => ({
  type: TActionType.licenseDeleteRequest,
  payload,
});

export const actionLicenseDeleteSuccess = (): TActionLicenseDeleteSuccess => ({
  type: TActionType.licenseDeleteSuccess,
});

export const actionLicenseDeleteFailure = (payload: string): TActionLicenseDeleteFailure => ({
  type: TActionType.licenseDeleteFailure,
  reason: payload,
});
