/** @format */

import { AxiosResponse, AxiosError } from "axios";
import { API_ENDPOINTS } from "../../constants/api";
import apiClient from "../../utils/apiClient";

interface Params {
  email: string;
  password: string;
}

export const apiLogin = (data: Params): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .post(API_ENDPOINTS.LOGIN, data)
    .then(response => {
      return { response };
    })
    .catch(error => ({ error }));

//FAKE LOGIN
/* export const apiLogin = (data: Params): TAPILoginSuccess => ({
  data: {
    message: "",
    token: 'xx',
    user: {
      uid: "1",
      token: "xxx",
      name: "Produzione",
      lastName: "Urios",
      mobile: "333000000",
      email: "produzione@infcom.it",
      profile_pic: "",
    },
  },
}); */
