/** @format */

import { watcherAccountType } from "./watcherAccountType";
import { watcherAdministrator } from "./watcherAdministrator";
import { watcherAuth } from "./watcherAuth";
import { watcherBranch } from "./watcherBranch";
import { watcherBrand } from "./watcherBrand";
import { watcherCache } from "./watcherCache";
import { watcherCustomer } from "./watcherCustomer";
import { watcherGroup } from "./watcherGroup";
import { watcherLicense } from "./watcherLicense";
import { watcherMassiveAction } from "./watcherMassivePeople";
import { watcherMassiveStoreAction } from "./watcherMassiveStore";
import { watcherPartner } from "./watcherPartner";
import { watcherPeople } from "./watcherPeople";
import { watcherRole } from "./watcherRole";
import { watcherStore } from "./watcherStore";
import { watcherTipology } from "./watcherTipology";

export const watchers = [
  watcherAuth,
  watcherCache,
  watcherCustomer,
  watcherGroup,
  watcherLicense,
  watcherTipology,
  watcherAccountType,
  watcherPeople,
  watcherBrand,
  watcherBranch,
  watcherAdministrator,
  watcherMassiveAction,
  watcherRole,
  watcherStore,
  watcherMassiveStoreAction,
  watcherPartner,
];
