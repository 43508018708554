/** @format */

import axios from "axios";
import { API_PARAMS } from "../constants/api";
import { utilityGetFromLocalStorage } from "./cache/getFromLocalStorage";
import { utilityRemoveLocalStorage } from "./cache/removeFromLocalStorage";

const apiClient = axios.create({
  baseURL: API_PARAMS.BASE_URL,
  withCredentials: false,
  headers: {
    "Access-Control-Allow-Origin": "http://localhost:3000",
  },
});

apiClient.interceptors.request.use(config => {
  const token = utilityGetFromLocalStorage("apiKey");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

apiClient.interceptors.response.use(undefined, error => {
  if (!!error.response && (error.response.status === 401 || error.response.status === 419))
    utilityRemoveLocalStorage("apiKey");
  return Promise.reject(error);
});

export default apiClient;
