/** @format */

import {
  TActionBranchListSuccess,
  TActionBranchListReset,
  TActionBranchDeleteSuccess,
} from "../declarations/actions/branches";
import { TActionType } from "../declarations/actionTypes";
import { TBranchState } from "../declarations/maps/branch";

type Actions = TActionBranchListSuccess | TActionBranchListReset | TActionBranchDeleteSuccess;

const defaultState = {
  list: {
    1: {
      id: 1,
      name: "EA",
      customer_id: 1,
      canDelete: false,
    },
    2: {
      id: 2,
      name: "GA",
      customer_id: 1,
      canDelete: false,
    },
  },
};

export const reducerBranch = (
  state: TBranchState = defaultState,
  action: Actions,
): TBranchState => {
  switch (action.type) {
    case TActionType.branchListSuccess:
      return { list: { ...action.payload } };
    case TActionType.branchListReset:
      return defaultState;
    case TActionType.branchDeleteSuccess:
      const { [action.payload]: _, ...newState } = state.list;
      return { list: newState };
    default:
      return state;
  }
};
