/** @format */

import { UIFormItemDragger, UIText } from "@rstore-react-packages/template-ui";
import { Button } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import { InboxOutlined, DownloadOutlined } from "@ant-design/icons";
import StyledModal from "../../../ui/Modal";

/** @format */
interface Props {
  visible: boolean;
  handleOk: (file: File, name: string) => void;
  handleCancel: () => void;
}
const ModalImportStore = ({ visible = false, handleCancel, handleOk }: Props): JSX.Element => {
  const l = useLanguage();
  const [selected, setSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedFileName, setSelectedFileName] = useState("");

  const handleUpload = (files): void => {
    if (!!files.length) {
      const selectedFile = { ...files[0].originFileObj };
      const fileName = files[0].originFileObj.name;
      const reader = new FileReader();
      reader.readAsDataURL(files[0].originFileObj);
      //@ts-ignore
      reader.onload = (): void => setSelectedFile(reader.result);

      setSelected(true);
      setSelectedFile(selectedFile);
      setSelectedFileName(fileName);
    } else {
      setSelected(false);
    }
  };

  const beforeUpload = (file, files): void => console.log(file);

  const handleFinish = (): void => {
    //@ts-ignore
    handleOk(selectedFile, selectedFileName);
  };

  return (
    <StyledModal
      title={l("importStore")}
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Return
        </Button>,
        <Button key="submit" onClick={handleFinish}>
          Submit
        </Button>,
        <Button
          key="link"
          target="__blank"
          href={process.env.PUBLIC_URL + "/example_import_stores.csv"}
          style={{ float: "left" }}
        >
          <DownloadOutlined />
          Facsimile
        </Button>,
      ]}
    >
      <StyledDragger disabled={selected}>
        <UIFormItemDragger
          onChange={handleUpload}
          onBeforeUpload={beforeUpload}
          multiple={false}
          accept=".csv"
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <UIText>{l("importPeopleText")}</UIText>
        </UIFormItemDragger>
      </StyledDragger>
    </StyledModal>
  );
};
interface ext {
  disabled: boolean;
}

const StyledDragger = styled.div<ext>`
  cursor: ${(props): string => {
      return !!props.disabled ? "not-allowed!important;" : "auto;";
    }}
    .ant-upload.ant-upload-drag {
    pointer-events: ${(props): string => {
      return !!props.disabled ? "none!important;" : "inherit;";
    }};
  }
  .ant-upload-list ant-upload-list-text {
    cursor: auto;
  }
`;
export default ModalImportStore;
