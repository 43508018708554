/** @format */

import React from "react";
import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { selectorLanguage } from "../../../../redux-modules/selectors/selectorsUI";
import { UIImage } from "@rstore-react-packages/template-ui";
import GB from "../../../../assets/svg/gb.svg";
import IT from "../../../../assets/svg/it.svg";
import { DownOutlined } from "@ant-design/icons";
import { actionLanguageSet } from "../../../../redux-modules/actions/actionsUI";
import { TLanguage } from "../../../../redux-modules/declarations/maps/ui";
import { TActionSetLanguage } from "../../../../redux-modules/declarations/actions/ui";

const CustomMenu = styled(Menu)`
  float: right;
`;

const LangMenu = (): JSX.Element => {
  const dispatch = useDispatch();
  const selectedLang = useSelector(selectorLanguage);

  const switchLang = (lang: TLanguage): TActionSetLanguage => dispatch(actionLanguageSet(lang));

  return (
    <CustomMenu theme="dark" mode="horizontal">
      <SubMenu
        key="lang"
        title={
          <>
            {selectedLang === "en" ? (
              <UIImage src={GB} imgWidth="15px" objectFit="contain" />
            ) : (
              <UIImage src={IT} imgWidth="15px" objectFit="contain" />
            )}
            <span style={{ marginLeft: "5px" }}>{selectedLang?.toUpperCase()}</span>
            <DownOutlined style={{ marginLeft: "5px", fontSize: 12 }} />
          </>
        }
      >
        {selectedLang === "en" ? (
          <Menu.Item onClick={(): TActionSetLanguage => switchLang("it")}>
            <div>
              <UIImage src={IT} imgWidth="15px" objectFit="contain" />
              <span style={{ marginLeft: "5px" }}>IT</span>
            </div>
          </Menu.Item>
        ) : (
          <Menu.Item onClick={(): TActionSetLanguage => switchLang("en")}>
            <div>
              <UIImage src={GB} imgWidth="15px" objectFit="contain" />
              <span style={{ marginLeft: "5px" }}>EN</span>
            </div>
          </Menu.Item>
        )}
      </SubMenu>
    </CustomMenu>
  );
};

export default LangMenu;
