/** @format */

import { AxiosResponse, AxiosError } from "axios";
import { API_ENDPOINTS } from "../../constants/api";
import { TBrandAdminCreateRequest } from "../../redux-modules/declarations/maps/brand";
import apiClient from "../../utils/apiClient";

export const apiBrandAdminCreate = (
  data: TBrandAdminCreateRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .post(API_ENDPOINTS.BRAND_ADMIN, data)
    .then(response => ({ response }))
    .catch(error => ({ error }));

export const apiBrandAdminDelete = (
  data: TBrandAdminCreateRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .delete(
      API_ENDPOINTS.BRAND_ADMIN_DELETE.replace(":id_brand", `${data.brand_id}`).replace(
        ":id_person",
        `${data.person_id}`,
      ),
    )
    .then(response => ({ response }))
    .catch(error => ({ error }));
