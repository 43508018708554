/** @format */

import { UIAlert } from "@rstore-react-packages/template-ui";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionLogoutRequest } from "../actions/actionsAuth";
import { actionAlertRemove, actionStaticAlertRemove } from "../actions/actionsUI";
import { selectorAlert, selectorStaticAlert } from "../selectors/selectorsUI";

/*
Questo Hook gestisce qualsiasi errore in autonomia:
Ogni volta che il selectAlert restituisce un errore
(quindi ogni volta che si invoca actionAlertGenerate)
restituisce un JSX col messaggio di errore
e dopo il *timeout* invoca l'actionAlertRemove
rimuovendo anche il JSX

*/

type Return = JSX.Element;

export const useGenericAlert = (timeout: number): Return => {
  const [visible, setVisible] = useState(false);
  const [currentTimeout, setCurrentTimeout] = useState<number | null>(null);
  const dispatch = useDispatch();
  const alert = useSelector(selectorAlert);

  useEffect(() => {
    if (!!alert) setVisible(true);
    else setVisible(false);
  }, [alert]);

  useEffect(() => {
    if (currentTimeout) clearTimeout(currentTimeout);
    if (visible && !!alert) {
      window.scrollTo({ top: 0, behavior: "smooth" });

      const newTimeout = setTimeout(() => {
        if (alert.message === "Unauthenticated.") dispatch(actionLogoutRequest());
        setVisible(false);
        dispatch(actionAlertRemove());
      }, timeout);
      // @ts-ignore
      setCurrentTimeout(newTimeout);
    }
  }, [visible]);

  if (visible && !!alert)
    return (
      <Row justify="center" gutter={[16, 16]} style={{ marginBottom: "15px", marginTop: "15px" }}>
        <Col span={24}>
          <UIAlert
            message={alert?.message}
            description={alert?.description}
            type={alert?.type}
            style={{ whiteSpace: "pre-wrap" }}
            showIcon
          />
        </Col>
      </Row>
    );

  return <></>;
};

export const useGenericAlertClosable = (): Return => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const alert = useSelector(selectorStaticAlert);

  useEffect(() => {
    if (!!alert) setVisible(true);
    else setVisible(false);
  }, [alert]);

  useEffect(() => {
    if (visible && !!alert) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [visible]);

  const removeAlert = (): void => {
    setVisible(false);
    dispatch(actionStaticAlertRemove());
  };

  if (visible && !!alert)
    return (
      <Row justify="center" gutter={[16, 16]} style={{ marginBottom: "15px", marginTop: "15px" }}>
        <Col span={24}>
          <UIAlert
            message={alert?.message}
            description={alert?.description}
            type={alert?.type}
            style={{ whiteSpace: "pre-wrap" }}
            onClose={removeAlert}
            closable
            showIcon
          />
        </Col>
      </Row>
    );

  return <></>;
};
