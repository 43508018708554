/** @format */

import {
  TActionAdministratorListRequest,
  TActionAdministratorListSuccess,
  TActionAdministratorListFailure,
} from "../declarations/actions/people";
import { TActionType } from "../declarations/actionTypes";
import { TPeopleList } from "../declarations/maps/people";

export const actionAdministratorListRequest = (): TActionAdministratorListRequest => ({
  type: TActionType.administratorListRequest,
});

export const actionAdministratorListSuccess = (
  payload: TPeopleList,
): TActionAdministratorListSuccess => ({
  type: TActionType.administratorListSuccess,
  payload,
});

export const actionAdministratorListFailure = (
  payload: string,
): TActionAdministratorListFailure => ({
  type: TActionType.administratorListFailure,
  reason: payload,
});
