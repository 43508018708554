/** @format */

import { Menu } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { selectorIsAdmin } from "../../../../redux-modules/selectors/selectorsAuth";
import { selectorCustomerSelected } from "../../../../redux-modules/selectors/selectorsUI";
import { Routes } from "../../../../utils/Routes";

export const LayoutCustomerMenu = (): JSX.Element => {
  const history = useHistory();
  const isAdmin = useSelector(selectorIsAdmin);
  const isCustomerSelected = useSelector(selectorCustomerSelected);

  return (
    <StyledMenu
      style={{ borderBottom: "none" }}
      mode="horizontal"
      defaultSelectedKeys={[
        history.location.pathname === "/"
          ? "/customer"
          : "/" + history.location.pathname.split("/")[1],
      ]}
      selectedKeys={[
        history.location.pathname === "/"
          ? "/customer"
          : "/" + history.location.pathname.split("/")[1],
      ]}
    >
      <StyledMenuItem key="/customer">
        <NavLink to={Routes.customer.replace(":id", `${isCustomerSelected?.id}`)}>Overview</NavLink>
      </StyledMenuItem>
      {!!isCustomerSelected && !!isAdmin && (
        <>
          <StyledMenuItem key="/brands">
            <NavLink to={Routes.brands}>Brands</NavLink>
          </StyledMenuItem>
          <StyledMenuItem key="/branches">
            <NavLink to={Routes.branch}>Branches</NavLink>
          </StyledMenuItem>
          <StyledMenuItem key="/partners">
            <NavLink to={Routes.partners}>Partners</NavLink>
          </StyledMenuItem>
        </>
      )}
    </StyledMenu>
  );
};

const StyledMenu = styled(Menu)`
  background-color: #000;

  .ant-menu-horizontal {
    border-color: #000000 !important;
  }
  .ant-menu-item-selected {
    color: #000 !important;
  }
  .ant-menu-item:hover {
    color: #000 !important;
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  margin-left: 0 !important;
  margin-right: 30px !important;
  .ant-menu-item-selected {
    color: #000 !important;
  }
  .ant-menu-item:hover {
    color: #000 !important;
  }
`;
