/** @format */

import React from "react";
import { ColumnsType } from "antd/lib/table";
import { NavLink } from "react-router-dom";
import { Routes } from "../../utils/Routes";

const storeGroupListColumns: ColumnsType<object> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    // eslint-disable-next-line react/display-name
    render: (e, r, i): JSX.Element => <LinkTo text={e} row={r} index={i} />,
  },
];

//@ts-ignore
const LinkTo = ({ text, row, index }): JSX.Element => {
  return (
    <NavLink key={row.id} to={Routes.brand.replace(":id", row.id)} style={{ color: "#0e7cdc" }}>
      {row.name}
    </NavLink>
  );
};

export default storeGroupListColumns;
