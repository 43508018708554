/** @format */

import { UIRow, UICol } from "@rstore-react-packages/template-ui";
import { Button, ConfigProvider, Form } from "antd";
import React from "react";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";

import en_GB from "antd/lib/locale-provider/en_GB";
import moment from "moment";
import "moment/locale/en-gb";
import { useDispatch } from "react-redux";
import { actionMassivePeoplePlanRequest } from "../../../../redux-modules/actions/actionsGeneral";
import StyledDatePicker from "../../../ui/DatePicker";
import StyledModal from "../../../ui/Modal";
moment.locale("en-gb", { week: { dow: 1, doy: 4 } });

/** @format */
interface Props {
  visible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  people: number[];
}
const ModalChangePlan = ({
  visible = false,
  handleCancel,
  handleOk,
  people,
}: Props): JSX.Element => {
  const l = useLanguage();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleFinish = (form): void => {
    dispatch(
      actionMassivePeoplePlanRequest({
        users_ids: people,
        action: "plan",
        starting: moment(form.starting).format("YYYY-MM-DD"),
        ending: moment(form.ending).format("YYYY-MM-DD"),
      }),
    );
    //@ts-ignore
    handleOk();
  };

  return (
    <StyledModal
      title={l("editPlan")}
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Return
        </Button>,
        <Button key="submit" onClick={(): void => form.submit()}>
          Submit
        </Button>,
      ]}
    >
      <UIRow>
        <UICol xs={24}>
          <Form form={form} name="edit-people-plan" layout="horizontal" onFinish={handleFinish}>
            <ConfigProvider locale={en_GB}>
              <Form.Item
                name="starting"
                label={"You are editing " + people.length + "'s people plan with starting:"}
              >
                <StyledDatePicker format={"DD/MM/YYYY"} />
              </Form.Item>
              <Form.Item name="ending" label={"and ending:"}>
                <StyledDatePicker format={"DD/MM/YYYY"} />
              </Form.Item>
            </ConfigProvider>
          </Form>
        </UICol>
      </UIRow>
    </StyledModal>
  );
};
export default ModalChangePlan;
