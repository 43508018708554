/** @format */

import { TBrand, TBrandForSelect } from "../declarations/maps/brand";
import { TStore } from "../declarations/store";

export const selectorBrandList = (store: TStore, customerId: number | false = false): TBrand[] => {
  return !!store.auth.isAdmin
    ? customerId
      ? Object.values(store.brand.list).filter(el => el.customer_id == customerId)
      : Object.values(store.brand.list) || []
    : Object.values(store.brand.list).filter(
        brand =>
          //@ts-ignore
          !!Object.values(brand.administrators).find(amm => amm.person_id == store.auth.user?.id),
      ) || [];
};

export const selectorBrand = (store: TStore, brandId: string): TBrand | null =>
  store.brand.list[brandId] || null;

export const selectorBrandListForSelect = (store: TStore): TBrandForSelect[] => {
  const brandList = Object.values(store.brand.list);
  if (!!!brandList) return [];

  return brandList.map(el => {
    const newEl = (({ ...o }): TBrandForSelect => ({
      ...o,
      key: o.id,
      value: o.id,
      description: o.name,
    }))(el);

    return newEl;
  });
};
