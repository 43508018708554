/** @format */

import { UIRow, UICol } from "@rstore-react-packages/template-ui";
import { Button, Form, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionMassivePeopleGroupRequest } from "../../../../redux-modules/actions/actionsGeneral";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import { selectorGroupListForSelect } from "../../../../redux-modules/selectors/selectorsGroup";
import StyledModal from "../../../ui/Modal";
const { Option } = Select;

/** @format */
interface Props {
  visible: boolean;
  handleOk?: () => void;
  handleCancel?: () => void;
  people: number[];
}
const ModalChangeGroup = ({
  visible = false,
  handleCancel,
  handleOk,
  people,
}: Props): JSX.Element => {
  const l = useLanguage();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const groups = useSelector(selectorGroupListForSelect);

  const handleFinish = (form): void => {
    dispatch(
      actionMassivePeopleGroupRequest({
        users_ids: people,
        action: "changeGroup",
        group_id: form.group_id,
      }),
    );
    //@ts-ignore
    handleOk && handleOk();
  };

  const handleReturn = (): void => {
    handleCancel && handleCancel();
  };

  return (
    <StyledModal
      title={l("editGroup")}
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleReturn}>
          Return
        </Button>,
        <Button key="submit" onClick={(): void => form.submit()}>
          Submit
        </Button>,
      ]}
    >
      <UIRow>
        <UICol xs={24}>
          <Form form={form} name="edit-people-group" layout="horizontal" onFinish={handleFinish}>
            <Form.Item
              name="group_id"
              label={"You are editing " + people.length + "'s people group with:"}
            >
              <Select
                optionFilterProp="label"
                showSearch
                filterOption={(input, option): boolean =>
                  //@ts-ignore
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {groups.map(group => (
                  <Option value={group.value} key={group.key}>
                    {group.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </UICol>
      </UIRow>
    </StyledModal>
  );
};
export default ModalChangeGroup;
