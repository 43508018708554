/** @format */

import React, { FC, useState } from "react";
import { UIRow, UICol } from "@rstore-react-packages/template-ui";
import { useSelector } from "react-redux";
import brandListColumns from "../../../../constants/tables/brandListColumns";
import { Input, Table } from "antd";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import styled from "styled-components";
import { TStore } from "../../../../redux-modules/declarations/store";
import { selectorBrandList } from "../../../../redux-modules/selectors/selectorsBrand";
import { TBrand } from "../../../../redux-modules/declarations/maps/brand";
import storeGroupListColumns from "../../../../constants/tables/storeGroupListColumns";

interface Props {
  filteredByCustomer?: number | false;
  withLinks?: boolean;
}

const LayoutBrandsTable: FC<Props> = ({
  filteredByCustomer = false,
  withLinks = false,
}: Props): JSX.Element => {
  //const dispatch = useDispatch();
  const l = useLanguage();
  const [filteredTable, setFilteredTable] = useState<TBrand[] | null>(null);
  const formattedBrandList = useSelector((store: TStore) =>
    selectorBrandList(store, filteredByCustomer),
  ).map(el => ({
    ...el,
    key: el.id,
  }));

  const handleSearch = (value: string): void => {
    const filterTable = formattedBrandList.filter(o =>
      Object.keys(o).some(k => String(o[k]).toLowerCase().includes(value.toLowerCase())),
    );

    setFilteredTable(filterTable);
  };
  return (
    <>
      <UIRow justify="end" gutter={[16, 16]}>
        <UICol>
          <StyledUISearchInput placeholder={l("searchPlaceholder")} onSearch={handleSearch} />
        </UICol>
      </UIRow>
      <UIRow style={{ marginTop: "10px" }} gutter={[16, 16]}>
        <UICol span={24}>
          <Table
            size="middle"
            columns={withLinks ? storeGroupListColumns : brandListColumns}
            dataSource={filteredTable || formattedBrandList}
          />
        </UICol>
      </UIRow>
    </>
  );
};

const StyledUISearchInput = styled(Input.Search)`
  .anticon {
    color: #000 !important;
  }
`;

export default LayoutBrandsTable;
