/** @format */

import { DatePicker } from "antd";
import styled from "styled-components";

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

export default StyledDatePicker;
