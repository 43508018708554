/** @format */

import { AxiosError, AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../../constants/api";
import { TTStoreUpdateRequest } from "../../redux-modules/declarations/maps/store";
import apiClient from "../../utils/apiClient";

export const apiStoreUpdate = (
  data: TTStoreUpdateRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .put(API_ENDPOINTS.STORE_DETAIL.replace(":id", `${data.id}`), data)
    .then(response => ({ response }))
    .catch(error => ({ error }));
