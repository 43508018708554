/** @format */

import { AxiosError, AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { apiGroupCreate } from "../../api/group/apiGroupCreate";
import { apiGroupUpdate } from "../../api/group/apiGroupUpdate";
import { apiGroupList } from "../../api/group/apiGroupList";
import { arrayToObject } from "../../utils/format/arrayToObject";
import {
  actionGroupAdminCreateFailure,
  actionGroupAdminCreateSuccess,
  actionGroupAdminDeleteFailure,
  actionGroupAdminDeleteSuccess,
  actionGroupCreateFailure,
  actionGroupCreateSuccess,
  actionGroupListFailure,
  actionGroupListRequest,
  actionGroupListSuccess,
  actionGroupUpdateFailure,
  actionGroupUpdateSuccess,
} from "../actions/actionsGroup";
import {
  actionAlertGenerate,
  actionGroupListLoadingStart,
  actionGroupListLoadingStop,
} from "../actions/actionsUI";
import {
  TActionGroupAdminCreateRequest,
  TActionGroupAdminDeleteRequest,
  TActionGroupCreateRequest,
  TActionGroupListRequest,
  TActionGroupUpdateRequest,
} from "../declarations/actions/groups";
import { TAPIValidationError } from "../declarations/apis/general";
import { apiGroupAdminCreate, apiGroupAdminDelete } from "../../api/group/apiGroupAdmin";

export function* sagaGroupList(action: TActionGroupListRequest): unknown {
  yield put(actionGroupListLoadingStart());

  try {
    const response = yield call(apiGroupList);
    const data = response?.data;

    //@ts-ignore
    yield put(actionGroupListSuccess(arrayToObject(data.data, "id")));
    yield put(actionGroupListLoadingStop());
  } catch (e) {
    yield put(
      //@ts-ignore
      actionGroupListFailure((e.response?.data?.errors as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        description: e?.toString() || "",
      }),
    );
  }
}

export function* sagaGroupCreate(action: TActionGroupCreateRequest): unknown {
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiGroupCreate, action.payload);

  if (!error && response) {
    console.log("data senza errore", response.data);
    yield put(actionGroupCreateSuccess());
    //@ts-ignore
    yield put(actionGroupListRequest());
  } else {
    yield put(
      //@ts-ignore
      actionGroupCreateFailure((error?.response?.data?.message as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}

export function* sagaGroupUpdate(action: TActionGroupUpdateRequest): unknown {
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiGroupUpdate, action.payload);

  if (!error && response) {
    yield put(actionGroupUpdateSuccess());
    yield put(actionGroupListRequest());
  } else {
    yield put(
      //@ts-ignore
      actionGroupUpdateFailure((error?.response?.data?.message as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}

export function* sagaGroupAdminCreate(action: TActionGroupAdminCreateRequest): unknown {
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiGroupAdminCreate, action.payload);

  if (!error && response) {
    console.log("data senza errore", response.data);
    yield put(actionGroupAdminCreateSuccess());
    //@ts-ignore
    yield put(actionGroupListRequest());
  } else {
    yield put(
      //@ts-ignore
      actionGroupAdminCreateFailure((error?.response?.data?.message as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}

export function* sagaGroupAdminDelete(action: TActionGroupAdminDeleteRequest): unknown {
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiGroupAdminDelete, action.payload);

  if (!error && response) {
    console.log("data senza errore", response.data);
    yield put(actionGroupAdminDeleteSuccess());
    //@ts-ignore
    yield put(actionGroupListRequest());
  } else {
    yield put(
      //@ts-ignore
      actionGroupAdminDeleteFailure((error?.response?.data?.message as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}
