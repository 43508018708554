/** @format */

import { AxiosError, AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../../constants/api";
import apiClient from "../../utils/apiClient";

export const apiPartnerDelete = (
  data: number,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .delete(API_ENDPOINTS.PARTNERS_DELETE.replace(":id", `${data}`))
    .then(response => ({ response }))
    .catch(error => ({ error }));
