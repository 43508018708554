/** @format */

import { AxiosResponse, AxiosError } from "axios";

import { call, put } from "redux-saga/effects";
import {
  apiMassiveStoreBranch,
  apiMassiveStoreBrand,
  apiMassiveStorePartner,
  apiMassiveStoreStatus,
} from "../../api/store/apiMassiveActions";
import {
  actionMassiveStoreBranchFailure,
  actionMassiveStoreBranchSuccess,
  actionMassiveStoreBrandFailure,
  actionMassiveStoreBrandSuccess,
  actionMassiveStorePartnerFailure,
  actionMassiveStorePartnerSuccess,
  actionMassiveStoreStatusFailure,
  actionMassiveStoreStatusSuccess,
  actionStoreListRequest,
} from "../actions/actionsStore";
import { actionAlertGenerate } from "../actions/actionsUI";
import {
  TActionMassiveStoreBranchRequest,
  TActionMassiveStoreBrandRequest,
  TActionMassiveStorePartnerRequest,
  TActionMassiveStoreStatusRequest,
} from "../declarations/actions/store";
import { TAPIValidationError } from "../declarations/apis/general";

export function* sagaMassiveStoreBrand(action: TActionMassiveStoreBrandRequest): unknown {
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiMassiveStoreBrand, action.payload);

  if (!error && response) {
    yield put(actionMassiveStoreBrandSuccess());
    yield put(actionStoreListRequest());
  } else {
    yield put(
      actionMassiveStoreBrandFailure(
        //@ts-ignore
        (error?.response?.data?.message as TAPIValidationError) || {},
      ),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}
export function* sagaMassiveStoreBranch(action: TActionMassiveStoreBranchRequest): unknown {
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiMassiveStoreBranch, action.payload);

  if (!error && response) {
    if (response.data.length > 0) {
      actionAlertGenerate({
        type: "warning",
        message: "Attention",
        description: `${response.data.map(er => er.person + ": " + er.description)}
        `,
      });
    }
    yield put(actionMassiveStoreBranchSuccess());
    yield put(actionStoreListRequest());
  } else {
    yield put(
      actionMassiveStoreBranchFailure(
        //@ts-ignore
        (error?.response?.data?.message as TAPIValidationError) || {},
      ),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}

export function* sagaMassiveStoreStatus(action: TActionMassiveStoreStatusRequest): unknown {
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiMassiveStoreStatus, action.payload);

  if (!error && response) {
    yield put(actionMassiveStoreStatusSuccess());
    yield put(actionStoreListRequest());
  } else {
    yield put(
      actionMassiveStoreStatusFailure(
        //@ts-ignore
        (error?.response?.data?.message as TAPIValidationError) || {},
      ),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}

export function* sagaMassiveStorePartner(action: TActionMassiveStorePartnerRequest): unknown {
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiMassiveStorePartner, action.payload);

  if (!error && response) {
    yield put(actionMassiveStorePartnerSuccess());
    yield put(actionStoreListRequest());
  } else {
    yield put(
      actionMassiveStorePartnerFailure(
        //@ts-ignore
        (error?.response?.data?.message as TAPIValidationError) || {},
      ),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}
