/** @format */

import { TUI } from "../declarations/maps/ui";
import { TActionType } from "../declarations/actionTypes";
import {
  TActionAlertGenerate,
  TActionAlertRemove,
  TActionAppLoadingFailure,
  TActionAppLoadingRequest,
  TActionAppLoadingSuccess,
  TActionSetLanguage,
  TActionCustomerSelect,
  TActionCustomerListLoadingStart,
  TActionCustomerListLoadingStop,
  TActionGroupListLoadingStop,
  TActionGroupListLoadingStart,
  TActionLicenseListLoadingStart,
  TActionLicenseListLoadingStop,
  TActionPeopleListLoadingStart,
  TActionPeopleListLoadingStop,
  TActionStoreListLoadingStart,
  TActionStoreListLoadingStop,
  TActionImportPeopleLoadingStart,
  TActionImportPeopleLoadingStop,
  TActionStaticAlertGenerate,
  TActionStaticAlertRemove,
  TActionPartnerListLoadingStart,
  TActionPartnerListLoadingStop,
} from "../declarations/actions/ui";
import { TActionLoginFailure, TActionLoginReset } from "../declarations/actions/auth";
import { TActionStoreTimestampSetRequest } from "../declarations/actions/general";

type Actions =
  | TActionLoginReset
  | TActionLoginFailure
  | TActionAppLoadingRequest
  | TActionAppLoadingSuccess
  | TActionAppLoadingFailure
  | TActionAlertGenerate
  | TActionAlertRemove
  | TActionSetLanguage
  | TActionCustomerSelect
  | TActionCustomerListLoadingStart
  | TActionCustomerListLoadingStop
  | TActionGroupListLoadingStart
  | TActionGroupListLoadingStop
  | TActionLicenseListLoadingStart
  | TActionLicenseListLoadingStop
  | TActionPeopleListLoadingStart
  | TActionPeopleListLoadingStop
  | TActionImportPeopleLoadingStart
  | TActionImportPeopleLoadingStop
  | TActionStoreListLoadingStart
  | TActionStoreListLoadingStop
  | TActionStoreTimestampSetRequest
  | TActionStaticAlertGenerate
  | TActionStaticAlertRemove
  | TActionPartnerListLoadingStart
  | TActionPartnerListLoadingStop;

const defaultState: TUI = {
  loaders: {
    customerList: false,
    groupList: false,
    licenseList: false,
    peopleList: false,
    importPeople: false,
    storeList: false,
    partnerList: false,
  },
  loading: false,
  lang: "en",
  timestamp: Date.now(),
};

export const reducerUI = (prevStore: TUI = defaultState, action: Actions): TUI => {
  switch (action.type) {
    case TActionType.loginFailure:
      return { ...prevStore, loginValidation: action.payload };
    case TActionType.loginReset:
      return { ...prevStore, loginValidation: {} };
    case TActionType.appLoadingRequest:
      return { ...prevStore, loading: true };
    case TActionType.appLoadingSuccess:
      return { ...prevStore, loading: false };
    case TActionType.appLoadingFailure:
      return { ...prevStore, loading: false };
    case TActionType.alertGenerate:
      return { ...prevStore, alert: action.payload };
    case TActionType.alertRemove:
      return { ...prevStore, alert: undefined };
    case TActionType.staticAlertGenerate:
      return { ...prevStore, staticAlert: action.payload };
    case TActionType.staticAlertRemove:
      return { ...prevStore, staticAlert: undefined };
    case TActionType.setLanguage:
      return { ...prevStore, lang: action.payload };
    case TActionType.customerSelect:
      return { ...prevStore, selectedCustomerId: action.payload };
    case TActionType.customerListLoadingStart:
      return { ...prevStore, loaders: { ...prevStore.loaders, customerList: true } };
    case TActionType.customerListLoadingStop:
      return { ...prevStore, loaders: { ...prevStore.loaders, customerList: false } };
    case TActionType.groupListLoadingStart:
      return { ...prevStore, loaders: { ...prevStore.loaders, groupList: true } };
    case TActionType.groupListLoadingStop:
      return { ...prevStore, loaders: { ...prevStore.loaders, groupList: false } };
    case TActionType.licenseListLoadingStart:
      return { ...prevStore, loaders: { ...prevStore.loaders, licenseList: true } };
    case TActionType.licenseListLoadingStop:
      return { ...prevStore, loaders: { ...prevStore.loaders, licenseList: false } };
    case TActionType.partnerListLoadingStart:
      return { ...prevStore, loaders: { ...prevStore.loaders, partnerList: true } };
    case TActionType.partnerListLoadingStop:
      return { ...prevStore, loaders: { ...prevStore.loaders, partnerList: false } };
    case TActionType.peopleListLoadingStart:
      return { ...prevStore, loaders: { ...prevStore.loaders, peopleList: true } };
    case TActionType.peopleListLoadingStop:
      return { ...prevStore, loaders: { ...prevStore.loaders, peopleList: false } };
    case TActionType.storeListLoadingStart:
      return { ...prevStore, loaders: { ...prevStore.loaders, storeList: true } };
    case TActionType.storeListLoadingStop:
      return { ...prevStore, loaders: { ...prevStore.loaders, storeList: false } };
    case TActionType.importPeopleLoadingStart:
      return { ...prevStore, loaders: { ...prevStore.loaders, importPeople: true } };
    case TActionType.importPeopleLoadingStop:
      return { ...prevStore, loaders: { ...prevStore.loaders, importPeople: false } };
    case TActionType.storeTimestampSetRequest:
      return { ...prevStore, timestamp: action.payload };
    default:
      return prevStore;
  }
};
