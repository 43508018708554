/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import {
  sagaMassivePeopleAccountType,
  sagaMassivePeopleActivation,
  sagaMassivePeopleBranch,
  sagaMassivePeopleBrand,
  sagaMassivePeopleGroup,
  sagaMassivePeopleLicense,
  sagaMassivePeoplePlan,
  sagaMassivePeopleReports,
  sagaMassivePeopleRole,
  sagaMassivePeopleStatus,
} from "../sagas/sagasMassivePeople";

export function* watcherMassiveAction(): unknown {
  yield takeLatest(TActionType.massivePeopleBrandRequest, sagaMassivePeopleBrand);
  yield takeLatest(TActionType.massivePeopleBranchRequest, sagaMassivePeopleBranch);
  yield takeLatest(TActionType.massivePeoplePlanRequest, sagaMassivePeoplePlan);
  yield takeLatest(TActionType.massivePeopleStatusRequest, sagaMassivePeopleStatus);
  yield takeLatest(TActionType.massivePeopleAccountTypeRequest, sagaMassivePeopleAccountType);
  yield takeLatest(TActionType.massivePeopleRoleRequest, sagaMassivePeopleRole);
  yield takeLatest(TActionType.massivePeopleReportsRequest, sagaMassivePeopleReports);
  yield takeLatest(TActionType.massivePeopleGroupRequest, sagaMassivePeopleGroup);
  yield takeLatest(TActionType.massivePeopleLicenseRequest, sagaMassivePeopleLicense);
  yield takeLatest(TActionType.massivePeopleActivationRequest, sagaMassivePeopleActivation);
}
