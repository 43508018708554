/** @format */

import { AxiosError, AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../../constants/api";
import {
  TMassiveAccountTypeRequest,
  TMassiveActivationRequest,
  TMassiveBranchRequest,
  TMassiveBrandRequest,
  TMassiveGroupRequest,
  TMassiveLicenseRequest,
  TMassivePlanRequest,
  TMassiveReportsRequest,
  TMassiveRoleRequest,
  TMassiveStatusRequest,
} from "../../redux-modules/declarations/maps/general";
import apiClient from "../../utils/apiClient";

export const apiMassivePeopleBranch = (
  data: TMassiveBranchRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .put(API_ENDPOINTS.PEOPLE_MASSIVE, data)
    .then(response => ({ response }))
    .catch(error => ({ error }));

export const apiMassivePeopleBrand = (
  data: TMassiveBrandRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .put(API_ENDPOINTS.PEOPLE_MASSIVE, data)
    .then(response => ({ response }))
    .catch(error => ({ error }));

export const apiMassivePeoplePlan = (
  data: TMassivePlanRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .put(API_ENDPOINTS.PEOPLE_MASSIVE, data)
    .then(response => ({ response }))
    .catch(error => ({ error }));

export const apiMassivePeopleStatus = (
  data: TMassiveStatusRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .put(API_ENDPOINTS.PEOPLE_MASSIVE, data)
    .then(response => ({ response }))
    .catch(error => ({ error }));

export const apiMassivePeopleAccountType = (
  data: TMassiveAccountTypeRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .put(API_ENDPOINTS.PEOPLE_MASSIVE, data)
    .then(response => ({ response }))
    .catch(error => ({ error }));

export const apiMassivePeopleRole = (
  data: TMassiveRoleRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .put(API_ENDPOINTS.PEOPLE_MASSIVE, data)
    .then(response => ({ response }))
    .catch(error => ({ error }));

export const apiMassivePeopleReports = (
  data: TMassiveReportsRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .put(API_ENDPOINTS.PEOPLE_MASSIVE, data)
    .then(response => ({ response }))
    .catch(error => ({ error }));

export const apiMassivePeopleGroup = (
  data: TMassiveGroupRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .put(API_ENDPOINTS.PEOPLE_MASSIVE, data)
    .then(response => ({ response }))
    .catch(error => ({ error }));

export const apiMassivePeopleLicense = (
  data: TMassiveLicenseRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .put(API_ENDPOINTS.PEOPLE_MASSIVE, data)
    .then(response => ({ response }))
    .catch(error => ({ error }));

export const apiMassivePeopleActivation = (
  data: TMassiveActivationRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .put(API_ENDPOINTS.PEOPLE_MASSIVE, data)
    .then(response => ({ response }))
    .catch(error => ({ error }));
