/** @format */

export enum Colors {
  primary = "#F7F6F1",
  primaryBorder = "rgb(247, 246, 241)",
  cream = "#F7F6F1",
  background = "rgb(247, 246, 241)",
  disabled = "rgb(181, 121, 115)",
  gray = "#F7F6F1",
  dark = "#292929",
  green = "#2BAC76",
  blue = "#2c79a2",
  red = "#E01E5A",
  bordeaux = "rgb(145, 44, 34)",
  yellow = "#F5A623",
  darkGray = "#525252",
  darkBlue = "#0081c2",
  darkBlueRGBA = "rgba(0, 121, 255, .25)",
  title = "rgb(247, 246, 241)",
  loadingSublabel = "#373737",
  subGroup = "#292929",
  grayLabel = "#7B7B78",
}
