/** @format */

import {
  UITransition,
  UIRow,
  UICol,
  UICard,
  UIInput,
  UITitle,
  UIButton,
  UISelect,
} from "@rstore-react-packages/template-ui";
import { ConfigProvider, Form, Select } from "antd";
import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionLicenseCreateRequest } from "../../../../redux-modules/actions/actionsLicense";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";

import en_GB from "antd/lib/locale-provider/en_GB";
import moment from "moment";
import "moment/locale/en-gb";
import { selectorAccountTypeListForTables } from "../../../../redux-modules/selectors/selectorsAccountType";
import StyledDatePicker from "../../../ui/DatePicker";
import { labelWithRequired } from "../../../../utils/format/labelWithRequired";
moment.locale("en-gb", { week: { dow: 1, doy: 4 } });

interface Props {
  groupId: number;
  onCreateCallback?: () => void;
}
const FormAddLicense: FC<Props> = ({ groupId, onCreateCallback }: Props): JSX.Element => {
  const l = useLanguage();
  const dispatch = useDispatch();
  const [isUnlimited, setIsUnlimited] = useState(false);

  const tipologies = useSelector(selectorAccountTypeListForTables);
  const handleFinish = (form): void => {
    form.group_id = groupId;
    form.report_usage_limit = !!isUnlimited ? "unlimited" : form.report_usage_limit;
    dispatch(actionLicenseCreateRequest(form));
    if (onCreateCallback) onCreateCallback();
  };
  return (
    <UITransition>
      <UIRow style={{ marginTop: "20px", marginBottom: "20px" }}>
        <UICol xs={24}>
          <UICard>
            <UIRow>
              <UICol xs={24} style={{ marginBottom: "5px" }}>
                <UITitle level={3}>{l("newLicense")}</UITitle>
              </UICol>
            </UIRow>
            <Form name="add-license" layout="vertical" onFinish={handleFinish} requiredMark={false}>
              <UIRow>
                <ConfigProvider locale={en_GB}>
                  <UICol xs={24} md={8}>
                    <Form.Item
                      name="start_date"
                      label={labelWithRequired(l("startDate"))}
                      rules={[
                        {
                          required: true,
                          message: "Please insert a starting date!",
                        },
                      ]}
                    >
                      <StyledDatePicker format={"DD/MM/YYYY"} />
                    </Form.Item>
                  </UICol>
                  <UICol xs={24} md={8} offset={1}>
                    <Form.Item
                      name="end_date"
                      label={labelWithRequired(l("endDate"))}
                      rules={[
                        {
                          required: true,
                          message: "Please insert an ending date!",
                        },
                      ]}
                    >
                      <StyledDatePicker format={"DD/MM/YYYY"} />
                    </Form.Item>
                  </UICol>
                </ConfigProvider>
              </UIRow>
              <UIRow>
                <UICol xs={24} md={8}>
                  <Form.Item
                    name="report_usage_limit"
                    label={labelWithRequired(l("reportUsageLimit"))}
                    rules={[
                      {
                        required: !!!isUnlimited,
                        message: "Please insert the report usage limit!",
                      },
                    ]}
                  >
                    <UIInput
                      type="number"
                      disabled={!!isUnlimited}
                      addonAfter={
                        <div
                          onClick={(): void => setIsUnlimited(!isUnlimited)}
                          style={{
                            cursor: "pointer",
                            textShadow: !!isUnlimited
                              ? "0 0 5px #017be4, 0 0 10px #017be4, 0 0 10px #017be4, 0 0 15px #017be4"
                              : "none",
                          }}
                        >
                          ∞
                        </div>
                      }
                    />
                  </Form.Item>
                </UICol>
                <UICol xs={24} md={8} offset={1}>
                  <Form.Item
                    name="people_usage_limit"
                    label={labelWithRequired(l("peopleUsageLimit"))}
                    rules={[{ required: true, message: "Please insert the people usage limit!" }]}
                  >
                    <UIInput />
                  </Form.Item>
                </UICol>
              </UIRow>
              <UIRow>
                <UICol xs={24} md={8}>
                  <Form.Item
                    name="account_type_id"
                    label={labelWithRequired(l("accountType"))}
                    rules={[
                      {
                        required: true,
                        message: "Please select an account type!",
                      },
                    ]}
                  >
                    <UISelect>
                      {tipologies.map(
                        (tipology): JSX.Element => (
                          <Select.Option key={tipology.value} value={`${tipology.value}`}>
                            {tipology.description}
                          </Select.Option>
                        ),
                      )}
                    </UISelect>
                  </Form.Item>
                </UICol>
              </UIRow>
              <UIRow>
                <UICol xs={24} md={8}>
                  <Form.Item>
                    <UIButton htmlType="submit" style={{ color: "#000" }}>
                      {l("save")}
                    </UIButton>
                  </Form.Item>
                </UICol>
              </UIRow>
            </Form>
          </UICard>
        </UICol>
      </UIRow>
    </UITransition>
  );
};

export default FormAddLicense;
