/** @format */

import {
  TActionBranchCreateFailure,
  TActionBranchCreateRequest,
  TActionBranchCreateSuccess,
  TActionBranchListFailure,
  TActionBranchListRequest,
  TActionBranchListSuccess,
  TActionBranchUpdateFailure,
  TActionBranchUpdateRequest,
  TActionBranchUpdateSuccess,
} from "../declarations/actions/branches";
import { TActionType } from "../declarations/actionTypes";
import {
  TBranchCreateRequest,
  TBranchList,
  TBranchUpdateRequest,
} from "../declarations/maps/branch";

export const actionBranchListRequest = (): TActionBranchListRequest => ({
  type: TActionType.branchListRequest,
});

export const actionBranchListSuccess = (payload: TBranchList): TActionBranchListSuccess => ({
  type: TActionType.branchListSuccess,
  payload,
});

export const actionBranchListFailure = (payload: string): TActionBranchListFailure => ({
  type: TActionType.branchListFailure,
  reason: payload,
});

// Create

export const actionBranchCreateRequest = (
  payload: TBranchCreateRequest,
): TActionBranchCreateRequest => ({
  type: TActionType.branchCreateRequest,
  payload,
});

export const actionBranchCreateSuccess = (): TActionBranchCreateSuccess => ({
  type: TActionType.branchCreateSuccess,
});

export const actionBranchCreateFailure = (payload: string): TActionBranchCreateFailure => ({
  type: TActionType.branchCreateFailure,
  reason: payload,
});

// Update
export const actionBranchUpdateRequest = (
  payload: TBranchUpdateRequest,
): TActionBranchUpdateRequest => ({
  type: TActionType.branchUpdateRequest,
  payload,
});

export const actionBranchUpdateSuccess = (): TActionBranchUpdateSuccess => ({
  type: TActionType.branchUpdateSuccess,
});

export const actionBranchUpdateFailure = (payload: string): TActionBranchUpdateFailure => ({
  type: TActionType.branchUpdateFailure,
  reason: payload,
});
