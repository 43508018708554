/** @format */

import { UIRow, UICol, UIText } from "@rstore-react-packages/template-ui";
import { Form, Select } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionBrandAdminCreateRequest,
  actionBrandAdminDeleteRequest,
  actionBrandUpdateRequest,
} from "../../../../redux-modules/actions/actionsBrand";
import { TBrand } from "../../../../redux-modules/declarations/maps/brand";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import { selectorIsAdmin } from "../../../../redux-modules/selectors/selectorsAuth";
import { selectorAdministratorListForSelect } from "../../../../redux-modules/selectors/selectorsPeople";
import { Colors } from "../../../../theme/maps/Colors";
import FormEditBrand from "../../Forms/Brand/editBrand";
const { Option } = Select;

interface Props {
  brand: TBrand | null;
}

const BrandInfoPanel = ({ brand }: Props): JSX.Element => {
  const l = useLanguage();
  const dispatch = useDispatch();
  const isAdmin = useSelector(selectorIsAdmin);
  const [isEditing, setIsEditing] = useState(false);
  const handleEditing = (): void => setIsEditing(!isEditing);
  const handleFinish = (form): void => {
    dispatch(actionBrandUpdateRequest({ ...brand, ...form }));
    handleEditing();
  };

  const administrators = useSelector(selectorAdministratorListForSelect);
  //@ts-ignore
  const [selectedAdministrators, setSelectedAdministrators] = useState(brand.administrators);

  const handleSelect = (val, opt): void => {
    //@ts-ignore
    if (val.length < selectedAdministrators.length) {
      const removed = selectedAdministrators?.find(x => !val.includes(x.person_id))?.person_id;
      dispatch(
        actionBrandAdminDeleteRequest({
          //@ts-ignore
          brand_id: brand.id,
          //@ts-ignore
          person_id: removed,
        }),
      );
    }
    //@ts-ignore
    if (val.length > selectedAdministrators?.length) {
      dispatch(
        actionBrandAdminCreateRequest({
          //@ts-ignore
          brand_id: brand.id,
          person_id: val[val.length - 1],
        }),
      );
    }
  };

  useEffect(() => {
    //@ts-ignore
    setSelectedAdministrators(brand.administrators);
    //@ts-ignore
  }, [brand.administrators]);

  if (!!!brand) return <></>;
  if (!!isEditing)
    return (
      <FormEditBrand brand={brand} onFinishCallback={handleFinish} onCloseForm={handleEditing} />
    );
  return (
    <>
      <UIRow style={{ marginTop: "10px", marginBottom: "30px" }}>
        <UICol xs={24} md={12}>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol style={{ display: "inline-flex" }}>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("name")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                {brand.name}
              </UIText>
            </UICol>
          </UIRow>
        </UICol>
        <UICol xs={{ span: 24, offset: 0 }} md={{ span: 11, offset: 1 }}>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol xs={24}>
              {isAdmin ? (
                <Form>
                  <Form.Item
                    label={l("administrators")}
                    name="admins"
                    initialValue={brand.administrators?.map(el => el.person_id)}
                  >
                    <Select
                      mode="multiple"
                      onChange={(val, opt): void => handleSelect(val, opt)}
                      filterOption={(input, option): boolean =>
                        option?.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      style={{ width: "100%" }}
                    >
                      {administrators.map(el => {
                        return (
                          <Option value={el.value} key={el.key}>
                            {el.name} {el.surname}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Form>
              ) : (
                <>
                  <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                    {l("administrators")}:
                  </UIText>
                  <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                    <Paragraph
                      style={{ display: "inline" }}
                      editable={!!!isAdmin ? false : { onStart: handleEditing }}
                    >
                      {`${brand.administrators?.map(
                        (el, i) =>
                          ` ${el.person.name} ${el.person.surname}${
                            //@ts-ignore
                            i !== brand.administrators.length - 1 ? "" : ""
                          }`,
                      )}`}
                    </Paragraph>
                  </UIText>
                </>
              )}

              {/* <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph style={{ display: "inline" }} editable={{ onStart: handleEditing }}>
                  {`${brand.administrators?.map(
                    (el, i) =>
                      ` ${el.person.name} ${el.person.surname}${
                        //@ts-ignore
                        i !== brand.administrators.length - 1 ? "" : ""
                      }`,
                  )}`}
                </Paragraph>
              </UIText> */}
            </UICol>
          </UIRow>
        </UICol>
      </UIRow>
    </>
  );
};

export default BrandInfoPanel;
