/** @format */

export const labelWithRequired = (str: string, direction: string = "right"): JSX.Element => {
  return (
    <>
      {str}
      <span style={{ color: "red", marginLeft: "5px" }}>*</span>
    </>
  );
};
