/** @format */

import { AxiosError, AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../../constants/api";
import { TPeopleUpdateRequest } from "../../redux-modules/declarations/maps/people";
import apiClient from "../../utils/apiClient";

export const apiPeopleUpdate = (
  data: TPeopleUpdateRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .put(API_ENDPOINTS.PEOPLE_DETAIL.replace(":id", `${data.id}`), data)
    .then(response => ({ response }))
    .catch(error => ({ error }));
