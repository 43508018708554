/** @format */

import React, { FC } from "react";
import { UIRow, UICol, UICard } from "@rstore-react-packages/template-ui";
import { Statistic } from "antd";
import { UserOutlined, FileProtectOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectorIsAdmin } from "../../../redux-modules/selectors/selectorsAuth";
import { TGroup } from "../../../redux-modules/declarations/maps/group";

interface Props {
  group: TGroup | null;
}

const LayoutGroupStatistics: FC<Props> = ({ group }: Props): JSX.Element => {
  const isAdmin = useSelector(selectorIsAdmin);

  if (!!!isAdmin) return <></>;
  if (!!!group)
    return (
      <UIRow justify="space-between">
        <UICol xs={11}>
          <UICard style={{ width: 230, marginTop: 16 }} loading></UICard>
        </UICol>
        <UICol xs={11}>
          <UICard style={{ width: 230, marginTop: 16 }} loading></UICard>
        </UICol>
      </UIRow>
    );
  return (
    <UIRow justify="space-between">
      <UICol xs={11}>
        <UICard>
          <Statistic
            title="People"
            value={group.people}
            valueStyle={{ color: "#3f8600" }}
            prefix={<UserOutlined />}
          />
        </UICard>
      </UICol>
      <UICol xs={11} offset={2}>
        <UICard>
          <Statistic
            title="Reports"
            value={
              `${group.reports}`.split("/")[0] !== "null" ? `${group.reports}`.split("/")[0] : 0
            }
            valueStyle={{ color: "#3f8600" }}
            prefix={<FileProtectOutlined />}
          />
        </UICard>
      </UICol>
    </UIRow>
  );
};

export default LayoutGroupStatistics;
