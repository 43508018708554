/** @format */

import { UIRow, UICol, UIInput, UIButton } from "@rstore-react-packages/template-ui";
import { Form, Select } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { TSTORE_STATUS_LIST } from "../../../../constants/general";
import { TTStore } from "../../../../redux-modules/declarations/maps/store";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import { selectorBranchListForSelect } from "../../../../redux-modules/selectors/selectorsBranch";
import { selectorBrandListForSelect } from "../../../../redux-modules/selectors/selectorsBrand";
import { selectorPartnerList } from "../../../../redux-modules/selectors/selectorsPartner";
import { labelWithRequired } from "../../../../utils/format/labelWithRequired";
const { Option } = Select;

interface Props {
  store: TTStore;
  onFinishCallback: (form) => void;
  onCloseForm: () => void;
}
const FormEditStore = ({ store, onFinishCallback, onCloseForm }: Props): JSX.Element => {
  const l = useLanguage();
  const branches = useSelector(selectorBranchListForSelect);
  const brands = useSelector(selectorBrandListForSelect);
  const partners = useSelector(selectorPartnerList);

  return (
    <Form
      name="edit-store"
      layout="vertical"
      onFinish={onFinishCallback}
      initialValues={store}
      requiredMark={false}
    >
      <UIRow style={{ marginTop: "10px" }}>
        <UICol xs={{ span: 24 }} md={{ span: 12 }}>
          <UIRow>
            <UICol xs={24}>
              <Form.Item
                name="name"
                label={labelWithRequired(l("name"))}
                rules={[
                  {
                    required: true,
                    message: "Please insert a name!",
                  },
                ]}
              >
                <UIInput />
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={24}>
              <Form.Item
                name="address"
                label={labelWithRequired(l("address"))}
                rules={[
                  {
                    required: true,
                    message: "Please insert an address!",
                  },
                ]}
              >
                <UIInput />
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={24}>
              <Form.Item
                name="branch_id"
                label={labelWithRequired(l("branch"))}
                initialValue={[store.branch.id]}
                rules={[
                  {
                    required: true,
                    message: "Please select a branch!",
                  },
                ]}
              >
                <Select
                  optionFilterProp="label"
                  showSearch
                  filterOption={(input, option): boolean =>
                    //@ts-ignore
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {branches.map(branch => (
                    <Option value={branch.value} key={branch.key}>
                      {branch.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={24}>
              <Form.Item
                name="brands_ids"
                label={labelWithRequired(l("brands"))}
                initialValue={Object.values(store.brands.map(el => el.id))}
                rules={[
                  {
                    required: true,
                    message: "Please select at least one brand!",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  optionFilterProp="label"
                  showSearch
                  filterOption={(input, option): boolean =>
                    //@ts-ignore
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {brands.map(brand => (
                    <Option value={brand.value} key={brand.key}>
                      {brand.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={24}>
              <Form.Item
                name="partner_id"
                label={labelWithRequired(l("partner"))}
                rules={[
                  {
                    required: true,
                    message: "Please select a partner!",
                  },
                ]}
                initialValue={[store.partner?.id]}
              >
                <Select>
                  {partners.map(part => (
                    <Option key={part.id} value={part.id}>
                      {part.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </UICol>
          </UIRow>
        </UICol>
        <UICol xs={{ span: 24, offset: 0 }} md={{ span: 12, offset: 0 }}>
          <UIRow>
            <UICol xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 1 }}>
              <Form.Item
                name="csHq"
                label={labelWithRequired("Cs Hq")}
                initialValue={[store.details?.data.find(el => el.key === "csHq")?.value]}
                rules={[
                  {
                    required: true,
                    message: "Please pick one!",
                  },
                ]}
              >
                <UIInput />
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 1 }}>
              <Form.Item
                name="amHq"
                label={labelWithRequired("Am Hq")}
                initialValue={[store.details?.data.find(el => el.key === "amHq")?.value]}
                rules={[
                  {
                    required: true,
                    message: "Please pick one!",
                  },
                ]}
              >
                <UIInput />
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 1 }}>
              <Form.Item
                name="contactList"
                label={labelWithRequired("Contact List")}
                initialValue={store.details?.data
                  .find(el => el.key === "contactList")
                  ?.value.split(";")}
                rules={[
                  {
                    required: true,
                    message: "Please pick one!",
                  },
                ]}
              >
                <Select mode="tags" style={{ width: "100%" }} tokenSeparators={[";"]}></Select>
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 1 }}>
              <Form.Item
                name="storeStatus"
                label={labelWithRequired("Store Status")}
                initialValue={[store.details?.data.find(el => el.key === "storeStatus")?.value]}
                rules={[
                  {
                    required: true,
                    message: "Please pick one!",
                  },
                ]}
              >
                <Select>
                  {Object.keys(TSTORE_STATUS_LIST).map(el => (
                    <Option key={el} value={el}>
                      {el}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 1 }}>
              <Form.Item
                name="gender"
                label={labelWithRequired("Gender")}
                initialValue={[store.details?.data.find(el => el.key === "gender")?.value]}
                rules={[
                  {
                    required: true,
                    message: "Please pick one!",
                  },
                ]}
              >
                <UIInput />
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 1 }}>
              <Form.Item
                name="line"
                label={labelWithRequired("Line")}
                initialValue={[store.details?.data.find(el => el.key === "line")?.value]}
                rules={[
                  {
                    required: true,
                    message: "Please pick one!",
                  },
                ]}
              >
                <UIInput />
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 1 }}>
              <Form.Item
                name="doorCode"
                label={labelWithRequired("Door Code")}
                initialValue={[store.details?.data.find(el => el.key === "doorCode")?.value]}
                rules={[
                  {
                    required: true,
                    message: "Please pick one!",
                  },
                ]}
              >
                <UIInput />
              </Form.Item>
            </UICol>
          </UIRow>
        </UICol>
      </UIRow>
      <UIRow>
        <UICol xs={24} md={12}>
          <UIRow>
            <UICol xs={24}>
              <UIRow justify="start">
                <UICol>
                  <UIButton style={{ color: "black" }} onClick={onCloseForm}>
                    {l("dismiss")}
                  </UIButton>
                </UICol>
                <UICol offset={1}>
                  <Form.Item>
                    <UIButton style={{ color: "black" }} htmlType="submit">
                      {l("save")}
                    </UIButton>
                  </Form.Item>
                </UICol>
              </UIRow>
            </UICol>
          </UIRow>
        </UICol>
      </UIRow>
    </Form>
  );
};

export default FormEditStore;
