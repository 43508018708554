/** @format */

import { TBranch, TBranchForSelect } from "../declarations/maps/branch";
import { TStore } from "../declarations/store";

export const selectorBranchList = (store: TStore, customerId: number | false = false): TBranch[] =>
  customerId
    ? Object.values(store.branch.list)
        .filter(el => el.customer_id == customerId)
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    : Object.values(store.branch.list).sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
      ) || [];

export const selectorBranch = (store: TStore, branchId: string): TBranch | null =>
  store.branch.list[branchId] || null;

export const selectorBranchListForSelect = (store: TStore): TBranchForSelect[] => {
  const branchList = Object.values(store.branch.list);
  if (!!!branchList) return [];

  return branchList.map(el => {
    const newEl = (({ ...o }): TBranchForSelect => ({
      ...o,
      key: o.id,
      value: o.id,
      description: o.name,
    }))(el);

    return newEl;
  });
};
