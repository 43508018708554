/** @format */

import { Form } from "antd";
import React, { useEffect } from "react";
import Logo from "../../../assets/svg/vuem-logo-white.svg";
import { useDispatch, useSelector } from "react-redux";
import { actionLoginRequest, actionLoginReset } from "../../../redux-modules/actions/actionsAuth";
import { UserOutlined, UnlockOutlined } from "@ant-design/icons";
import { selectorLoginValidation } from "../../../redux-modules/selectors/selectorsUI";
import { useAlert } from "../../../redux-modules/hooks/useAlert";
import {
  UICard,
  UITitle,
  UIAlert,
  UIInput,
  UIButton,
  UICol,
  UIRow,
  UIInputPassword,
  UIImage,
} from "@rstore-react-packages/template-ui";
import { useLanguage } from "../../../redux-modules/hooks/useLanguage";

export const ViewLogin = (): JSX.Element => {
  const validation = useSelector(selectorLoginValidation);
  const [visible, setVisible] = useAlert(6000);
  const dispatch = useDispatch();
  const l = useLanguage();
  const handleLogin = (loginObject: {}): void => {
    //@ts-ignore
    const authObj = { email: loginObject.email, password: loginObject.password };
    dispatch(actionLoginRequest(authObj));
  };

  useEffect(() => {
    return (): void => {
      dispatch(actionLoginReset());
    };
  }, []);

  useEffect(() => {
    if (!!Object.values(validation).length && !visible) setVisible(true);
  }, [validation]);

  useEffect(() => {
    if (!visible && !!Object.values(validation).length) dispatch(actionLoginReset());
  }, [visible]);

  return (
    <>
      <UIRow justify="center" style={{ marginTop: "35px", marginBottom: "15px" }}>
        <UICol>
          <UIImage imgWidth={"180px"} src={Logo} />
        </UICol>
      </UIRow>
      <UIRow justify="center" gutter={[16, 16]}>
        <UICol lg={8} sm={18} xs={24}>
          <UICard style={{ background: "#000", border: "none" }}>
            <UIRow>
              <UICol xs={12}>
                <UITitle level={2}>{l("logIn")}</UITitle>
              </UICol>
            </UIRow>
            {visible &&
              Object.values(validation)
                .reduce((list, current) => [...list, ...current], [])
                .map(current => (
                  <UIRow
                    key={current}
                    justify="center"
                    style={{ marginBottom: "10px" }}
                    gutter={[16, 16]}
                  >
                    <UICol span={24}>
                      <UIAlert
                        message="An error's occurred"
                        description={current}
                        type="error"
                        showIcon
                      />
                    </UICol>
                  </UIRow>
                ))}
            <Form
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              onFinish={handleLogin}
              onFinishFailed={(e): void => console.log(e)}
            >
              <Form.Item
                name="email"
                rules={[{ required: true, message: `${l("insertValidMail")}!` }]}
              >
                <UIInput placeholder="Email" prefix={<UserOutlined />} />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: `${l("insertValidPassword")}` }]}
              >
                <UIInputPassword placeholder="Password" prefix={<UnlockOutlined />} />
              </Form.Item>
              {/* <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>{l("rememberMe")}</Checkbox>
                </Form.Item>
              </Form.Item> */}
              <Form.Item wrapperCol={{ span: 24 }}>
                <UIButton htmlType="submit" style={{ color: "#000" }} block>
                  {l("logIn")}
                </UIButton>
              </Form.Item>
              {/* <Form.Item name="lostPassword" noStyle>
                <NavLink style={{ color: Colors.title }} to={Routes.forgotPassword}>
                  {l("forgotPassword")}?
                </NavLink>
              </Form.Item> */}
            </Form>
          </UICard>
        </UICol>
      </UIRow>
    </>
  );
};
