/** @format */

import { AxiosError, AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../../constants/api";
import { TLicenseCreateRequest } from "../../redux-modules/declarations/maps/license";
import apiClient from "../../utils/apiClient";

export const apiLicenseCreate = (
  data: TLicenseCreateRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .post(API_ENDPOINTS.LICENSE, data)
    .then(response => ({ response }))
    .catch(error => ({ error }));
