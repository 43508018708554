/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import {
  sagaBrandAdminCreate,
  sagaBrandAdminDelete,
  sagaBrandCreate,
  sagaBrandList,
  sagaBrandUpdate,
} from "../sagas/sagasBrand";

export function* watcherBrand(): unknown {
  yield takeLatest(TActionType.brandListRequest, sagaBrandList);
  yield takeLatest(TActionType.brandCreateRequest, sagaBrandCreate);
  yield takeLatest(TActionType.brandUpdateRequest, sagaBrandUpdate);
  yield takeLatest(TActionType.brandAdminCreateRequest, sagaBrandAdminCreate);
  yield takeLatest(TActionType.brandAdminDeleteRequest, sagaBrandAdminDelete);
}
