/** @format */

import {
  UIRow,
  UICol,
  UIInput,
  UIButton,
  UITransition,
  UITitle,
} from "@rstore-react-packages/template-ui";
import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TPeople } from "../../../../redux-modules/declarations/maps/people";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import { selectorIsAdmin } from "../../../../redux-modules/selectors/selectorsAuth";
import { selectorBranchListForSelect } from "../../../../redux-modules/selectors/selectorsBranch";
import { selectorBrandListForSelect } from "../../../../redux-modules/selectors/selectorsBrand";
import { selectorPartnerList } from "../../../../redux-modules/selectors/selectorsPartner";
import { selectorRoleList } from "../../../../redux-modules/selectors/selectorsRole";
import { labelWithRequired } from "../../../../utils/format/labelWithRequired";
const { Option } = Select;

interface Props {
  people: TPeople;
  onFinishCallback: (form) => void;
  onCloseForm: () => void;
}
const FormEditPeople = ({ people, onFinishCallback, onCloseForm }: Props): JSX.Element => {
  const l = useLanguage();
  const isAdmin = useSelector(selectorIsAdmin);
  const roles = useSelector(selectorRoleList);
  const branches = useSelector(selectorBranchListForSelect);
  const brands = useSelector(selectorBrandListForSelect);
  const partners = useSelector(selectorPartnerList);
  const [needsPartner, setNeedsPartner] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    console.log(people.role === "VM Partner");
    if (people.role === "VM Partner") setNeedsPartner(true);
    else setNeedsPartner(false);
  }, [people]);

  useEffect(() => {
    if (!!needsPartner) {
      form.setFieldsValue({ branch_id: null });
    }
  }, [needsPartner]);
  return (
    <Form
      name="edit-person"
      layout="vertical"
      form={form}
      onFinish={onFinishCallback}
      initialValues={people}
      requiredMark={false}
    >
      <UIRow style={{ marginTop: "10px" }}>
        <UICol xs={24} md={12}>
          <UIRow>
            <UICol xs={24}>
              <UITitle level={4}>Personal Info</UITitle>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={24}>
              <Form.Item
                name="name"
                label={labelWithRequired(l("name"))}
                rules={[
                  {
                    required: true,
                    message: "Please insert a name!",
                  },
                ]}
              >
                <UIInput />
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={24}>
              <Form.Item
                name="surname"
                label={labelWithRequired(l("surname"))}
                rules={[
                  {
                    required: true,
                    message: "Please insert a surname!",
                  },
                ]}
              >
                <UIInput />
              </Form.Item>
            </UICol>
          </UIRow>

          <UIRow>
            <UICol xs={24}>
              <Form.Item
                name="email"
                label={labelWithRequired(l("email"))}
                rules={[
                  {
                    required: true,
                    message: "Please insert an email!",
                  },
                ]}
              >
                <UIInput />
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={24}>
              <Form.Item
                name="mobile"
                label={l("mobile")}
                initialValue={people.details?.find(el => el.key === "mobile")?.value || ""}
                rules={[
                  {
                    required: false,
                    message: "Please insert a mobile!",
                  },
                ]}
              >
                <UIInput />
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={24}>
              <UITitle level={4}>Account Informations</UITitle>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={24}>
              <Form.Item
                name="role_id"
                label={labelWithRequired(l("role"))}
                initialValue={roles.find(el => el.name === people.role)?.id}
                rules={[
                  {
                    required: true,
                    message: "Please select a role!",
                  },
                ]}
              >
                <Select
                  onChange={(e): void => {
                    if (e === 9) setNeedsPartner(true);
                    else setNeedsPartner(false);
                  }}
                >
                  {!!isAdmin
                    ? roles.map(role => (
                        <Option key={role.id} value={role.id}>
                          {role.name}
                        </Option>
                      ))
                    : roles
                        .filter(e => e.id !== 1 && e.id !== 2 && e.id !== 3)
                        .map(role => (
                          <Option key={role.id} value={role.id}>
                            {role.name}
                          </Option>
                        ))}
                </Select>
              </Form.Item>
            </UICol>
          </UIRow>
          {!!needsPartner && (
            <UITransition>
              <UIRow>
                <UICol xs={24}>
                  <Form.Item
                    name="partner_id"
                    label={labelWithRequired(l("partner"))}
                    rules={[
                      {
                        required: true,
                        message: "Please select a partner!",
                      },
                    ]}
                    initialValue={[people.partner?.id]}
                  >
                    <Select>
                      {partners
                        .map(el => ({
                          ...el,
                          key: el.id,
                          name: el.name.toUpperCase(),
                        }))
                        .sort(function (a, b) {
                          if (a.name < b.name) {
                            return -1;
                          }
                          if (a.name > b.name) {
                            return 1;
                          }
                          return 0;
                        })
                        .map(part => (
                          <Option key={part.id} value={part.id}>
                            {part.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </UICol>
              </UIRow>
            </UITransition>
          )}
          <UIRow>
            <UICol xs={24}>
              <Form.Item
                name="nickname"
                label={l("nickname")}
                initialValue={people.details?.find(el => el.key === "nickname")?.value || ""}
              >
                <UIInput />
              </Form.Item>
            </UICol>
          </UIRow>
        </UICol>
        <UICol xs={24} md={11} offset={1}>
          <UIRow>
            <UICol xs={24}>
              <UITitle level={4}>Job Informations</UITitle>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={24}>
              <Form.Item
                name="branch_id"
                label={!!!needsPartner ? labelWithRequired(l("branch")) : l("branch")}
                rules={[
                  {
                    required: !!!needsPartner,
                    message: "Please select at least one branch!",
                  },
                ]}
                initialValue={people.branch?.id}
              >
                <Select
                  optionFilterProp="label"
                  showSearch
                  disabled={!!needsPartner}
                  filterOption={(input, option): boolean =>
                    //@ts-ignore
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {branches.map(branch => (
                    <Option value={branch.value} key={branch.key}>
                      {branch.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={24}>
              <Form.Item
                name="brands_ids"
                label={labelWithRequired(l("brands"))}
                initialValue={Object.values(people.brands.map(el => el.id))}
                rules={[
                  {
                    required: true,
                    message: "Please select at least one brand!",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  optionFilterProp="label"
                  showSearch
                  filterOption={(input, option): boolean =>
                    //@ts-ignore
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {brands.map(brand => (
                    <Option value={brand.value} key={brand.key}>
                      {brand.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </UICol>
          </UIRow>
          <UIRow>
            <UICol xs={24}>
              <Form.Item name="job_title" label={l("jobTitle")}>
                <UIInput />
              </Form.Item>
            </UICol>
          </UIRow>
        </UICol>
      </UIRow>
      <UIRow>
        <UICol xs={24} md={12}>
          <UIRow>
            <UICol xs={24}>
              <UIRow justify="start">
                <UICol>
                  <UIButton style={{ color: "black" }} onClick={onCloseForm}>
                    {l("dismiss")}
                  </UIButton>
                </UICol>
                <UICol offset={1}>
                  <Form.Item>
                    <UIButton style={{ color: "black" }} htmlType="submit">
                      {l("save")}
                    </UIButton>
                  </Form.Item>
                </UICol>
              </UIRow>
            </UICol>
          </UIRow>
        </UICol>
      </UIRow>
    </Form>
  );
};

export default FormEditPeople;
