/** @format */

import React, { FC, useEffect } from "react";
import { useGenericAlert } from "../../../redux-modules/hooks/useGenericAlert";
import { UICol, UIRow, UITitle, UITransition } from "@rstore-react-packages/template-ui";
import LayoutCustomerSelection from "../../layout/CustomerSelection";
import { useSelector } from "react-redux";
import {
  selectorIsAdmin,
  selectorUserCustomerId,
} from "../../../redux-modules/selectors/selectorsAuth";
import { useHistory } from "react-router";
import { Routes } from "../../../utils/Routes";

const ViewHome: FC = (): JSX.Element => {
  const Alert = useGenericAlert(6000);
  const history = useHistory();
  const isAdmin = useSelector(selectorIsAdmin);
  const userCustomerId = useSelector(selectorUserCustomerId);

  useEffect(() => {
    //@ts-ignore
    if (!!!isAdmin) history.push(Routes.customer.replace(":id", userCustomerId));
  }, [isAdmin]);

  return (
    <UITransition>
      {Alert}
      <UIRow style={{ marginTop: "30px" }}>
        <UICol>
          <UITitle>Homepage</UITitle>
        </UICol>
      </UIRow>
      <LayoutCustomerSelection />
    </UITransition>
  );
};

export default ViewHome;
