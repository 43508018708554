/** @format */

import { AxiosResponse, AxiosError } from "axios";
import { call, delay, put } from "redux-saga/effects";
import { apiPeopleCreate } from "../../api/people/apiPeopleCreate";
import { apiPeopleImport, apiPeopleImportPolling } from "../../api/people/apiPeopleImport";
import { apiPeopleList } from "../../api/people/apiPeopleList";
import { apiPeopleUpdate } from "../../api/people/apiPeopleUpdate";
import { arrayToObject } from "../../utils/format/arrayToObject";
import { actionGroupListRequest } from "../actions/actionsGroup";
import { actionLicenseListRequest } from "../actions/actionsLicense";
import {
  actionPeopleCreateFailure,
  actionPeopleCreateSuccess,
  actionPeopleImportFailure,
  actionPeopleListFailure,
  actionPeopleListRequest,
  actionPeopleListSuccess,
  actionPeoplePollingError,
  actionPeoplePollingStart,
  actionPeoplePollingStop,
  actionPeopleUpdateFailure,
  actionPeopleUpdateSuccess,
} from "../actions/actionsPeople";
import { actionStoreListRequest } from "../actions/actionsStore";
import {
  actionAlertGenerate,
  actionImportPeopleLoadingStart,
  actionImportPeopleLoadingStop,
  actionStaticAlertGenerate,
} from "../actions/actionsUI";
import {
  TActionPeopleCreateRequest,
  TActionPeopleImportPollingStart,
  TActionPeopleImportRequest,
  TActionPeopleListRequest,
  TActionPeopleUpdateRequest,
} from "../declarations/actions/people";
import { TAPIValidationError } from "../declarations/apis/general";

export function* sagaPeopleList(action: TActionPeopleListRequest): unknown {
  try {
    const response = yield call(apiPeopleList);
    const data = response?.data.data;
    //@ts-ignore
    yield put(actionPeopleListSuccess(arrayToObject(data, "id")));
  } catch (e) {
    yield put(
      //@ts-ignore
      actionPeopleListFailure((e?.response?.data?.errors as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        description: e?.response?.data?.errors?.toString() || "",
      }),
    );
  }
}

export function* sagaPeopleCreate(action: TActionPeopleCreateRequest): unknown {
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiPeopleCreate, action.payload);

  if (!error && response) {
    yield put(actionPeopleCreateSuccess());
    yield put(actionLicenseListRequest());
    yield put(actionGroupListRequest());
    yield put(
      actionAlertGenerate({
        type: "success",
        message: "Success",
        //@ts-ignore
        description: "Operation successfully completed!",
      }),
    );
    yield put(actionPeopleListRequest());
  } else {
    yield put(
      //@ts-ignore
      actionPeopleCreateFailure((error?.response?.data?.message as TAPIValidationError) || {}),
    );

    if (error?.response?.status == 422) {
      yield put(
        actionAlertGenerate({
          type: "error",
          message: "Error",
          //@ts-ignore
          description: Object.values(error?.response?.data?.errors)[0] || "",
        }),
      );
    } else {
      yield put(
        actionAlertGenerate({
          type: "error",
          message: "Error",
          //@ts-ignore
          description: error?.response?.data?.message || "",
        }),
      );
    }
  }
}

export function* sagaPeopleUpdate(action: TActionPeopleUpdateRequest): unknown {
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiPeopleUpdate, action.payload);

  if (!error && response) {
    yield put(actionPeopleUpdateSuccess());
    yield put(actionPeopleListRequest());
  } else {
    yield put(
      //@ts-ignore
      actionPeopleUpdateFailure((error?.response?.data?.message as TAPIValidationError) || {}),
    );

    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}

export function* sagaPeopleImport(action: TActionPeopleImportRequest): unknown {
  yield put(actionImportPeopleLoadingStart());
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiPeopleImport, action.payload);

  if (!error && response) {
    yield put(actionPeoplePollingStart(response.data.data.id));
  } else {
    yield put(
      //@ts-ignore
      actionPeopleImportFailure((error?.response?.data?.message as TAPIValidationError) || {}),
    );
    yield put(actionImportPeopleLoadingStop());

    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}

export function* pollTask(action: TActionPeopleImportPollingStart): unknown {
  let stillPolling = true;
  let counter = 0;
  while (stillPolling) {
    // Fetching posts at regular interval 4 seconds.
    const { response, error } = yield call(apiPeopleImportPolling, action.payload);

    if (!error && response) {
      if (response.data.data.status === "completed") {
        yield put(actionPeoplePollingStop());
        stillPolling = false;
        yield put(
          actionAlertGenerate({
            type: "success",
            message: "Success",
            //@ts-ignore
            description: "Operation successfully completed!",
          }),
        );
      }
      if (response.data.data.status === "error") {
        yield put(actionPeoplePollingError());
        stillPolling = false;
      }
    } else {
      yield put(actionPeoplePollingError());
      stillPolling = false;
    }

    if (!stillPolling) {
      yield put(actionImportPeopleLoadingStop());
      yield put(actionPeopleListRequest());
      yield put(actionStoreListRequest());
      yield put(actionLicenseListRequest());
      yield put(actionGroupListRequest());

      if (!!response.data.data.errors.length) {
        yield put(
          actionStaticAlertGenerate({
            type: "warning",
            message: "An Error's Occurred",
            description: `${response.data.data.errors
              .map(el => "[Row n. " + el.row + " ]: " + el.error)
              .join("\n")}`,
          }),
        );
      }
    }

    // dopo un minuto si interrompe
    if (counter > 20) {
      yield put(actionPeoplePollingError());
      stillPolling = false;
    }

    counter++;

    yield delay(3000);
  }
}
