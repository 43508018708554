/** @format */

import { Row, Col } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useState } from "react";
import { SettingOutlined } from "@ant-design/icons";
import ModalDeletePartner from "../../components/layout/Modals/Partner/deletePartner";

const partnerListColumns: ColumnsType<object> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    align: "center",
    title: <SettingOutlined />,
    dataIndex: "actions",
    key: "actions",
    //@ts-ignore
    // eslint-disable-next-line react/display-name
    render: (text, row, index): JSX.Element => (
      <ActionDropdown text={text} row={row} index={index} />
    ),
  },
];

//@ts-ignore
const ActionDropdown = ({ text, row, index }): JSX.Element => {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const closeDeleteModal = (): void => setDeleteModalVisible(false);
  return (
    <>
      {deleteModalVisible && (
        <ModalDeletePartner
          partner={row}
          handleCancel={closeDeleteModal}
          handleOk={closeDeleteModal}
          visible={deleteModalVisible}
        />
      )}
      <Row justify="center">
        <Col offset={2}>
          {!!row.canDelete && (
            <div
              onClick={(): void => setDeleteModalVisible(true)}
              style={{ cursor: "pointer", color: "#0e7cdc" }}
            >
              Delete
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default partnerListColumns;
