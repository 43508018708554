/** @format */

import { ColumnsType } from "antd/lib/table";

export const storeListColumns: ColumnsType<object> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Brands",
    dataIndex: "brands",
    key: "brands",
  },
  {
    title: "Branch",
    dataIndex: "branch",
    key: "branch",
  },
  {
    title: "Partner",
    dataIndex: "partner",
    key: "partner",
  },
  {
    title: "Gender",
    dataIndex: "gender",
    key: "gender",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  } /* ,
  {
    align: "center",
    title: <SettingOutlined />,
    dataIndex: "actions",
    key: "actions",
    //@ts-ignore
    // eslint-disable-next-line react/display-name
    render: (text, row, index): JSX.Element => (
      <ActionDropdown text={text} row={row} index={index} />
    ),
  }, */,
];

//@ts-ignore
/* const ActionDropdown = ({ text, row, index }): JSX.Element => {
  return (
    <Row gutter={[12, 0]}>
      <Col>
        {
          <NavLink to={Routes.storeDetail.replace(":id", row.id)} style={{ color: "#0e7cdc" }}>
            View
          </NavLink>
        }
      </Col>
    </Row>
  );
}; */
