/** @format */

import {
  TActionCacheSaveSuccess,
  TActionCacheSaveFailure,
  TActionCacheRemoveSuccess,
  TActionCacheRemoveFailure,
  TActionMassivePeopleBrandRequest,
  TActionMassivePeopleBrandFailure,
  TActionMassivePeopleBrandSuccess,
  TActionMassivePeopleBranchFailure,
  TActionMassivePeopleBranchRequest,
  TActionMassivePeopleBranchSuccess,
  TActionMassivePeoplePlanFailure,
  TActionMassivePeoplePlanRequest,
  TActionMassivePeoplePlanSuccess,
  TActionMassivePeopleStatusFailure,
  TActionMassivePeopleStatusRequest,
  TActionMassivePeopleStatusSuccess,
  TActionMassivePeopleAccountTypeFailure,
  TActionMassivePeopleAccountTypeRequest,
  TActionMassivePeopleAccountTypeSuccess,
  TActionMassivePeopleRoleFailure,
  TActionMassivePeopleRoleRequest,
  TActionMassivePeopleRoleSuccess,
  TActionMassivePeopleReportsFailure,
  TActionMassivePeopleReportsRequest,
  TActionMassivePeopleReportsSuccess,
  TActionMassivePeopleGroupFailure,
  TActionMassivePeopleGroupRequest,
  TActionMassivePeopleGroupSuccess,
  TActionMassivePeopleLicenseFailure,
  TActionMassivePeopleLicenseRequest,
  TActionMassivePeopleLicenseSuccess,
  TActionMassivePeopleActivationFailure,
  TActionMassivePeopleActivationRequest,
  TActionMassivePeopleActivationSuccess,
  TActionStoreTimestampSetFailure,
  TActionStoreTimestampSetRequest,
  TActionStoreTimestampSetSuccess,
  TActionStoreReloadFailure,
  TActionStoreReloadRequest,
  TActionStoreReloadSuccess,
} from "../declarations/actions/general";
import { TActionType } from "../declarations/actionTypes";
import {
  TMassiveAccountTypeRequest,
  TMassiveActivationRequest,
  TMassiveBranchRequest,
  TMassiveBrandRequest,
  TMassiveGroupRequest,
  TMassiveLicenseRequest,
  TMassivePlanRequest,
  TMassiveReportsRequest,
  TMassiveRoleRequest,
  TMassiveStatusRequest,
} from "../declarations/maps/general";
import { TStore } from "../declarations/store";

export const actionCacheSaveSuccess = (store: TStore): TActionCacheSaveSuccess => ({
  type: TActionType.cacheSaveSuccess,
  payload: store,
});

export const actionCacheSaveFailure = (): TActionCacheSaveFailure => ({
  type: TActionType.cacheSaveFailure,
});

export const actionCacheRemoveSuccess = (): TActionCacheRemoveSuccess => ({
  type: TActionType.cacheRemoveSuccess,
});

export const actionCacheRemoveFailure = (): TActionCacheRemoveFailure => ({
  type: TActionType.cacheRemoveFailure,
});

export const actionStoreTimestampSetRequest = (
  timestamp: number,
): TActionStoreTimestampSetRequest => ({
  type: TActionType.storeTimestampSetRequest,
  payload: timestamp,
});
export const actionStoreTimestampSetSuccess = (): TActionStoreTimestampSetSuccess => ({
  type: TActionType.storeTimestampSetSuccess,
});

export const actionStoreTimestampSetFailure = (): TActionStoreTimestampSetFailure => ({
  type: TActionType.storeTimestampSetFailure,
});

export const actionStoreReloadRequest = (): TActionStoreReloadRequest => ({
  type: TActionType.storeReloadRequest,
});
export const actionStoreReloadSuccess = (): TActionStoreReloadSuccess => ({
  type: TActionType.storeReloadSuccess,
});

export const actionStoreReloadFailure = (): TActionStoreReloadFailure => ({
  type: TActionType.storeReloadFailure,
});
// Massive People Brand
export const actionMassivePeopleBrandRequest = (
  payload: TMassiveBrandRequest,
): TActionMassivePeopleBrandRequest => ({
  type: TActionType.massivePeopleBrandRequest,
  payload,
});

export const actionMassivePeopleBrandSuccess = (): TActionMassivePeopleBrandSuccess => ({
  type: TActionType.massivePeopleBrandSuccess,
});

export const actionMassivePeopleBrandFailure = (
  payload: string,
): TActionMassivePeopleBrandFailure => ({
  type: TActionType.massivePeopleBrandFailure,
  reason: payload,
});

// Massive People Branch
export const actionMassivePeopleBranchRequest = (
  payload: TMassiveBranchRequest,
): TActionMassivePeopleBranchRequest => ({
  type: TActionType.massivePeopleBranchRequest,
  payload,
});

export const actionMassivePeopleBranchSuccess = (): TActionMassivePeopleBranchSuccess => ({
  type: TActionType.massivePeopleBranchSuccess,
});

export const actionMassivePeopleBranchFailure = (
  payload: string,
): TActionMassivePeopleBranchFailure => ({
  type: TActionType.massivePeopleBranchFailure,
  reason: payload,
});

// Massive People Plan
export const actionMassivePeoplePlanRequest = (
  payload: TMassivePlanRequest,
): TActionMassivePeoplePlanRequest => ({
  type: TActionType.massivePeoplePlanRequest,
  payload,
});

export const actionMassivePeoplePlanSuccess = (): TActionMassivePeoplePlanSuccess => ({
  type: TActionType.massivePeoplePlanSuccess,
});

export const actionMassivePeoplePlanFailure = (
  payload: string,
): TActionMassivePeoplePlanFailure => ({
  type: TActionType.massivePeoplePlanFailure,
  reason: payload,
});

// Massive People Status
export const actionMassivePeopleStatusRequest = (
  payload: TMassiveStatusRequest,
): TActionMassivePeopleStatusRequest => ({
  type: TActionType.massivePeopleStatusRequest,
  payload,
});

export const actionMassivePeopleStatusSuccess = (): TActionMassivePeopleStatusSuccess => ({
  type: TActionType.massivePeopleStatusSuccess,
});

export const actionMassivePeopleStatusFailure = (
  payload: string,
): TActionMassivePeopleStatusFailure => ({
  type: TActionType.massivePeopleStatusFailure,
  reason: payload,
});

// Massive People AccountType
export const actionMassivePeopleAccountTypeRequest = (
  payload: TMassiveAccountTypeRequest,
): TActionMassivePeopleAccountTypeRequest => ({
  type: TActionType.massivePeopleAccountTypeRequest,
  payload,
});

export const actionMassivePeopleAccountTypeSuccess = (): TActionMassivePeopleAccountTypeSuccess => ({
  type: TActionType.massivePeopleAccountTypeSuccess,
});

export const actionMassivePeopleAccountTypeFailure = (
  payload: string,
): TActionMassivePeopleAccountTypeFailure => ({
  type: TActionType.massivePeopleAccountTypeFailure,
  reason: payload,
});

// Massive People Role
export const actionMassivePeopleRoleRequest = (
  payload: TMassiveRoleRequest,
): TActionMassivePeopleRoleRequest => ({
  type: TActionType.massivePeopleRoleRequest,
  payload,
});

export const actionMassivePeopleRoleSuccess = (): TActionMassivePeopleRoleSuccess => ({
  type: TActionType.massivePeopleRoleSuccess,
});

export const actionMassivePeopleRoleFailure = (
  payload: string,
): TActionMassivePeopleRoleFailure => ({
  type: TActionType.massivePeopleRoleFailure,
  reason: payload,
});

// Massive People Report
export const actionMassivePeopleReportsRequest = (
  payload: TMassiveReportsRequest,
): TActionMassivePeopleReportsRequest => ({
  type: TActionType.massivePeopleReportsRequest,
  payload,
});

export const actionMassivePeopleReportsSuccess = (): TActionMassivePeopleReportsSuccess => ({
  type: TActionType.massivePeopleReportsSuccess,
});

export const actionMassivePeopleReportsFailure = (
  payload: string,
): TActionMassivePeopleReportsFailure => ({
  type: TActionType.massivePeopleReportsFailure,
  reason: payload,
});

// Massive People Group
export const actionMassivePeopleGroupRequest = (
  payload: TMassiveGroupRequest,
): TActionMassivePeopleGroupRequest => ({
  type: TActionType.massivePeopleGroupRequest,
  payload,
});

export const actionMassivePeopleGroupSuccess = (): TActionMassivePeopleGroupSuccess => ({
  type: TActionType.massivePeopleGroupSuccess,
});

export const actionMassivePeopleGroupFailure = (
  payload: string,
): TActionMassivePeopleGroupFailure => ({
  type: TActionType.massivePeopleGroupFailure,
  reason: payload,
});

// Massive People License
export const actionMassivePeopleLicenseRequest = (
  payload: TMassiveLicenseRequest,
): TActionMassivePeopleLicenseRequest => ({
  type: TActionType.massivePeopleLicenseRequest,
  payload,
});

export const actionMassivePeopleLicenseSuccess = (): TActionMassivePeopleLicenseSuccess => ({
  type: TActionType.massivePeopleLicenseSuccess,
});

export const actionMassivePeopleLicenseFailure = (
  payload: string,
): TActionMassivePeopleLicenseFailure => ({
  type: TActionType.massivePeopleLicenseFailure,
  reason: payload,
});

// Massive People Activation
export const actionMassivePeopleActivationRequest = (
  payload: TMassiveActivationRequest,
): TActionMassivePeopleActivationRequest => ({
  type: TActionType.massivePeopleActivationRequest,
  payload,
});

export const actionMassivePeopleActivationSuccess = (): TActionMassivePeopleActivationSuccess => ({
  type: TActionType.massivePeopleActivationSuccess,
});

export const actionMassivePeopleActivationFailure = (
  payload: string,
): TActionMassivePeopleActivationFailure => ({
  type: TActionType.massivePeopleActivationFailure,
  reason: payload,
});
