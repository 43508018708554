/** @format */

import { TActionRoleListSuccess, TActionRoleListReset } from "../declarations/actions/roles";
import { TActionType } from "../declarations/actionTypes";
import { TRoleState } from "../declarations/maps/role";

type Actions = TActionRoleListSuccess | TActionRoleListReset;

const defaultState = {
  list: {
    1: {
      id: 1,
      name: "xxx",
    },
    2: {
      id: 2,
      name: "yyy",
    },
  },
};

export const reducerRole = (state: TRoleState = defaultState, action: Actions): TRoleState => {
  switch (action.type) {
    case TActionType.roleListSuccess:
      return { list: { ...action.payload } };
    case TActionType.roleListReset:
      return defaultState;
    default:
      return state;
  }
};
