/** @format */

import { Table } from "antd";
import styled from "styled-components";

const StyledTable = styled((props): JSX.Element => Table({ ...props, scroll: { x: true } }))`
  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: black;
  }
`;
export default StyledTable;
