/** @format */

import { TGroup, TGroupForSelect, TGroupForTable } from "../declarations/maps/group";
import { TStore } from "../declarations/store";

export const selectorGroupList = (store: TStore): TGroup[] => Object.values(store.group.list) || [];

export const selectorGroup = (store: TStore, groupId: string): TGroup | null =>
  store.group.list[groupId] || null;

export const selectorGroupListForTables = (
  store: TStore,
  customerId: number | false = false,
): TGroupForTable[] => {
  const groupList = !!store.auth.isAdmin
    ? customerId
      ? Object.values(store.group.list).filter(el => el.customer_id == customerId)
      : Object.values(store.group.list)
    : Object.values(store.group.list).filter(
        group =>
          //@ts-ignore
          !!Object.values(group.administrators).find(amm => amm.person_id == store.auth.user?.id),
      ) || [];

  if (!!!groupList) return [];
  return groupList.map(el => {
    const newEl = (({
      note,
      customer_id,
      contractor_email,
      contractor_name,
      contractor_phone,
      administrators,
      tipology,
      ...o
    }): TGroupForTable => ({ ...o, key: o.id }))(el);

    return newEl;
  });
};

export const selectorGroupListForSelect = (store: TStore): TGroupForSelect[] => {
  const groupList = Object.values(store.group.list);
  if (!!!groupList) return [];

  return groupList.map(el => {
    const newEl = (({ ...o }): TGroupForSelect => ({
      ...o,
      key: o.id,
      value: o.id,
      description: o.name,
    }))(el);

    return newEl;
  });
};
