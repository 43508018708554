/** @format */

import { AxiosResponse, AxiosError } from "axios";

import { call, put } from "redux-saga/effects";
import {
  apiMassivePeopleAccountType,
  apiMassivePeopleActivation,
  apiMassivePeopleBranch,
  apiMassivePeopleBrand,
  apiMassivePeopleGroup,
  apiMassivePeopleLicense,
  apiMassivePeoplePlan,
  apiMassivePeopleReports,
  apiMassivePeopleRole,
  apiMassivePeopleStatus,
} from "../../api/people/apiMassiveAction";
import {
  actionMassivePeopleAccountTypeFailure,
  actionMassivePeopleAccountTypeSuccess,
  actionMassivePeopleActivationFailure,
  actionMassivePeopleActivationSuccess,
  actionMassivePeopleBranchFailure,
  actionMassivePeopleBranchSuccess,
  actionMassivePeopleBrandFailure,
  actionMassivePeopleBrandSuccess,
  actionMassivePeopleGroupFailure,
  actionMassivePeopleGroupSuccess,
  actionMassivePeopleLicenseFailure,
  actionMassivePeopleLicenseSuccess,
  actionMassivePeoplePlanFailure,
  actionMassivePeoplePlanSuccess,
  actionMassivePeopleReportsFailure,
  actionMassivePeopleReportsSuccess,
  actionMassivePeopleRoleFailure,
  actionMassivePeopleRoleSuccess,
  actionMassivePeopleStatusFailure,
  actionMassivePeopleStatusSuccess,
} from "../actions/actionsGeneral";
import { actionGroupListRequest } from "../actions/actionsGroup";
import { actionLicenseListRequest } from "../actions/actionsLicense";
import { actionPeopleListRequest } from "../actions/actionsPeople";
import {
  actionAlertGenerate,
  actionPeopleListLoadingStart,
  actionPeopleListLoadingStop,
} from "../actions/actionsUI";
import {
  TActionMassivePeopleAccountTypeRequest,
  TActionMassivePeopleActivationRequest,
  TActionMassivePeopleBranchRequest,
  TActionMassivePeopleBrandRequest,
  TActionMassivePeopleGroupRequest,
  TActionMassivePeopleLicenseRequest,
  TActionMassivePeoplePlanRequest,
  TActionMassivePeopleReportsRequest,
  TActionMassivePeopleRoleRequest,
  TActionMassivePeopleStatusRequest,
} from "../declarations/actions/general";
import { TAPIValidationError } from "../declarations/apis/general";

export function* sagaMassivePeopleBrand(action: TActionMassivePeopleBrandRequest): unknown {
  yield put(actionPeopleListLoadingStart());
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiMassivePeopleBrand, action.payload);

  if (!error && response) {
    if (response.data.length > 0) {
      yield put(
        actionAlertGenerate({
          type: "warning",
          message: "Attention",
          description: `${response.data.map(el => el.person + ": " + el.description).join("\n")}`,
        }),
      );
    } else {
      yield put(
        actionAlertGenerate({
          type: "success",
          message: "Completed",
          description: `Operation succesfully completed`,
        }),
      );
    }
    yield put(actionMassivePeopleBrandSuccess());
    yield put(actionPeopleListRequest());
    yield put(actionPeopleListLoadingStop());
  } else {
    yield put(
      //@ts-ignore
      actionMassivePeopleBrandFailure(
        //@ts-ignore
        (error?.response?.data?.message as TAPIValidationError) || {},
      ),
    );
    yield put(actionPeopleListLoadingStop());
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}
export function* sagaMassivePeopleBranch(action: TActionMassivePeopleBranchRequest): unknown {
  yield put(actionPeopleListLoadingStart());
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiMassivePeopleBranch, action.payload);

  if (!error && response) {
    if (response.data.length > 0) {
      yield put(
        actionAlertGenerate({
          type: "warning",
          message: "Attention",
          description: `${response.data.map(el => el.person + ": " + el.description).join("\n")}`,
        }),
      );
    } else {
      yield put(
        actionAlertGenerate({
          type: "success",
          message: "Completed",
          description: `Operation succesfully completed`,
        }),
      );
    }
    yield put(actionMassivePeopleBranchSuccess());
    yield put(actionPeopleListRequest());
    yield put(actionPeopleListLoadingStop());
  } else {
    yield put(
      actionMassivePeopleBranchFailure(
        //@ts-ignore
        (error?.response?.data?.message as TAPIValidationError) || {},
      ),
    );
    yield put(actionPeopleListLoadingStop());
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}

export function* sagaMassivePeoplePlan(action: TActionMassivePeoplePlanRequest): unknown {
  yield put(actionPeopleListLoadingStart());
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiMassivePeoplePlan, action.payload);

  if (!error && response) {
    if (response.data.length > 0) {
      yield put(
        actionAlertGenerate({
          type: "warning",
          message: "Attention",
          description: `${response.data.map(el => el.person + ": " + el.description).join("\n")}`,
        }),
      );
    } else {
      yield put(
        actionAlertGenerate({
          type: "success",
          message: "Completed",
          description: `Operation succesfully completed`,
        }),
      );
    }
    yield put(actionMassivePeoplePlanSuccess());
    yield put(actionPeopleListRequest());
    yield put(actionPeopleListLoadingStop());
  } else {
    yield put(
      actionMassivePeoplePlanFailure(
        //@ts-ignore
        (error?.response?.data?.message as TAPIValidationError) || {},
      ),
    );
    yield put(actionPeopleListLoadingStop());
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}

export function* sagaMassivePeopleStatus(action: TActionMassivePeopleStatusRequest): unknown {
  yield put(actionPeopleListLoadingStart());
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiMassivePeopleStatus, action.payload);

  if (!error && response) {
    if (response.data.length > 0) {
      yield put(
        actionAlertGenerate({
          type: "warning",
          message: "Attention",
          description: `${response.data.map(el => el.person + ": " + el.description).join("\n")}`,
        }),
      );
    } else {
      yield put(
        actionAlertGenerate({
          type: "success",
          message: "Completed",
          description: `Operation succesfully completed`,
        }),
      );
    }
    yield put(actionMassivePeopleStatusSuccess());
    yield put(actionPeopleListRequest());
    yield put(actionLicenseListRequest());
    yield put(actionGroupListRequest());
    yield put(actionPeopleListLoadingStop());
  } else {
    yield put(
      actionMassivePeopleStatusFailure(
        //@ts-ignore
        (error?.response?.data?.message as TAPIValidationError) || {},
      ),
    );
    yield put(actionPeopleListLoadingStop());
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}

export function* sagaMassivePeopleAccountType(
  action: TActionMassivePeopleAccountTypeRequest,
): unknown {
  yield put(actionPeopleListLoadingStart());
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiMassivePeopleAccountType, action.payload);

  if (!error && response) {
    if (response.data.length > 0) {
      yield put(
        actionAlertGenerate({
          type: "warning",
          message: "Attention",
          description: `${response.data.map(el => el.person + ": " + el.description).join("\n")}`,
        }),
      );
    } else {
      yield put(
        actionAlertGenerate({
          type: "success",
          message: "Completed",
          description: `Operation succesfully completed`,
        }),
      );
    }
    yield put(actionMassivePeopleAccountTypeSuccess());
    yield put(actionPeopleListRequest());
    yield put(actionPeopleListLoadingStop());
  } else {
    yield put(
      actionMassivePeopleAccountTypeFailure(
        //@ts-ignore
        (error?.response?.data?.message as TAPIValidationError) || {},
      ),
    );
    yield put(actionPeopleListLoadingStop());
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}

export function* sagaMassivePeopleRole(action: TActionMassivePeopleRoleRequest): unknown {
  yield put(actionPeopleListLoadingStart());
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiMassivePeopleRole, action.payload);

  if (!error && response) {
    if (response.data.length > 0) {
      yield put(
        actionAlertGenerate({
          type: "warning",
          message: "Attention",
          description: `${response.data.map(el => el.person + ": " + el.description).join("\n")}`,
        }),
      );
    } else {
      yield put(
        actionAlertGenerate({
          type: "success",
          message: "Completed",
          description: `Operation succesfully completed`,
        }),
      );
    }
    yield put(actionMassivePeopleRoleSuccess());
    yield put(actionPeopleListRequest());
    yield put(actionPeopleListLoadingStop());
  } else {
    yield put(
      actionMassivePeopleRoleFailure(
        //@ts-ignore
        (error?.response?.data?.message as TAPIValidationError) || {},
      ),
    );
    yield put(actionPeopleListLoadingStop());
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}
export function* sagaMassivePeopleReports(action: TActionMassivePeopleReportsRequest): unknown {
  yield put(actionPeopleListLoadingStart());
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiMassivePeopleReports, action.payload);

  if (!error && response) {
    if (response.data.length > 0) {
      yield put(
        actionAlertGenerate({
          type: "warning",
          message: "Attention",
          description: `${response.data.map(el => el.person + ": " + el.description).join("\n")}`,
        }),
      );
    } else {
      yield put(
        actionAlertGenerate({
          type: "success",
          message: "Completed",
          description: `Operation succesfully completed`,
        }),
      );
    }
    yield put(actionMassivePeopleReportsSuccess());
    yield put(actionPeopleListRequest());
    yield put(actionPeopleListLoadingStop());
  } else {
    yield put(
      actionMassivePeopleReportsFailure(
        //@ts-ignore
        (error?.response?.data?.message as TAPIValidationError) || {},
      ),
    );
    yield put(actionPeopleListLoadingStop());
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}

export function* sagaMassivePeopleGroup(action: TActionMassivePeopleGroupRequest): unknown {
  yield put(actionPeopleListLoadingStart());
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiMassivePeopleGroup, action.payload);

  if (!error && response) {
    if (response.data.length > 0) {
      yield put(
        actionAlertGenerate({
          type: "warning",
          message: "Attention",
          description: `${response.data.map(el => el.person + ": " + el.description).join("\n")}`,
        }),
      );
    } else {
      yield put(
        actionAlertGenerate({
          type: "success",
          message: "Completed",
          description: `Operation succesfully completed`,
        }),
      );
    }
    yield put(actionMassivePeopleGroupSuccess());
    yield put(actionPeopleListRequest());
    yield put(actionGroupListRequest());
    yield put(actionLicenseListRequest());
    yield put(actionPeopleListLoadingStop());
  } else {
    yield put(
      actionMassivePeopleGroupFailure(
        //@ts-ignore
        (error?.response?.data?.message as TAPIValidationError) || {},
      ),
    );
    yield put(actionPeopleListLoadingStop());
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}

export function* sagaMassivePeopleLicense(action: TActionMassivePeopleLicenseRequest): unknown {
  yield put(actionPeopleListLoadingStart());
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiMassivePeopleLicense, action.payload);

  if (!error && response) {
    if (response.data.length > 0) {
      yield put(
        actionAlertGenerate({
          type: "warning",
          message: "Attention",
          description: `${response.data.map(el => el.person + ": " + el.description).join("\n")}`,
        }),
      );
    } else {
      yield put(
        actionAlertGenerate({
          type: "success",
          message: "Completed",
          description: `Operation succesfully completed`,
        }),
      );
    }
    yield put(actionMassivePeopleLicenseSuccess());
    yield put(actionPeopleListRequest());
    yield put(actionLicenseListRequest());
    yield put(actionGroupListRequest());
    yield put(actionPeopleListLoadingStop());
  } else {
    yield put(
      actionMassivePeopleLicenseFailure(
        //@ts-ignore
        (error?.response?.data?.message as TAPIValidationError) || {},
      ),
    );
    yield put(actionPeopleListLoadingStop());
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}

export function* sagaMassivePeopleActivation(
  action: TActionMassivePeopleActivationRequest,
): unknown {
  yield put(actionPeopleListLoadingStart());
  const {
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError<{ errors: TAPIValidationError }>;
  } = yield call(apiMassivePeopleActivation, action.payload);

  if (!error && response) {
    if (response.data.length > 0) {
      yield put(
        actionAlertGenerate({
          type: "warning",
          message: "Attention",
          description: `${response.data.map(el => el.person + ": " + el.description).join("\n")}`,
        }),
      );
    } else {
      yield put(
        actionAlertGenerate({
          type: "success",
          message: "Completed",
          description: `Operation succesfully completed`,
        }),
      );
    }
    yield put(actionMassivePeopleActivationSuccess());
    yield put(actionPeopleListRequest());
    yield put(actionLicenseListRequest());
    yield put(actionGroupListRequest());
    yield put(actionPeopleListLoadingStop());
  } else {
    yield put(
      actionMassivePeopleActivationFailure(
        //@ts-ignore
        (error?.response?.data?.message as TAPIValidationError) || {},
      ),
    );
    yield put(actionPeopleListLoadingStop());
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.message || "",
      }),
    );
  }
}
