/** @format */

import {
  UITransition,
  UIRow,
  UICol,
  UICard,
  UIInput,
  UITextArea,
  UITitle,
  UIButton,
} from "@rstore-react-packages/template-ui";
import { Form } from "antd";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { actionGroupCreateRequest } from "../../../../redux-modules/actions/actionsGroup";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";

interface Props {
  customerId: number;
  onCreateCallback?: () => void;
}
const FormAddGroup: FC<Props> = ({ customerId, onCreateCallback }: Props): JSX.Element => {
  const l = useLanguage();
  const dispatch = useDispatch();
  const handleFinish = (form): void => {
    form.customer_id = customerId;
    dispatch(actionGroupCreateRequest(form));
    if (onCreateCallback) onCreateCallback();
  };

  return (
    <UITransition>
      <UIRow style={{ marginTop: "20px", marginBottom: "20px" }}>
        <UICol xs={24}>
          <UICard>
            <UIRow>
              <UICol xs={24} style={{ marginBottom: "5px" }}>
                <UITitle level={3}>{l("newGroup")}</UITitle>
              </UICol>
            </UIRow>
            <Form name="basic" layout="vertical" onFinish={handleFinish}>
              <UIRow>
                <UICol xs={24} md={8}>
                  <Form.Item name="name" label={l("name")}>
                    <UIInput />
                  </Form.Item>
                </UICol>
                <UICol xs={24} md={8} offset={1}>
                  <Form.Item name="contractor_name" label={l("contractorName")}>
                    <UIInput />
                  </Form.Item>
                </UICol>
              </UIRow>
              <UIRow>
                <UICol xs={24} md={8}>
                  <Form.Item name="contractor_email" label={l("contractorEmail")}>
                    <UIInput />
                  </Form.Item>
                </UICol>
                <UICol xs={24} md={8} offset={1}>
                  <Form.Item name="contractor_phone" label={l("contractorPhone")}>
                    <UIInput />
                  </Form.Item>
                </UICol>
              </UIRow>
              <UIRow>
                <UICol xs={24} md={8}>
                  <Form.Item name="note" label={l("note")}>
                    <UITextArea />
                  </Form.Item>
                </UICol>
                <UICol xs={24} md={8} offset={1}>
                  <Form.Item name="tipology" label={l("tipology")}>
                    <UIInput />
                  </Form.Item>
                </UICol>
                <UICol xs={24} md={8}>
                  <Form.Item>
                    <UIButton htmlType="submit" style={{ color: "#000" }}>
                      {l("save")}
                    </UIButton>
                  </Form.Item>
                </UICol>
              </UIRow>
            </Form>
          </UICard>
        </UICol>
      </UIRow>
    </UITransition>
  );
};

export default FormAddGroup;
