/** @format */

import { UICol, UIRow } from "@rstore-react-packages/template-ui";
import { Button, Form, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionMassiveStoreBrandRequest } from "../../../../redux-modules/actions/actionsStore";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import { selectorBrandListForSelect } from "../../../../redux-modules/selectors/selectorsBrand";
import StyledModal from "../../../ui/Modal";
const { Option } = Select;

/** @format */
interface Props {
  visible: boolean;
  handleOk?: () => void;
  handleCancel?: () => void;
  store: number[];
}
const ModalChangeStoreBrand = ({
  visible = false,
  handleCancel,
  handleOk,
  store,
}: Props): JSX.Element => {
  const l = useLanguage();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const brands = useSelector(selectorBrandListForSelect);

  const handleFinish = (formValues): void => {
    dispatch(
      actionMassiveStoreBrandRequest({
        users_ids: store,
        action: "brands",
        //@ts-ignore
        brands: formValues.brands_ids,
      }),
    );

    handleOk && handleOk();
  };

  const handleReturn = (): void => {
    handleCancel && handleCancel();
  };
  return (
    <StyledModal
      title={l("editBrand")}
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleReturn}>
          Return
        </Button>,
        <Button key="submit" onClick={(): void => form.submit()}>
          Submit
        </Button>,
      ]}
    >
      <UIRow>
        <UICol xs={24}>
          <Form form={form} name="edit-store-brand" layout="horizontal" onFinish={handleFinish}>
            <Form.Item
              name="brands_ids"
              label={"You are editing " + store.length + "'s store brand with:"}
            >
              <Select
                mode="multiple"
                optionFilterProp="label"
                showSearch
                filterOption={(input, option): boolean =>
                  //@ts-ignore
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {brands.map(brand => (
                  <Option value={brand.value} key={brand.key}>
                    {brand.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </UICol>
      </UIRow>
    </StyledModal>
  );
};
export default ModalChangeStoreBrand;
