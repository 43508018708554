/** @format */

import {
  TActionGroupAdminCreateFailure,
  TActionGroupAdminCreateRequest,
  TActionGroupAdminCreateSuccess,
  TActionGroupAdminDeleteFailure,
  TActionGroupAdminDeleteRequest,
  TActionGroupAdminDeleteSuccess,
  TActionGroupCreateFailure,
  TActionGroupCreateRequest,
  TActionGroupCreateSuccess,
  TActionGroupListFailure,
  TActionGroupListRequest,
  TActionGroupListSuccess,
  TActionGroupUpdateFailure,
  TActionGroupUpdateRequest,
  TActionGroupUpdateSuccess,
} from "../declarations/actions/groups";
import { TActionType } from "../declarations/actionTypes";
import { TGroupCreateRequest, TGroupList, TGroupUpdateRequest } from "../declarations/maps/group";
import { TGroupAdminCreateRequest } from "../declarations/maps/groupAdmin";

export const actionGroupListRequest = (): TActionGroupListRequest => ({
  type: TActionType.groupListRequest,
});

export const actionGroupListSuccess = (payload: TGroupList): TActionGroupListSuccess => ({
  type: TActionType.groupListSuccess,
  payload,
});

export const actionGroupListFailure = (payload: string): TActionGroupListFailure => ({
  type: TActionType.groupListFailure,
  reason: payload,
});

// Create
export const actionGroupCreateRequest = (
  payload: TGroupCreateRequest,
): TActionGroupCreateRequest => ({
  type: TActionType.groupCreateRequest,
  payload,
});

export const actionGroupCreateSuccess = (): TActionGroupCreateSuccess => ({
  type: TActionType.groupCreateSuccess,
});

export const actionGroupCreateFailure = (payload: string): TActionGroupCreateFailure => ({
  type: TActionType.groupCreateFailure,
  reason: payload,
});

// Update
export const actionGroupUpdateRequest = (
  payload: TGroupUpdateRequest,
): TActionGroupUpdateRequest => ({
  type: TActionType.groupUpdateRequest,
  payload,
});

export const actionGroupUpdateSuccess = (): TActionGroupUpdateSuccess => ({
  type: TActionType.groupUpdateSuccess,
});

export const actionGroupUpdateFailure = (payload: string): TActionGroupUpdateFailure => ({
  type: TActionType.groupUpdateFailure,
  reason: payload,
});

// Create Group Admin
export const actionGroupAdminCreateRequest = (
  payload: TGroupAdminCreateRequest,
): TActionGroupAdminCreateRequest => ({
  type: TActionType.groupAdminCreateRequest,
  payload,
});

export const actionGroupAdminCreateSuccess = (): TActionGroupAdminCreateSuccess => ({
  type: TActionType.groupAdminCreateSuccess,
});

export const actionGroupAdminCreateFailure = (payload: string): TActionGroupAdminCreateFailure => ({
  type: TActionType.groupAdminCreateFailure,
  reason: payload,
});

// Delete Group Admin
export const actionGroupAdminDeleteRequest = (
  payload: TGroupAdminCreateRequest,
): TActionGroupAdminDeleteRequest => ({
  type: TActionType.groupAdminDeleteRequest,
  payload,
});

export const actionGroupAdminDeleteSuccess = (): TActionGroupAdminDeleteSuccess => ({
  type: TActionType.groupAdminDeleteSuccess,
});

export const actionGroupAdminDeleteFailure = (payload: string): TActionGroupAdminDeleteFailure => ({
  type: TActionType.groupAdminDeleteFailure,
  reason: payload,
});
