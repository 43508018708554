/** @format */

import { TPartner, TPartnerForSelect } from "../declarations/maps/partner";
import { TStore } from "../declarations/store";

export const selectorPartnerList = (
  store: TStore,
  customerId: number | false = false,
): TPartner[] =>
  customerId
    ? Object.values(store.branch.list)
        .filter(el => el.customer_id == customerId)
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    : Object.values(store.partner.list).sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
      ) || [];

export const selectorPartner = (store: TStore, partnerId: string): TPartner | null =>
  store.partner.list[partnerId] || null;

export const selectorPartnerListForTables = (store: TStore): TPartnerForSelect[] => {
  const partnerList = Object.values(store.partner.list);
  if (!!!partnerList) return [];

  return partnerList
    .map(el => {
      const newEl = (({ ...o }): TPartnerForSelect => ({
        ...o,
        value: o.id,
        key: o.id,
        description: o.name.toUpperCase(),
      }))(el);

      return newEl;
    })
    .sort((a, b) => (a.description > b.description ? 1 : b.description > a.description ? -1 : 0));
};
