/** @format */

import { AxiosError, AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../../constants/api";
import { TCustomerUpdateRequest } from "../../redux-modules/declarations/maps/customer";
import apiClient from "../../utils/apiClient";

export const apiCustomerUpdate = (
  data: TCustomerUpdateRequest,
): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .put(API_ENDPOINTS.CUSTOMER_UPDATE.replace(":id", `${data.id}`), data)
    .then(response => ({ response }))
    .catch(error => ({ error }));
