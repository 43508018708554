/** @format */

import { Form } from "antd";
import React from "react";
import Logo from "../../../assets/svg/vuem-logo-white.svg";
import { Colors } from "../../../theme/maps/Colors";
import { MailOutlined } from "@ant-design/icons";
import {
  UIButton,
  UICard,
  UICol,
  UIImage,
  UIInput,
  UIRow,
  UIText,
  UITitle,
  UITransition,
} from "@rstore-react-packages/template-ui";
import { useLanguage } from "../../../redux-modules/hooks/useLanguage";

export const ViewForgotPassword = (): JSX.Element => {
  const l = useLanguage();
  const handleRecovery = (forgotObject: {}): void => {
    //@ts-ignore
    const mailObj = { email: forgotObject.email };
    console.log(mailObj);
    //dispatch(actionRecoveryRequest(mailObj));
  };

  return (
    <UITransition>
      <UIRow justify="center" gutter={[16, 16]} style={{ marginTop: "35px", marginBottom: "15px" }}>
        <UICol>
          <UIImage imgWidth={"180px"} src={Logo} />
        </UICol>
      </UIRow>

      <UIRow justify="center" gutter={[16, 16]}>
        <UICol lg={8} sm={18} xs={24}>
          <UICard>
            <UIRow>
              <UICol xs={24}>
                <UITitle level={3}>{l("forgotPassword")}?</UITitle>
              </UICol>
            </UIRow>
            <UIRow style={{ marginBottom: "25px" }}>
              <UICol xs={24}>
                <UIText color={Colors.darkGray}>{l("forgotPasswordLabel")}</UIText>
              </UICol>
            </UIRow>
            <Form
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              onFinish={handleRecovery}
              onFinishFailed={(e): void => console.log(e)}
            >
              <Form.Item
                name="email"
                rules={[{ required: true, message: `${l("insertValidMail")}` }]}
              >
                <UIInput placeholder="Email" prefix={<MailOutlined />} />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 24 }}>
                <UIButton type="primary" htmlType="submit" fullWidth>
                  {l("resetPassword")}
                </UIButton>
              </Form.Item>
            </Form>
          </UICard>
        </UICol>
      </UIRow>
    </UITransition>
  );
};
