/** @format */

import { takeLatest } from "redux-saga/effects";

import { TActionType } from "../declarations/actionTypes";
import { sagaStoreCreate, sagaStoreList, sagaStoreUpdate } from "../sagas/sagasStore";

export function* watcherStore(): unknown {
  yield takeLatest(TActionType.storeListRequest, sagaStoreList);
  yield takeLatest(TActionType.storeUpdateRequest, sagaStoreUpdate);
  yield takeLatest(TActionType.storeCreateRequest, sagaStoreCreate);
}
