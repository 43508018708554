/** @format */

import { AxiosError, AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { apiAccountTypeList } from "../../api/accountType/apiAccountTypeList";
import { arrayToObject } from "../../utils/format/arrayToObject";
import {
  actionAccountTypeListFailure,
  actionAccountTypeListSuccess,
} from "../actions/actionsAccountType";

import { actionAlertGenerate } from "../actions/actionsUI";
import { TActionAccountTypeListRequest } from "../declarations/actions/accountTypes";
import { TAPIValidationError } from "../declarations/apis/general";

export function* sagaAccountTypeList(action: TActionAccountTypeListRequest): unknown {
  const {
    response,
    error,
  }: { response?: AxiosResponse; error?: AxiosError<{ errors: TAPIValidationError }> } = yield call(
    apiAccountTypeList,
  );

  if (!error && response) {
    //@ts-ignore
    yield put(actionAccountTypeListSuccess(arrayToObject(response.data.data, "id")));
  } else {
    yield put(
      //@ts-ignore
      actionAccountTypeListFailure((error?.response?.data?.errors as TAPIValidationError) || {}),
    );
    yield put(
      actionAlertGenerate({
        type: "error",
        message: "Error",
        //@ts-ignore
        description: error?.response?.data?.errors?.toString() || "",
      }),
    );
  }
}
