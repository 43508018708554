/** @format */

import React, { FC } from "react";
import { Layout, Select } from "antd";
import { Routes } from "../../../utils/Routes";
import { NavLink, useHistory, withRouter } from "react-router-dom";
import { UICol, UIImage } from "@rstore-react-packages/template-ui";
import { useDispatch, useSelector } from "react-redux";
import { selectorAuthenticated, selectorIsAdmin } from /*   selectorIsAdmin,
 */ "../../../redux-modules/selectors/selectorsAuth";
import LayoutUserMenu from "../Menu/UserMenu";
import Logo from "../../../assets/svg/vuem-logo-white.svg";
import LangMenu from "../Menu/LangMenu";
import { selectorCustomerSelected } from "../../../redux-modules/selectors/selectorsUI";
import { selectorCustomerList } from "../../../redux-modules/selectors/selectorsCustomer";
import { actionCustomerSelect } from "../../../redux-modules/actions/actionsUI";
/* import { selectorCustomerSelected } from "../../../redux-modules/selectors/selectorsUI";
 */
const { Header } = Layout;
const { Option } = Select;

const LayoutHeader: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticated = useSelector(selectorAuthenticated);
  const isCustomerSelected = useSelector(selectorCustomerSelected);
  const isAdmin = useSelector(selectorIsAdmin);
  const customerList = useSelector(selectorCustomerList);

  const changeCustomer = (number: number): void => {
    dispatch(actionCustomerSelect(number));
    history.push(Routes.customer.replace(":id", `${number}`));
  };

  return (
    <Header
      style={{
        position: "fixed",
        zIndex: 1,
        width: "100%",
        display: isAuthenticated ? "flex" : "none",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex" }}>
        <div className="logo" style={{ marginRight: "20px" }}>
          <NavLink to={Routes.home}>
            <UIImage imgWidth={"130px"} src={Logo} />
          </NavLink>
        </div>
        {!!isCustomerSelected && !!isAdmin && (
          <div>
            <Select defaultValue={isCustomerSelected.id} bordered={false} onChange={changeCustomer}>
              {customerList.map(e => (
                <Option value={e.id}>{e.name}</Option>
              ))}
            </Select>
          </div>
        )}

        {/* <StyledMenu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={[
            history.location.pathname === "/"
              ? "/customer"
              : "/" + history.location.pathname.split("/")[1],
          ]}
          selectedKeys={[
            history.location.pathname === "/"
              ? "/customer"
              : "/" + history.location.pathname.split("/")[1],
          ]}
        >
          <StyledMenuItem key="/customer">
            <NavLink to={Routes.home}>Customers</NavLink>
          </StyledMenuItem>
                    {!!isCustomerSelected && !!isAdmin && (
            <>
              <StyledMenuItem key="/brands">
                <NavLink to={Routes.brands}>Brands</NavLink>
              </StyledMenuItem>
              <StyledMenuItem key="/branches">
                <NavLink to={Routes.branch}>Branches</NavLink>
              </StyledMenuItem>
              <StyledMenuItem key="/partners">
                <NavLink to={Routes.partners}>Partners</NavLink>
              </StyledMenuItem>
            </>
          )}
        </StyledMenu> */}
      </div>
      <div>
        <LayoutUserMenu />
        <UICol xs={0}>
          <LangMenu />
        </UICol>
      </div>
    </Header>
  );
};

export default withRouter(LayoutHeader);
/* 
const StyledMenu = styled(Menu)`
  .ant-menu-item-selected {
    color: #000 !important;
  }
  .ant-menu-item:hover {
    color: #000 !important;
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  .ant-menu-item-selected {
    color: #000 !important;
  }
  .ant-menu-item:hover {
    color: #000 !important;
  }
`; */
/* 
const StyledNavLink = styled(NavLink)`
  :hover {
    color: #000 !important;
  }
  .active {
    color: #000 !important;
  }
`;
 */
