/** @format */

import { UIRow, UICol, UIText } from "@rstore-react-packages/template-ui";
import Paragraph from "antd/lib/typography/Paragraph";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { actionStoreUpdateRequest } from "../../../../redux-modules/actions/actionsStore";
/* import { useDispatch } from "react-redux";
import { actionStoreUpdateRequest } from "../../../../redux-modules/actions/actionsStore"; */
import { TTStore } from "../../../../redux-modules/declarations/maps/store";
import { useLanguage } from "../../../../redux-modules/hooks/useLanguage";
import { Colors } from "../../../../theme/maps/Colors";
import { pascalCaseToLabel } from "../../../../utils/format/pascalCaseToLabel";
import FormEditStore from "../../Forms/Store/editStore";

interface Props {
  store: TTStore | null;
}

const StoreInfoPanel = ({ store }: Props): JSX.Element => {
  const l = useLanguage();
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const handleEditing = (): void => setIsEditing(!isEditing);
  const handleFinish = (form): void => {
    dispatch(actionStoreUpdateRequest({ ...store, ...form }));
    handleEditing();
  };

  if (!!!store) return <></>;
  if (!!isEditing)
    return (
      <FormEditStore store={store} onFinishCallback={handleFinish} onCloseForm={handleEditing} />
    );
  return (
    <>
      <UIRow style={{ marginTop: "10px", marginBottom: "30px" }}>
        <UICol xs={24} md={12}>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol style={{ display: "inline-flex" }}>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("name")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph style={{ display: "inline" }} editable={{ onStart: handleEditing }}>
                  {store.name}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol style={{ display: "inline-flex" }}>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("address")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph style={{ display: "inline" }} editable={{ onStart: handleEditing }}>
                  {store.address}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol style={{ display: "inline-flex" }}>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("branch")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph style={{ display: "inline" }} editable={{ onStart: handleEditing }}>
                  {store.branch.name}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol style={{ display: "inline-flex" }}>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("brands")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph style={{ display: "inline" }} editable={{ onStart: handleEditing }}>
                  {Object.values(store.brands.map(e => e.name)).toString()}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
          <UIRow style={{ marginTop: "8px" }}>
            <UICol style={{ display: "inline-flex" }}>
              <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                {l("partner")}:
              </UIText>{" "}
              <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                <Paragraph style={{ display: "inline" }} editable={{ onStart: handleEditing }}>
                  {store.partner.name}
                </Paragraph>
              </UIText>
            </UICol>
          </UIRow>
        </UICol>
        <UICol xs={24} md={12}>
          {Object.values(store.details?.data || {}).map(detail => (
            <UIRow style={{ marginTop: "8px" }} key={detail.id}>
              <UICol xs={24} style={{ display: "inline-flex" }}>
                <UIText style={{ fontSize: "15px" }} color={Colors.grayLabel}>
                  {pascalCaseToLabel(detail.key)}:
                </UIText>{" "}
                <UIText style={{ fontSize: "16px", marginLeft: "15px", marginBottom: "-2px" }}>
                  <Paragraph
                    style={{
                      display: detail.key === "contactList" ? "table-caption" : "inline",
                      fontSize: detail.key === "contactList" ? "15px" : "inherit",
                    }}
                    editable={{ onStart: handleEditing }}
                  >
                    {detail.key === "contactList"
                      ? detail.value.split(";").map(e => (
                          <div style={{ whiteSpace: "nowrap" }} key={e}>
                            <a href={"mailto:" + e}>{e}</a>
                          </div>
                        ))
                      : detail.value}
                  </Paragraph>
                </UIText>
              </UICol>
            </UIRow>
          ))}
        </UICol>
      </UIRow>
    </>
  );
};

export default StoreInfoPanel;
