/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import { sagaBranchCreate, sagaBranchList, sagaBranchUpdate } from "../sagas/sagasBranch";

export function* watcherBranch(): unknown {
  yield takeLatest(TActionType.branchListRequest, sagaBranchList);
  yield takeLatest(TActionType.branchCreateRequest, sagaBranchCreate);
  yield takeLatest(TActionType.branchUpdateRequest, sagaBranchUpdate);
}
